import React, { FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";
import { useLocaleContext } from "@ct-react/locale";
import { PrimaryButton } from "../../components/button";
import "./cart-connexion.scss";

const CartAccount:FunctionComponent = ()=>{
    const intl = useIntl();
    const { locale } = useLocaleContext();

    const accountTarget = useMemo(() => `/${locale.basename}/checkout/information`, [ locale ]);

    return(
        <div className="connexion-choice">
            <div>
                <h5>{intl.formatMessage({id:"cart-connexion-identify-yourself",defaultMessage : "S'identifier"})}</h5>
                <dl>
                    <dt>{intl.formatMessage({id:"already-subscribe",defaultMessage : "Je suis déja inscrit"})}</dt>
                    <dd>
                        <button className="btn primary">
                            <a href={`/login/?target=${accountTarget}`}>{intl.formatMessage({id:"login",defaultMessage : "Se connecter"})}</a>
                        </button>
                    </dd>
                    <dt>{intl.formatMessage({id:"cart-connexion-first-time-booking",defaultMessage : "C'est la première fois que je réserve sur Maytain :"})}</dt>
                    <dd>
                        <button className="btn primary">
                            <a href={`/signup/?target=${accountTarget}`}>{intl.formatMessage({id:"signin",defaultMessage : "S'inscrire"})}</a>
                        </button>
                    </dd>
                </dl>
            </div>
            <div>
                <h5>{intl.formatMessage({id:"cart-connexion-continue-without-signin", defaultMessage : "Continuer sans compte"})}</h5>
                <dl>
                    <dt>{intl.formatMessage({id:"cart-connexion-visitor", defaultMessage : "Je souhaite faire une réservation à titre de visiteur et renoncez aux avantages réservés aux titulaires d'un compte en ligne:"})}</dt>
                    <dd>
                        <PrimaryButton value={intl.formatMessage({id:"btn-continue", defaultMessage : "Continuer"})} to={"/checkout/information"}/>
                    </dd>
                </dl>
            </div>
        </div>
    )
};

export default CartAccount;
