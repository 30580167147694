import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { PrimaryButton } from "../button";
import "./common.scss"

type TitleBoxProps = {
    title:string,
    subtitle:string,
    position:string 
}

type ButtonBoxProps ={
    value:string,
    to?:string,
    color?:"primary"|"secondary"|"white",
    position:"start"|"center"|"end"|null
    small?:boolean,
    squared?:boolean,
    setState?: () => void
}


export const TitleBox:FunctionComponent<TitleBoxProps> = ({title,subtitle,position})=>{

    
    return(
        <div className={`titleBox ${position}`}>
            <h6 className="bold-red">{subtitle}</h6>
            <h2 className="bold-black">{title}</h2>
        </div>
    )
}

export const ButtonBox:FunctionComponent<ButtonBoxProps> = ({
        value,
        to,
        color="primary",
        position="center",
        squared=false,
        small=false,
        setState = () => void 0
    })=>{
    const pos = ()=>{
        switch(position){
            case("center"):return "center"
            break
            case("start"):return "btnStart"
            break
            case("end"):return "btnEnd"
            break
            default:return "center"
        }
    }

    const background = ()=>{
        switch(color){
            case("primary"):return "primary"
            break
            case("secondary"):return "secondary"
            break
            case("white"):return "btnWhite"
            break
            default:return "primary"
        }
    }

    return(
        <div className={`buttonBox ${pos()}`}>
            <PrimaryButton to={to} value={value} color={background()} squared={squared} small={small} setState={setState}/>
        </div>
    )
}

export const ButtonToTop = () => {

    const btnRef = useRef(null);
    const offsetGapRef = useRef<number>(200);
    const [ isVisible, setIsVisible ] = useState<boolean>(false);

    useEffect(() => {
      onScroll();
      window.addEventListener("scroll", () => onScroll());
      return () => { window.removeEventListener("scroll", () => onScroll()); };
    }, []);

    useEffect(() => {
      gsap.to(btnRef.current, {
        x: isVisible ? 0 : 100,
        autoAlpha: isVisible ? 1 : 0
      });
    }, [ isVisible ]);

    const onScroll = () => setIsVisible(window.scrollY > offsetGapRef.current);
    const handleOnClick = () => window.scrollTo(0,0);

    return (
      <div ref={btnRef}
           className="btnToTop"
           onClick={handleOnClick}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="var(--white)" d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/>
        </svg>
      </div>);

  }
