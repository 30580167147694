
export class SearchAccomodationArticles {
    type:string;
    qRent:RentAccommodationSearchRequest;
    qSeasonal:RentAccommodationSearchRequest;
    qAnnual:RentAccommodationSearchRequest;
    qSale:SaleAccommodationSearchRequest;
    first:number|null;
    last:number|null;
    before?:string|null;
    after:string|null;
    constructor(
        type:string,
        first:number|null = 8,
        last:number|null = null,
        before:string|null = null,
        after:string|null = null
    ){
        this.type = type;
        this.qRent  = new RentAccommodationSearchRequest()
        this.qSeasonal  = new RentAccommodationSearchRequest()
        this.qAnnual  = new RentAccommodationSearchRequest()
        this.qSale  = new SaleAccommodationSearchRequest()
        this.first = first;
        this.last = last;
        this.before = before;
        this.after = after
    }

}

export class RentAccommodationSearchRequest {
    merchants:string|null;
    novelty:boolean|null;
    promoted:boolean|null;
    objectTypes:string[]|null;
    region:string|null;
    roomsRange:number[]|null;
    minSurface:number|null;
    dishwasher:boolean|null;
    washingMachine:boolean|null;
    fireplace:boolean|null;
    pool:boolean|null;
    sauna:boolean|null;
    lift:boolean|null;
    parking:boolean|null;
    balcony:boolean|null;
    terrace:boolean|null;
    period:PeriodBookingConfig|null;
    flexPeriod:FlexBookingConfig|null;
    minGuests:number|null;
    wifi:boolean|null;
    tv:boolean|null;
    pet:boolean|null;
    smoker:boolean|null;
    disabledPerson:boolean|null;
    constructor(
        merchants:string|null = null,
        novelty:boolean|null = null,
        promoted:boolean|null = null,
        objectTypes:string[]|null = null,
        region:string|null = null,
        roomsRange:number[]|null = null,
        minSurface:number|null = null,
        dishwasher:boolean|null = null,
        washingMachine:boolean|null = null,
        fireplace:boolean|null = null,
        pool:boolean|null = null,
        sauna:boolean|null = null,
        lift:boolean|null = null,
        parking:boolean|null = null,
        balcony:boolean|null = null,
        terrace:boolean|null = null,
        period:PeriodBookingConfig|null = null,
        flexPeriod:FlexBookingConfig|null = null,
        minGuests:number|null = null,
        wifi:boolean|null = null,
        tv:boolean|null = null,
        pet:boolean|null = null,
        smoker:boolean|null = null,
        disabledPerson:boolean|null = null,
    ){
        this.merchants = merchants
        this.novelty = novelty
        this.promoted = promoted
        this.objectTypes = objectTypes
        this.region = region
        this.roomsRange = roomsRange
        this.minSurface = minSurface
        this.dishwasher = dishwasher
        this.washingMachine = washingMachine
        this.fireplace = fireplace
        this.pool = pool
        this.sauna = sauna
        this.lift = lift
        this.parking = parking
        this.balcony = balcony
        this.terrace = terrace
        this.period = period
        this.flexPeriod = flexPeriod
        this.minGuests = minGuests
        this.wifi = wifi
        this.tv = tv
        this.pet = pet
        this.smoker = smoker
        this.disabledPerson = disabledPerson 
    }
}

export class SaleAccommodationSearchRequest {
    merchants:string|null;
    novelty:boolean|null;
    promoted:boolean|null;
    objectTypes:string[]|null;
    region:string|null;
    roomsRange:number[]|null;
    minSurface:number|null;
    dishwasher:boolean|null;
    washingMachine:boolean|null;
    fireplace:boolean|null;
    pool:boolean|null;
    sauna:boolean|null;
    lift:boolean|null;
    parking:boolean|null;
    balcony:boolean|null;
    terrace:boolean|null;
    new:boolean|null;
    furnished:boolean|null;
    forForeigners:boolean|null;
    constructor(
        merchants:string|null = null,
        novelty:boolean|null = null,
        promoted:boolean|null = null,
        objectTypes:string[]|null = null,
        region:string|null = null,
        roomsRange:number[]|null = null,
        minSurface:number|null = null,
        dishwasher:boolean|null = null,
        washingMachine:boolean|null = null,
        fireplace:boolean|null = null,
        pool:boolean|null = null,
        sauna:boolean|null = null,
        lift:boolean|null = null,
        parking:boolean|null = null,
        balcony:boolean|null = null,
        terrace:boolean|null = null,
        newItem:boolean|null = null,
        furnished:boolean|null = null,
        forForeigners:boolean|null = null,
    ){
        this.merchants = merchants
        this.novelty = novelty
        this.promoted = promoted
        this.objectTypes = objectTypes
        this.region = region
        this.roomsRange = roomsRange
        this.minSurface = minSurface
        this.dishwasher = dishwasher
        this.washingMachine = washingMachine
        this.fireplace = fireplace
        this.pool = pool
        this.sauna = sauna
        this.lift = lift
        this.parking = parking
        this.balcony = balcony
        this.terrace = terrace
        this.new = newItem
        this.furnished = furnished
        this.forForeigners = forForeigners
    }

}

class PeriodBookingConfig {
    from:string|null;
    to:string|null;
    dayMargin:number|null
    constructor(
        from:string|null = null,
        to:string|null = null,
        dayMargin:number|null = null
    ){
        this.from = from;
        this.to = to;
        this.dayMargin = dayMargin
    }
}

class FlexBookingConfig {
    duration:"Weekend"|"Week";
    intoMonths:string
    constructor(
        duration:"Weekend"|"Week",
        intoMonths:string
    ){
        this.duration = duration;
        this.intoMonths = intoMonths
    }
}