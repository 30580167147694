import { gql, useQuery } from "@apollo/client";
import React, { FunctionComponent, useState } from "react";
import { useLocation } from "react-router";
import {
  ANNUAL_FEATURES_ALL_FIELDS,
  IMAGE_FIELDS,
  LOCATION_FIELDS,
  RENT_FEATURES_ALL_FIELDS,
  SEASONAL_FEATURES_ALL_FIELDS,
  SEASONAL_PRICE,
  SEO_ARTICLE
} from "@shared/fragments";
import { RentArticleCarousel } from "../../components/carousel";
import { ArticleMap } from "../../components/maps/maps";
import Section from "../../components/wrapper/section";
import { useIntl } from "react-intl";
import { ArticleHeader, ArticleImageGrid, ArticleInfo } from "./articleInfo";
import { useNavigate } from "@ct-react/locale";
import { Discounts } from "../../models/articles";
import moment from "moment";
import DiscountSvg from "../../assets/svgs/discount.svg";
import SeoHelmet from "../../components/seo-helmet";
import { commonDefs } from "../../i18n";

type RentDiscountProps = {
    discount : [Discounts]
}

const ARTICLE_DESCRIPTION = gql`
    query GetArticleDescription($articleId: ID!){
        articleDescription(articleId: $articleId){
            value
        }
    }
`;

const WEEKLY_ARTICLE_SUMMARY = gql`
    ${IMAGE_FIELDS}
    ${LOCATION_FIELDS}
    ${RENT_FEATURES_ALL_FIELDS}
    query GetArticleSummary($articleId: ID!){
        articleSummary(articleId: $articleId){
            ...on RentalAccommodationSummary{
                __typename,
                id,
                title {value},
                coordinates,
                priceOnDemand,
                price {currency , amount},
                images { ...ImageFields },
                location{...LocationFields},
                features { ...RentalFeaturesAllFields }
                discounts {
                    definitionType
                    period
                    type
                    percentageValue
                    fixedValue {
                        currency
                        amount
                    }
                }
            }
        }
    }
` ;

const SEASONAL_ARTICLE_SUMMARY = gql`
    ${IMAGE_FIELDS}
    ${LOCATION_FIELDS}
    ${SEASONAL_FEATURES_ALL_FIELDS}
    ${SEASONAL_PRICE}
    query GetArticleSummary($articleId: ID!){
        articleSummary(articleId: $articleId){
            ...on SeasonalAccommodationSummary{
                __typename,
                id,
                title {value},
                images{...ImageFields},
                novelty,
                promoted,
                location{...LocationFields},
                coordinates,
                priceOnDemand,
                price { ...SeasonalPrice},
                withPlans,
                features{...SeasonalFeaturesAllFields}
            }
        }
    }
` ;

const ANNUAL_ARTICLE_SUMMARY = gql`
    ${IMAGE_FIELDS}
    ${LOCATION_FIELDS}
    ${ANNUAL_FEATURES_ALL_FIELDS}
    query GetArticleSummary($articleId: ID!){
        articleSummary(articleId: $articleId){
            ...on AnnualAccommodationSummary{
                __typename,
                id,
                title {value},
                images{...ImageFields},
                location{...LocationFields},
                coordinates,
                priceOnDemand,
                price {currency , amount},
                withPlans,
                features{...AnnualFeaturesAllFields}
            }
        }
    }
` ;

const RentDiscount:FunctionComponent<RentDiscountProps> = ({discount})=>{
    const intl = useIntl();

    if(!!discount){
        return(
            <div className="article-discount">
                <DiscountSvg />
                <div>
                    {intl.formatMessage({id:"card-article-reduction-percentage", defaultMessage: "Réduction de {percentage}%"},{percentage: discount[0].percentageValue * 100})}
                    <br/>
                    {intl.formatMessage(
                        {id:"card-article-reduction-dates", defaultMessage: "À partir du {from} au {to}"},
                        {
                        from:   moment(discount[0].period[0]).format("DD MMM"),
                        to:     moment(discount[0].period[1]).format("DD MMM")
                        }
                    )}
                </div>
            </div>
        );
    }else{
        return <></>;
    }
}

const RentInfo:FunctionComponent=()=>{
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const [articleDescription,setArticleDescription] = useState<string|null>(null);
    const [articleSummary,setArticleSummary] = useState<any|null>(null);
    const [displayable,setDisplayable] = useState<boolean>(false);
    const [weeklyLocationPrice, setWeeklyLocationPrice] = useState<any>(null);

    const articleId = location.pathname.split("/")[4];
    const typeOfLocation = location.pathname.split("/")[3];

    //Fetching data

    const { data } = useQuery(SEO_ARTICLE, {
        variables: { id: articleId },
      });

    useQuery(ARTICLE_DESCRIPTION,{
        variables:{
            articleId:articleId
        },
        onCompleted:(data)=>{
            setArticleDescription(data.articleDescription.value)
        },
        onError:(error)=>{
            console.error(error);
            navigate("notFound");
        }
    });

    const query = ()=>{
        switch (typeOfLocation) {
        case "semaine":
            return WEEKLY_ARTICLE_SUMMARY
            break;
        case "saison":
            return SEASONAL_ARTICLE_SUMMARY
            break;
        case "annee":
            return ANNUAL_ARTICLE_SUMMARY
            break;
        default:
            return WEEKLY_ARTICLE_SUMMARY
            break;
        }
    };

    const type = ()=>{
        switch (typeOfLocation) {
            case "semaine":
                return "weekly"
                break;
            case "saison":
                return "seasonal"
                break;
            case "annee":
                return "annual"
                break;
            default:
                return "weekly"
                break;
            }
    };

    useQuery(query(),{
        variables:{
            articleId:articleId
        },
        onCompleted:(data)=>{
            setArticleSummary(data.articleSummary);
            setDisplayable(true);
        }
    });

    return(
        <>
            {!!data && <SeoHelmet {...{
                ...data.articleSEO, canonical : location.pathname.slice(4)
            }} />}
            <section className="article-section">
                {displayable&&
                <>
                <ArticleHeader props={articleSummary} type={type()} weeklyLocationPrice={weeklyLocationPrice}/>
                <div className="article-image">
                    <ArticleImageGrid props={articleSummary.images}/>
                    <RentDiscount discount={articleSummary.discounts}/>
                </div>
                <ArticleInfo
                    articleId={articleId}
                    description={articleDescription}
                    props={articleSummary}
                    type={type()}
                    weeklyLocationPrice={weeklyLocationPrice}
                    setWeeklyLocationPrice={setWeeklyLocationPrice}
                />
                </>
                }
                <Section className="map" title ={intl.formatMessage({ id:"where-is-accomodation", defaultMessage: "Où se trouve le logement ?"})} >
                {displayable&&
                    <ArticleMap coordinates={articleSummary.coordinates}/>
                }
                </Section>
            </section>
            <Section
                title={intl.formatMessage({ id:"other-accomodation-to-rent", defaultMessage: "Autres logements à louer"})}
                subtitle={intl.formatMessage(commonDefs.rent)}
                btnValue={intl.formatMessage({ id:"btn-see-all-accomodations", defaultMessage: "Voir tous les biens"})}
                btnLink={`louer/${typeOfLocation}`}
                background="var(--light-grey)"
            >
                <RentArticleCarousel type={typeOfLocation}/>
            </Section>
        </>
    );
};

export default RentInfo;
