import React, { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from "react";

type MenuContextProps = {
    isDown:boolean,
    setIsDown:Dispatch<SetStateAction<boolean>>;
}

type MenuContextProviderProps = {
    state:boolean;
    children:ReactNode
}

export const MenuContext = createContext<MenuContextProps>({
    isDown:false,
    setIsDown: () => void {}
});

export const MenuProvider = ({state,children}:MenuContextProviderProps)=>{
    const [isDown,setIsDown] = useState<boolean>(state);
    const value = useMemo(()=>{return {isDown,setIsDown}},[isDown]);
    return(
        <MenuContext.Provider value={value}>
            {children}
        </MenuContext.Provider>
    )
}

