import React, { FunctionComponent, ReactNode } from "react";
import { classNames } from "@ct-react/core";
import { ButtonBox, TitleBox } from "./common";
import "./twoColumn.scss"

type TwoColumnProps = {
    children:ReactNode,
    imageSrc?:string,
    alt?:string,
    title?:string,
    subtitle?:string,
    btnValue?:string,
    btnLink?:string,
    btnPosition?:"start"|"center"|"end"|null
    revert?:boolean
}

type ColumnTextProps = {
    children:ReactNode,
    title:string,
    subtitle:string,
    btnValue:string|null,
    btnLink:string,
    btnPosition:"start"|"center"|"end"|null
}

type ColumnImageProps = {
    src:string,
    alt?:string,
    revert:boolean
};

const ColumnText:FunctionComponent<ColumnTextProps> = ({
    children,
    title,
    subtitle,
    btnValue,
    btnLink,
    btnPosition
})=>{
    return(
        <div className="column column-text">
            <TitleBox title={title} subtitle={subtitle}/>
            {children}
            {!!btnValue&& <ButtonBox value={btnValue} to={btnLink} position={btnPosition} />}
        </div>
    );
};

const ColumnImage:FunctionComponent<ColumnImageProps>= ({src,alt,revert})=>{
    return(
        <div className="column column-image" style={{alignItems:revert?"flex-end":"flex-start"}}>
            <img src={src} alt={alt} loading="lazy"/>
        </div>
    );
};

const TwoColumn:FunctionComponent<TwoColumnProps> = ({
    children,
    imageSrc = "",
    alt = "",
    title = "",
    subtitle = "",
    btnValue = null,
    btnLink = "/",
    btnPosition = null,
    revert = false
})=>{

    return(
        <div className={classNames("twoColumn",{revertColumn:revert})}>
            <ColumnImage src={imageSrc} alt={alt} revert={revert}/>
            <ColumnText title={title} subtitle={subtitle} btnValue={btnValue} btnLink={btnLink} btnPosition={btnPosition}>
                {children}
            </ColumnText>
        </div>
    );
};

export default TwoColumn;
