import React, { FunctionComponent, MouseEvent, useCallback, useContext, useEffect, useMemo, useRef, useState, } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { classNames, Period } from "@ct-react/core";
import { RangePickerController } from "@ct-react/calendar";
import moment, { Moment } from "moment";
import SubInput from "./subInput";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faDog, faMagnifyingGlass, faSquareParking, faUsers, faWifi, faXmark } from '@fortawesome/free-solid-svg-icons';
import gsap from "gsap";
import { generateSearchArticleParamData, generateSearchArticleURL } from "../../utils/search";
import { FilterModuleContext } from "../../contexts/filterModule";
import { LayoutContext } from "../../contexts/layout";
import useShowSearchModule from "../../hooks/useShowSearchModule";
import BREAKPOINTS from "../../utils/breakpoints";
import useIsHomepage from "../../hooks/useIsHomepage";
import { useLocaleContext,translateMatchableIntlDef } from "@ct-react/locale";
import { FormattedMessage, useIntl } from "react-intl";
import { gql, useLazyQuery } from "@apollo/client";
import { PrimaryButton } from "../../components/button";
import { FirePlace, DishWasher, Tv } from "../../components/wrapper/icones";
import "./search-module.scss";
import { commonDefs } from "../../i18n";

type FormEvent = React.FormEvent<HTMLInputElement>;

const initialDate:Period = {
    start:undefined,
    end:undefined
};

const outputType = (typeOfSearch:string)=>{ //Output correct type for objectType search
    if(typeOfSearch === "sale") return "SALE";
    if(typeOfSearch === "weekly") return "RENTAL";
    if(typeOfSearch === "seasonal") return "SEASONAL";
    if(typeOfSearch === "annual") return "ANNUAL";
};

const OBJECT_QUERY = gql`
 query objectTypes($type: AccommodationArticleType){
    searchableAccommodationObjectTypes(type: $type)
 }
`;

const SearchModule:FunctionComponent = ()=>{
    const { locale } = useLocaleContext();
    const location = useLocation();
    const layout  = useContext(LayoutContext);
    const isVisible = useShowSearchModule();
    const intl = useIntl();

    //Search
    const navigate = useNavigate();
    const [searchParams,setSearchParams] = useSearchParams();


    //data managment
    const {searchData,dispatchSearchData} = useContext(FilterModuleContext);
    const [typeOfSearch,setTypeOfSearch] = useState<string>("weekly");
    const [objectTypes, setObjectTypes] = useState<any>([]);

    //Calendar
    const [ pickerFocus, setPickerFocus ] = useState<any>("start");
    const [range, setRange] = useState<any>(searchData.stay || initialDate);

    //Range
    const [roomsRangeColorRange, setRoomsRangeColorRange] = useState<string>("linear-gradient(to right, var(--borderColor) 0%, var(--red) 0%, var(--red) 100%, var(--borderColor) 100%)");
    const [spanMinRoomPosition, setSpanMinRoomPosition] = useState<string>("0");
    const [spanMaxRoomPosition, setSpanMaxRoomPosition] = useState<string>("18em");

    const [priceColorRange, setPriceColorRange] = useState<string>("linear-gradient(to right, var(--borderColor) 0%, var(--red) 0%, var(--red) 100%, var(--borderColor) 100%)");
    const [spanMinPricePosition, setSpanMinPricePosition] = useState<string>("0");
    const [spanMaxPricePosition, setSpanMaxPricePosition] = useState<string>("6em");

    const [surfaceColorRange,setSurfaceColorRange] = useState<string>("linear-gradient(to right, var(--borderColor) 0%, var(--red) 0%, var(--red) 50%, var(--borderColor) 50%)");
    const [spanMinSurfacePosition, setSpanMinSurfacePosition] = useState<string>("0");
    const [maxPrice, setMaxPrice] = useState<number>(0);

    //Module UI managment
    const [searchModuleDown,setSearchModuleDown] = useState<boolean>(false);
    const [filterModuleActive,setFilterModuleActive] = useState<boolean>(false);
    const [filterModuleInputs,setFilterModuleInputs] = useState<boolean>(false);

    //Smartphone V2

    const [rentDataState, setRentDataState] = useState<{period:boolean, person:boolean}>({period:false, person:false});

    //module position
    const [modulePosition,setModulePosition] = useState<"down"|"up">("down");
    const [ isWitnessVisible, setIsWitnessVisible ] = useState<boolean>(false);
    const isHomepage:boolean = useIsHomepage();

    useEffect(()=>{ // Set module position according to page
        if(isHomepage){
            setModulePosition("down");
        }else{
            setModulePosition("up");
        }
    },[location.pathname]);

    //Classes distribution
    let locationClassName = "searchModule-button";
    let sellClassName = "searchModule-button";
    if(typeOfSearch != "sale"){
        locationClassName =  locationClassName + " primary"
    }else{
        sellClassName =  sellClassName + " primary"
    }
    if(typeOfSearch === "weekly"){
        locationClassName = locationClassName + " cornerTopLeft"
        sellClassName =  sellClassName + " cornerTopRight"
    }

    //Type of Search

    const [ getObjectType, { data, loading } ] = useLazyQuery( OBJECT_QUERY, {
        ssr: false,
        variables:{type:outputType(typeOfSearch)},
        onCompleted:(data:any)=>{
            setObjectTypes(data.searchableAccommodationObjectTypes);
            dispatchSearchData({
                type: 'addTypeOfArticle',
                value: !!searchParams.get("objectTypes") ? searchParams.get("objectTypes")?.split("-") : data.searchableAccommodationObjectTypes,
            });
        }
    });

    useEffect(()=>{ //init Object type choice
        searchData.typeOfArticle.length<1 && getObjectType();
    },[]);

    useEffect(()=>{ //Change type of search on page change
        const path = location.pathname;
        let type = "weekly";
        if(path.includes("semaine")){type = "weekly";}
        if(path.includes("annee")){type = "annual";}
        if(path.includes("saison")){type = "seasonal";}
        if(path.includes("acheter")){type = "sale";}
        setTypeOfSearch(type);
    },[location]);

    useEffect(()=>{ //Update objectType if search link cliked on footer
        const objectTypes = searchParams.get("objectTypes");
        if(objectTypes){
            const nextArray = objectTypes.split("-");
            dispatchSearchData({
                type: 'addTypeOfArticle',
                value: nextArray,
              });
        }
    },[searchParams])

    const handleTypeOfSearch = (e:MouseEvent|FormEvent)=>{ //Handle change of type of search
        getObjectType();

        const target = e.currentTarget.id

        setTypeOfSearch(target)
        setRange(initialDate)
        dispatchSearchData({
            type: 'TypeOfSearch',
            value: target,
        });
        setMaxPrice(setMaximumPrice(searchData.type))
    }

    //Range Price

    const setMaximumPrice = (type) => {
        let price;
        if(type === "sale"){price = 10000000}
        if(type === "annual"){price = 5000}
        if(type === "seasonal" || type === "weekly"){price = 50000}
        return price;
    };

    useEffect(() => {
        setMaximumPrice(searchData.type);
    },[])

    const handleMinPrice = (e:FormEvent)=>{
        const value = parseInt(e.currentTarget.value)
        dispatchSearchData({
            type: 'minPrice',
            value: value,
          });
    };

    const handleMaxPrice =(e:FormEvent)=>{
        const value = parseInt(e.currentTarget.value)
        dispatchSearchData({
            type: 'maxPrice',
            value: value,
          });
    };

    const handlePriceRangeInputUI = useCallback(()=>{
        if(maxPrice === 0) return
        let percent1 = !!searchData.price ? (searchData.price[0] / maxPrice) * 100 : 0;
        let percent2 = !!searchData.price ? (searchData.price[1] / maxPrice) * 100 : 100;
        setPriceColorRange(`linear-gradient(to right, #dadae5 ${percent1}% , #B02318 ${percent1}% , #B02318 ${percent2}%, #dadae5 ${percent2}%)`);
        let left1 = percent1<1? 0 : (percent1/10)*0.8-1;
        let left2 = (percent2/10)*1.2+1;
        setSpanMinPricePosition(`${left1.toString()}em`);
        setSpanMaxPricePosition(`${left2.toString()}em`);
    },[searchData.price])

    const formatPrice = (value:number):string=>{
        if(value/1000>=1){
            return `${value/1000}K`
        }else{
            return value.toString()
        }
    };

    useEffect(()=>{
        handlePriceRangeInputUI()
    },[searchData.price]);

    //Surface

    const handleSurface = (e:FormEvent)=>{
        const value = parseInt(e.currentTarget.value)
        dispatchSearchData({
            type: 'surface',
            value: value,
        });
    };

    const handleSurfaceRangeInputUI = ()=>{
        //@ts-ignore
        let percent = (parseInt(searchData.minSurface) / 250) * 100;
        setSurfaceColorRange(`linear-gradient(to right, #dadae5 0% , #B02318 0% , #B02318 ${percent}%, #dadae5 ${percent}%)`);
        let left = (percent/10)*1.3
        setSpanMinSurfacePosition(`${left.toString()}em`)
    };

    useEffect(()=>{
        handleSurfaceRangeInputUI()
    },[searchData.minSurface]);

    //ObjectType

    const handleTypeOfArticle = (e:FormEvent)=>{
        const value = e.currentTarget.value
        if(e.currentTarget.checked){//add
            const nextArray = [...searchData.typeOfArticle,value]
            dispatchSearchData({
                type: 'addTypeOfArticle',
                value: nextArray,
            });
        }else{//remove
            const nextArray = [...searchData.typeOfArticle.filter((type:string)=>type!==value)]
            dispatchSearchData({
                type: 'removeTypeOfArticle',
                value: nextArray,
              });
        }
    };

    const renderObjectTypes = useMemo(()=>{
        if(objectTypes === null) return undefined;
        return (
            objectTypes.map((type:string)=>{
                return(
                    <label htmlFor={type} key={type}>
                    <input
                        type="checkbox"
                        id={type}
                        value={type}
                        name={type}
                        checked={searchData.typeOfArticle.includes(type)}
                        onChange={handleTypeOfArticle}
                    />
                    {translateMatchableIntlDef(intl, "objectTypes", type)}
                    </label>
                )
            })
        )
    },[searchData.typeOfArticle,searchParams,location]);

    //Room range

    const handleRoomsRangeMin = (e:FormEvent)=>{
        let value = parseInt(e.currentTarget.value);
        if(!!searchData.roomsRange && value >= searchData.roomsRange[1]){
            value = searchData.roomsRange[1];
        }
        dispatchSearchData({
            type: 'roomsRangeMin',
            value: value,
        });
    };

    const handleRoomsRangeMax = (e:FormEvent)=>{
        let value = parseInt(e.currentTarget.value);
        if(!!searchData.roomsRange && value <= searchData.roomsRange[0]){
            value = searchData.roomsRange[0];
        }
        dispatchSearchData({
            type: 'roomsRangeMax',
            value: value,
          });
    };

    const handleRoomsRangeInputUI = useCallback(()=>{
        let percent1 = !!searchData.roomsRange ? ((searchData.roomsRange[0] - 1) / 5) * 100 : 0;
        let percent2 = !!searchData.roomsRange ? ((searchData.roomsRange[1] - 1) / 5) * 100 : 100;
        setRoomsRangeColorRange(`linear-gradient(to right, #dadae5 ${percent1}% , #B02318 ${percent1}% , #B02318 ${percent2}%, #dadae5 ${percent2}%)`);
        let left1 = percent1<1? 0 : (percent1/10) * 1.6 + 1;
        let left2 = (percent2/10) * 1.6 + 1;
        setSpanMinRoomPosition(`${left1.toString()}em`);
        setSpanMaxRoomPosition(`${left2.toString()}em`);
    },[searchData.roomsRange]);

    useEffect(()=>{
        handleRoomsRangeInputUI();
    },[searchData.roomsRange]);

    const showRoom =  (value) => {
        if(value <= 1) return "Studio";
        if(value === 6) return "6+";
        return value;
    };

    //Form handling

    const submitSearchData = ()=>{
        setFilterModuleActive(false);
        if(searchData.type==="sale"){
            if(location.pathname.split("/")[2]!="acheter"||!!location.pathname.split("/")[3]){
                navigate(`/${locale.basename}/acheter`,{state:searchData,replace:true})
            }else{
                const paramArrays = generateSearchArticleParamData(searchData)
                paramArrays.length>0&&setSearchParams(generateSearchArticleURL(paramArrays))
            }
        }else if(searchData.type==="weekly"){
            if(location.pathname.split("/")[3]!="semaine"||!!location.pathname.split("/")[4]){
                navigate(`/${locale.basename}/louer/semaine`,{state:searchData,replace:true})
            }else{
                const paramArrays = generateSearchArticleParamData(searchData)
                paramArrays.length>0&&setSearchParams(generateSearchArticleURL(paramArrays))
            }
        }else if(searchData.type==="seasonal"){
            if(location.pathname.split("/")[3]!="saison"||!!location.pathname.split("/")[4]){
                navigate(`/${locale.basename}/louer/saison`,{state:searchData,replace:true})
            }else{
                const paramArrays = generateSearchArticleParamData(searchData)
                paramArrays.length>0&&setSearchParams(generateSearchArticleURL(paramArrays))
            }
        }else if(searchData.type==="annual"){
            if(location.pathname.split("/")[3]!="annee"||!!location.pathname.split("/")[4]){
                navigate(`/${locale.basename}/louer/annee`,{state:searchData,replace:true})
            }else{
                const paramArrays = generateSearchArticleParamData(searchData)
                paramArrays.length>0&&setSearchParams(generateSearchArticleURL(paramArrays))
            }
        }else{
            //404!!
        }
    };

    const resetInput = (action:string)=>{
        if(action === "typeOfSearchReset"){
            setTypeOfSearch("weekly") //reset
        }

        if(action === "stayReset"){
            setRange({});
            setPickerFocus("start");
            setRange(initialDate);
            setRentDataState({...rentDataState, period: false})
        }

        if(action === "personReset"){
            setRentDataState({...rentDataState, person: false})
        }

        dispatchSearchData({
            type: action,
            value: null,
          });
    };

    //Module UI handling

    const showSearchTypeChoice = ()=>{
        setSearchModuleDown(!searchModuleDown)
    };

    const handleFilterModule = (bool:boolean,event:React.MouseEvent)=>{
        !isHomepage&&event.currentTarget.classList[0].includes("btn")&&submitSearchData();//submit btn
        setFilterModuleInputs(event.currentTarget.classList[0].includes("dates")?true:false);
        setFilterModuleActive(bool);
    };

    const locationChoiceTitle = useMemo(()=>{
        if(searchData.type==="weekly"){
            return intl.formatMessage({id:"weekly-rental",defaultMessage: "Location à la semaine" });
        }else if(searchData.type==="seasonal"){
            return intl.formatMessage({id:"seasonal-rental", defaultMessage: "Location à la saison" });
        }else if(searchData.type==="annual"){
            return intl.formatMessage({id:"annual-rental", defaultMessage: "Location à l'année" });
        }else {
          return intl.formatMessage(commonDefs.toRent);
        }
    },[searchData.type,locale]);

    useEffect(()=>{
        dispatchSearchData({
            type: 'TypeOfSearch',
            value: searchData.type,
        });
        setMaxPrice(setMaximumPrice(searchData.type));
    },[searchData.type])

    useEffect(()=>{
        const body = document.querySelector("body");
        if(filterModuleActive && !!body){body.style.overflow = "hidden"}
        if(!filterModuleActive && !!body){body.style.overflow = "initial"}
    },[filterModuleActive]);

    //Calendar & NbrPerson

    const isBlockedDate = (day:Moment) => {
        if(moment(day).isBefore(moment())) return true;
        if(day.format("dddd") === "samedi" || day.format("dddd") === "Saturday") return false;
        else return true;
    };

    useEffect(()=>{
        if(pickerFocus === null){
            const formattedRange = {
                from:range.start?.format("YYYY-MM-DD"),
                to:range.end?.format("YYYY-MM-DD")
            }
            dispatchSearchData({
                type: 'stay',
                value: formattedRange,
            });
            setRentDataState({...rentDataState, period: true});
        }else{
            setRentDataState({...rentDataState, period: false});
        }
    },[pickerFocus]);

    const nights = ()=>{
        const diff = range.end!=undefined&&range.end.diff(range.start,"days")
        return intl.formatMessage({
            id: "calendar-number-of-night",
            defaultMessage: "{diff, plural, =1 {# nuit} other {# nuits}}"
          }, {diff})
    };

    const handleNbrPerson = (prop:string,increment:boolean)=>{
        const nextObject = {...searchData.person}
        if(prop==='adult'){
            if(increment){
                //@ts-ignore
                nextObject.adult < 12 && nextObject.adult++
            }else{
                //@ts-ignore
                nextObject.adult !== 0 && nextObject.adult--
            }
        }else if(prop==='child'){
            if(increment){
                //@ts-ignore
                nextObject.child < 12 && nextObject.child++
            }else{
                //@ts-ignore
                nextObject.child !== 0 && nextObject.child--
            }
        }else{
            if(increment){
                //@ts-ignore
                nextObject.exempt < 12 && nextObject.exempt++
            }else{
                //@ts-ignore
                nextObject.exempt !==0 && nextObject.exempt--
            }
        }
        dispatchSearchData({
            type: 'nbrPerson',
            value: nextObject,
        });
        setRentDataState({...rentDataState, person : nextObject.adult>0 ? true : false})
    };

    const nbrPerson = ()=>{
        const adult = searchData.person.adult;
        const child = searchData.person.child;
        const exempt = searchData.person.exempt;
        const total = adult+child+exempt;
        return intl.formatMessage({
            id: "calendar-number-of-person",
            defaultMessage: "{total, plural, =1 {# Personne} =0 {Personne} other {# Personnes}}"
          }, {total})
    };

    const renderSmartphoneBtn = useMemo(()=>{
        if(filterModuleInputs){
            if(rentDataState.person){
                return(
                    <PrimaryButton
                        value={intl.formatMessage({id:"btn-show-results", defaultMessage: "Voir les résultats"})}
                        squared={true}
                        setState={()=>submitSearchData()}
                        disabled={rentDataState.period ? false : true}
                    />
                );
            }else{
                return(
                    <PrimaryButton
                        value={intl.formatMessage({id:"btn-next", defaultMessage: "Suivant"})}
                        squared={true}
                        setState={()=>setFilterModuleInputs(false)}
                        disabled={rentDataState.period ? false : true}
                    />
                );

            }
        }
        if(!filterModuleInputs){
            if(rentDataState.period){
                return(
                    <PrimaryButton
                        value={intl.formatMessage({id:"btn-show-results", defaultMessage: "Voir les résultats"})}
                        squared={true}
                        setState={()=>submitSearchData()}
                        disabled={rentDataState.person ? false : true}
                    />
                );
            }else{
                return(
                    <PrimaryButton
                        value={intl.formatMessage({id:"btn-next", defaultMessage: "Suivant"})}
                        squared={true}
                        setState={()=>setFilterModuleInputs(true)}
                        disabled={rentDataState.person ? false : true}
                    />
                );

            }
        }
    },[filterModuleInputs, rentDataState, searchData])

    //Animation
    const searchModule= useRef<HTMLFormElement>(null);
    const offsetGapRef = useRef<number>(100);

    const onScroll = () => {
        setIsWitnessVisible(window.scrollY >= offsetGapRef.current);
    };

    useEffect(() => {
        window.addEventListener("scroll", () => onScroll());
        return () => { window.addEventListener("scroll", () => onScroll()); };
    },[]);

    useEffect(()=>{
        if(window.innerWidth>BREAKPOINTS.desktop){
            if(isHomepage){
                if(isWitnessVisible){
                    gsap.to(searchModule.current,{
                        position:"fixed",
                        top:40,
                        className:"searchModule up",
                        ease:"power1",
                        duration:0.5,
                        onComplete : ()=>setModulePosition("up"),
                    })
                }else{
                    gsap.to(searchModule.current,{
                        position:"absolute",
                        top:"85vh",
                        className:"searchModule down",
                        ease:"power1",
                        duration:0.5,
                        onComplete : ()=>setModulePosition("down"),
                    })
                }
            }else{
                gsap.to(searchModule.current,{
                    position:"sticky",
                    top:40,
                    className:"searchModule up",
                    ease:"power1",
                    duration:0.5
                })
            }
        }
    },[isWitnessVisible,isHomepage]);

    return(
        <>
            <form id="searchModule" ref={searchModule} className={classNames(`${modulePosition} searchModule`,layout.includes("smartphone")? {show:isVisible,hide:!isVisible} : "")}>

                {/*BROWSER MODULE*/}
                <div className="searchModule-container browser">
                    <div className="searchTypeButtons">
                        <div id="location" className={typeOfSearch==="sale"?"searchModule-button":"primary searchModule-button"}>
                            {locationChoiceTitle}
                            <svg className="searchModule-arrow" xmlns="http://www.w3.org/2000/svg" width="0.561rem" viewBox="0 0 10.773 5.38">
                                <path d="M10.377,14.377a.768.768,0,0,1-.492-.177L5.276,10.359a.769.769,0,0,1,.983-1.183l4.117,3.441L14.494,9.3a.788.788,0,1,1,.976,1.237l-4.609,3.71a.768.768,0,0,1-.484.131Z" transform="translate(-4.999 -8.999)"/>
                            </svg>
                            <SubInput
                                onReset={resetInput}
                                key="typeOfSearch"
                                type="typeOfSearch"
                                title={intl.formatMessage({ id: "search-rent-type-title", defaultMessage: "Quelle location désirez-vous ?" })}
                                modulePosition={modulePosition}
                                className="type"
                            >
                                <label htmlFor="weekly">
                                    <input type="radio" id="weekly" value="weekly" name="locationBrowser" checked={typeOfSearch === "weekly" ? true : false} onChange={handleTypeOfSearch}/>
                                    {intl.formatMessage({id:"weekly-rental", defaultMessage: "Location à la semaine"})}
                                </label>
                                <label htmlFor="seasonal">
                                    <input type="radio" id="seasonal" value="seasonal" name="locationBrowser" checked={typeOfSearch === "seasonal" ? true : false} onChange={handleTypeOfSearch}/>
                                    {intl.formatMessage({id:"seasonal-rental", defaultMessage: "Location à la saison"})}
                                </label>
                                <label htmlFor="annual">
                                    <input type="radio" id="annual" value="annual" name="locationBrowser" checked={typeOfSearch === "annual" ? true : false} onChange={handleTypeOfSearch}/>
                                    {intl.formatMessage({id:"annual-rental", defaultMessage: "Location à l'année"})}
                                </label>
                            </SubInput>
                        </div>
                        <div id="sale" className={typeOfSearch==="sale"?"primary searchModule-button":"searchModule-button"} onMouseDown={handleTypeOfSearch}>
                            {intl.formatMessage({id:"sale", defaultMessage: "Acheter"})}
                        </div>
                    </div>
                    <div className="searchModule-button">{/*ObjectType*/}
                        {intl.formatMessage({id:"searchmodule-types-of-object", defaultMessage: "Types"})}
                        <svg className="searchModule-arrow" xmlns="http://www.w3.org/2000/svg" width="0.561rem" viewBox="0 0 10.773 5.38">
                            <path d="M10.377,14.377a.768.768,0,0,1-.492-.177L5.276,10.359a.769.769,0,0,1,.983-1.183l4.117,3.441L14.494,9.3a.788.788,0,1,1,.976,1.237l-4.609,3.71a.768.768,0,0,1-.484.131Z" transform="translate(-4.999 -8.999)"/>
                        </svg>
                        <SubInput
                            onReset={()=>resetInput}
                            key="typeOfArticle"
                            type="typeOfArticle"
                            title={intl.formatMessage({id:"choose-type-of-object", defaultMessage: "Quel(s) type(s) de bien ?"})}
                            modulePosition={modulePosition}
                        >
                            {renderObjectTypes}
                        </SubInput>
                    </div>
                    <div className="searchModule-button">{/*Price or Calendar*/}
                        {typeOfSearch!=="weekly"?
                        ( //Price
                            <>
                                {!!searchData.price ?
                                    <>{formatPrice(searchData.price[0])} - {formatPrice(searchData.price[1])}</>
                                :
                                    intl.formatMessage({id:"price", defaultMessage: "Prix"})
                                }
                                <svg className="searchModule-arrow" xmlns="http://www.w3.org/2000/svg" width="0.561rem" viewBox="0 0 10.773 5.38">
                                    <path d="M10.377,14.377a.768.768,0,0,1-.492-.177L5.276,10.359a.769.769,0,0,1,.983-1.183l4.117,3.441L14.494,9.3a.788.788,0,1,1,.976,1.237l-4.609,3.71a.768.768,0,0,1-.484.131Z" transform="translate(-4.999 -8.999)"/>
                                </svg>
                                <SubInput
                                    onReset={resetInput}
                                    key="price"
                                    type="price"
                                    title={intl.formatMessage({id:"searchmodule-choose-price",defaultMessage: "Quel prix désirez-vous ?"})}
                                    modulePosition={modulePosition}
                                >
                                    <label className="rangeLabel">
                                        <div className="inputRangeColor" style={!!priceColorRange?{background:priceColorRange}:{}}></div>
                                        <input name="minPrice" type="range" step={typeOfSearch==="sale"?50000:100} value={!!searchData.price ? searchData.price[0] : 0} min={0} max={maxPrice} onChange={handleMinPrice}/>
                                        <input name="maxPrice" type="range" step={typeOfSearch==="sale"?50000:100} value={!!searchData.price ? searchData.price[1] : maxPrice } min={0} max={maxPrice} onChange={handleMaxPrice}/>
                                        <span style={{left:spanMinPricePosition}}>{`CHF ${!!searchData.price ? formatPrice(searchData.price[0]) : 0}`}</span>
                                        <span style={{left:spanMaxPricePosition}}>{`CHF ${!!searchData.price ? formatPrice(searchData.price[1]) : maxPrice}`}</span>
                                    </label>
                                </SubInput>
                            </>
                        )
                            :
                        (//Calendar
                            <>
                                {(!!range.start && !!range.end) ? nights() : intl.formatMessage({id:"searchmodule-arrival-departure",defaultMessage: "Arrivée-Départ"})}
                                <svg className="searchModule-arrow" xmlns="http://www.w3.org/2000/svg" width="0.561rem" viewBox="0 0 10.773 5.38">
                                    <path d="M10.377,14.377a.768.768,0,0,1-.492-.177L5.276,10.359a.769.769,0,0,1,.983-1.183l4.117,3.441L14.494,9.3a.788.788,0,1,1,.976,1.237l-4.609,3.71a.768.768,0,0,1-.484.131Z" transform="translate(-4.999 -8.999)"/>
                                </svg>
                                <SubInput
                                    onReset={()=>resetInput("stayReset")}
                                    key="stay"
                                    type="stay"
                                    title={
                                        <FormattedMessage
                                        id="searchmodule-choose-dates"
                                        defaultMessage="Quelles sont vos dates? <span> Du Samedi au Samedi uniquement</span>"
                                        values={{
                                          span: (...chunks) => <span>{chunks}</span>,
                                        }}
                                        />
                                    }
                                    modulePosition={modulePosition}
                                >
                                <RangePickerController
                                    selectedRange={range}
                                    pickFocus={pickerFocus}
                                    onSelectedRangeChange={setRange}
                                    onPickFocusChange={setPickerFocus}
                                    numberOfMonths={window.innerWidth > BREAKPOINTS.desktop ? 2 : 1}
                                    minDate={moment()}
                                    maxDate={moment().add(1, 'y')}
                                    isDayBlocked={isBlockedDate}
                                />
                                <span>
                                    {intl.formatMessage({id:"contact-for-special-date", defaultMessage:"Pour toute date spéciale n'hésitez pas à nous contacter."})}
                                </span>
                                </SubInput>
                            </>
                        )
                        }
                    </div>
                    <div className="searchModule-button">{/*minRoom*/}
                        {!!searchData.roomsRange ?
                        <>{`${showRoom(searchData.roomsRange[0])} - ${showRoom(searchData.roomsRange[1])}`}</>
                        :
                        intl.formatMessage({id:"rooms", defaultMessage: "Pièces"})
                        }
                        <svg className="searchModule-arrow" xmlns="http://www.w3.org/2000/svg" width="0.561rem" viewBox="0 0 10.773 5.38">
                            <path d="M10.377,14.377a.768.768,0,0,1-.492-.177L5.276,10.359a.769.769,0,0,1,.983-1.183l4.117,3.441L14.494,9.3a.788.788,0,1,1,.976,1.237l-4.609,3.71a.768.768,0,0,1-.484.131Z" transform="translate(-4.999 -8.999)"/>
                        </svg>
                        <SubInput
                            onReset={resetInput}
                            key="room"
                            type="room"
                            title={intl.formatMessage({id:"choose-minRoom",defaultMessage: "Combien de pièces ?"})}
                            modulePosition={modulePosition}
                        >
                            <label className="rangeLabel">
                                <div className="inputRangeColor" style={!!roomsRangeColorRange?{background:roomsRangeColorRange}:{}}></div>
                                <input name="minRoom" type="range" step={1} value={!!searchData.roomsRange ? searchData.roomsRange[0] : 1} min={1} max={6} onChange={handleRoomsRangeMin}/>
                                <input name="maxRoom" type="range" step={1} value={!!searchData.roomsRange ? searchData.roomsRange[1] : 6 } min={1} max={6} onChange={handleRoomsRangeMax}/>
                                <span style={{left:spanMinRoomPosition}}>{`${!!searchData.roomsRange ? showRoom(searchData.roomsRange[0]) : "Studio"}`}</span>
                                <span style={{left:spanMaxRoomPosition}}>{`${!!searchData.roomsRange ? showRoom(searchData.roomsRange[1]) : "6+"}`}</span>
                            </label>
                        </SubInput>
                    </div>
                    <div className={classNames('searchModule-button',{persons: typeOfSearch!=="sale"&&typeOfSearch!=="annual"})}>{/*Person and surface*/}
                        {typeOfSearch!=="sale"&&typeOfSearch!=="annual"?
                                    (
                                    <>
                                        {nbrPerson()}
                                        <svg className="searchModule-arrow" xmlns="http://www.w3.org/2000/svg" width="0.561rem" viewBox="0 0 10.773 5.38">
                                            <path d="M10.377,14.377a.768.768,0,0,1-.492-.177L5.276,10.359a.769.769,0,0,1,.983-1.183l4.117,3.441L14.494,9.3a.788.788,0,1,1,.976,1.237l-4.609,3.71a.768.768,0,0,1-.484.131Z" transform="translate(-4.999 -8.999)"/>
                                        </svg>
                                        <SubInput
                                            onReset={resetInput}
                                            key="person"
                                            type="person"
                                            title={intl.formatMessage({id:"nbrOfPerson",defaultMessage: "Nombre de personnes"})}
                                            modulePosition={modulePosition}
                                        >
                                            <div className="nbrPerson">
                                                <div className="nbrPerson-block">
                                                    <p>
                                                        {intl.formatMessage({id:"nbrPerson-adult", defaultMessage: "Adultes"})}
                                                    </p>
                                                    <div className="counter">
                                                        <button type="button" onClick={()=>handleNbrPerson('adult',false)} disabled = {searchData.person.adult=== 0 }>-</button>
                                                        <span>{searchData.person.adult}</span>
                                                        <button type="button" onClick={()=>handleNbrPerson('adult',true)} disabled = {searchData.person.adult=== 12 }>+</button>
                                                    </div>
                                                </div>
                                                <div className="nbrPerson-block">
                                                    <p> {intl.formatMessage({id:"nbrPerson-child", defaultMessage: "Enfants"})}
                                                    <span className="font14">
                                                        {intl.formatMessage({id:"nbrPerson-child-age-range", defaultMessage: "De 6 à 16 ans"})}
                                                    </span>
                                                    </p>
                                                    <div className="counter">
                                                    <button type="button" onClick={()=>handleNbrPerson('child',false)} disabled = {searchData.person.child=== 0 }>-</button>
                                                    <span>{searchData.person.child}</span>
                                                    <button type="button" onClick={()=>handleNbrPerson('child',true)} disabled = {searchData.person.child=== 12 }>+</button>
                                                    </div>
                                                </div>
                                                <div className="nbrPerson-block">
                                                    <p>{intl.formatMessage({id:"nbrPerson-child", defaultMessage : "Enfants"})}
                                                        <span className="font14">
                                                        {intl.formatMessage({id:"nbrPerson-exempt-age-range", defaultMessage: "De 0 à 5 ans"})}
                                                        </span>
                                                    </p>
                                                    <div className="counter">
                                                        <button type="button" onClick={()=>handleNbrPerson('exempt',false)} disabled = {searchData.person.exempt=== 0 }>-</button>
                                                        <span>{searchData.person.exempt}</span>
                                                        <button type="button" onClick={()=>handleNbrPerson('exempt',true)} disabled = {searchData.person.exempt=== 12 }>+</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </SubInput>
                                    </>
                                    )
                                    :
                                    (
                                    <>
                                        {!!searchData.minSurface ?
                                            <>{searchData.minSurface} m²</>
                                            :
                                            intl.formatMessage({id: "surface", defaultMessage: "Surface"})
                                        }
                                        <svg className="searchModule-arrow" xmlns="http://www.w3.org/2000/svg" width="0.561rem" viewBox="0 0 10.773 5.38">
                                            <path d="M10.377,14.377a.768.768,0,0,1-.492-.177L5.276,10.359a.769.769,0,0,1,.983-1.183l4.117,3.441L14.494,9.3a.788.788,0,1,1,.976,1.237l-4.609,3.71a.768.768,0,0,1-.484.131Z" transform="translate(-4.999 -8.999)"/>
                                        </svg>
                                        <SubInput
                                            onReset={resetInput}
                                            key="surface"
                                            type="surface"
                                            title={intl.formatMessage({id:"choose-surface", defaultMessage: "Combien de m² minimum ?"})}
                                            modulePosition={modulePosition}
                                        >
                                            <label className="rangeLabel">
                                                <div className="inputRangeColor" style={!!surfaceColorRange?{background:surfaceColorRange}:{}}></div>
                                                <input id="minSurface" name="minSurface" type="range" value={searchData.minSurface} step={10} min={0} max={250} onChange={handleSurface}/>
                                                <span style={{left:spanMinSurfacePosition}}>{searchData.minSurface} m²</span>
                                            </label>
                                        </SubInput>
                                    </>
                                    )
                        }
                    </div>
                    <button type="button" className="searchbutton secondary" onClick={submitSearchData}>
                        {intl.formatMessage({id:"btn-search", defaultMessage: "Rechercher"})}
                        <svg xmlns="http://www.w3.org/2000/svg" width="0.735rem" height="11.036" viewBox="0 0 14.116 11.036">
                            <g transform="translate(13.116 1.414) rotate(90)">
                                <path d="M0,12.116V0" transform="translate(4.104)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><path d="M0,4.1,4.1,0l4.1,4.1" transform="translate(0)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            </g>
                        </svg>
                    </button>
                </div>

                {/*SMARTPHONE MODULE*/}
                <div className={classNames("searchModule-container smartphone" ,{isVisible:isVisible})} >
                    <div className="searchTypeButtons rowWrap">
                        {isHomepage&&
                        <>
                            <div id="location" className={locationClassName} onClick={showSearchTypeChoice}>
                                {locationChoiceTitle}
                                <svg className="searchModule-arrow" xmlns="http://www.w3.org/2000/svg" width="0.8rem" viewBox="0 0 10.773 5.38">
                                    <path d="M10.377,14.377a.768.768,0,0,1-.492-.177L5.276,10.359a.769.769,0,0,1,.983-1.183l4.117,3.441L14.494,9.3a.788.788,0,1,1,.976,1.237l-4.609,3.71a.768.768,0,0,1-.484.131Z" transform="translate(-4.999 -8.999)" />
                                </svg>
                                <div className={`searchTypeChoice-mobile ${searchModuleDown ? "show" : "hide"}`}>
                                    <label htmlFor="weekly">
                                        <input type="radio" id="weekly" value="weekly" name="locationSmartphone" checked={typeOfSearch === "weekly" ? true : false} onChange={handleTypeOfSearch} onInput={()=>{setSearchModuleDown(false)}} />
                                        {intl.formatMessage({id:"weekly-rental", defaultMessage: "Location à la semaine" })}
                                    </label>
                                    <label htmlFor="seasonal">
                                        <input type="radio" id="seasonal" value="seasonal" name="locationSmartphone" checked={typeOfSearch === "seasonal" ? true : false} onChange={handleTypeOfSearch} onInput={()=>{setSearchModuleDown(false)}} />
                                        {intl.formatMessage({id:"seasonal-rental", defaultMessage: "Location à la saison" })}
                                    </label>
                                    <label htmlFor="annual">
                                        <input type="radio" id="annual" value="annual" name="locationSmartphone" checked={typeOfSearch === "annual" ? true : false} onChange={handleTypeOfSearch} onInput={()=>{setSearchModuleDown(false)}} />
                                        {intl.formatMessage({id:"annual-rental",  defaultMessage: "Location à l'année" })}
                                    </label>
                                    <div className="subInput-validation">
                                        <p id="typeOfSearchReset" onClick={function (e) { resetInput(e.currentTarget.id) } } className="bold-black">
                                            {intl.formatMessage(commonDefs.toReset)}
                                        </p>
                                        {/* <p className="bold-red" onClick={() => setSearchModuleDown(false)}>Valider</p> */}
                                    </div>
                                </div>
                            </div>
                            <div id="sale" className={sellClassName} onMouseDown={handleTypeOfSearch} onClick={() => setSearchModuleDown(false)}>
                                {intl.formatMessage(commonDefs.sale)}
                            </div>
                        </>
                        }
                        {typeOfSearch==="weekly"&& //Nbr person & Period if weekly
                        <>
                            <div className="dates-mobile searchModule-button" onClick={(e)=>handleFilterModule(true,e)} style={isHomepage?{}:{borderTopLeftRadius:"var(--borderSmall)"}}>
                                <FontAwesomeIcon icon={faCalendarDays}/>
                                {!!range.start&&!!range.end?nights():intl.formatMessage({id:"dates", defaultMessage: "Dates"})}
                            </div>
                            <div className="nbrPerson-mobile searchModule-button" onClick={(e)=>handleFilterModule(true,e)} style={isHomepage?{}:{borderTopRightRadius:"var(--borderSmall)"}}>
                                <FontAwesomeIcon icon={faUsers}/>
                                {nbrPerson()}
                            </div>
                        </>
                        }
                    </div>
                </div>

                {isHomepage&&
                    <button type="button" className="searchbutton primary smartphone" onClick={submitSearchData}>
                        {intl.formatMessage({id:"btn-search", defaultMessage: "Rechercher"})}
                    </button>
                }
            </form>


            {/*DATE AND NBRPERSON MODULE*/}
            {filterModuleActive&&
            <div className={`filterInputs ${filterModuleActive ? "show" : "hide"}`} >
                <div className="filterInputs-header">
                    <h4>{filterModuleInputs?intl.formatMessage({id:"dates", defaultMessage: "Dates"}):intl.formatMessage({id:"persons", defaultMessage: "Personnes"})}</h4>
                    <FontAwesomeIcon icon={faXmark} onClick={(e)=>handleFilterModule(false,e)} />
                </div>
                {!filterModuleInputs&&
                    <div className="searchModuleInputs">{/*Nbr person*/}
                        <h5 className="searchModuleInputs-title">
                            {intl.formatMessage({id:"nbrOfPerson", defaultMessage: "Nombre de personnes"})}
                        </h5>
                        <div className="nbrPerson">
                            <div className="nbrPerson-block">
                                <p>
                                    {intl.formatMessage({id:"nbrPerson-adult", defaultMessage: "Adultes"})}
                                </p>
                                <div className="counter">
                                    <button type="button" onClick={()=>handleNbrPerson('adult',false)} disabled = {searchData.person.adult=== 0 }>-</button>
                                    <span>{searchData.person.adult}</span>
                                    <button type="button" onClick={()=>handleNbrPerson('adult',true)} disabled = {searchData.person.adult=== 12 }>+</button>
                                </div>
                            </div>
                            <div className="nbrPerson-block">
                            <p> {intl.formatMessage({id:"nbrPerson-child", defaultMessage: "Enfants"})}
                                <span className="font14">
                                    {intl.formatMessage({id:"nbrPerson-child-age-range", defaultMessage: "De 6 à 16 ans"})}
                                </span>
                            </p>
                                <div className="counter">
                                    <button type="button" onClick={()=>handleNbrPerson('child',false)} disabled = {searchData.person.child=== 0 }>-</button>
                                    <span>{searchData.person.child}</span>
                                    <button type="button" onClick={()=>handleNbrPerson('child',true)} disabled = {searchData.person.child=== 12 }>+</button>
                                </div>
                            </div>
                            <div className="nbrPerson-block">
                                <p>{intl.formatMessage({id:"nbrPerson-child", defaultMessage : "Enfants"})}
                                    <span className="font14">
                                    {intl.formatMessage({id:"nbrPerson-exempt-age-range", defaultMessage: "De 0 à 5 ans"})}
                                    </span>
                                </p>
                                <div className="counter">
                                    <button type="button" onClick={()=>handleNbrPerson('exempt',false)} disabled = {searchData.person.exempt=== 0 }>-</button>
                                    <span>{searchData.person.exempt}</span>
                                    <button type="button" onClick={()=>handleNbrPerson('exempt',true)} disabled = {searchData.person.exempt=== 12 }>+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {filterModuleInputs&&
                    <div className="searchModuleInputs">{/*Dates*/}
                        <h5 className="searchModuleInputs-title">
                            <FormattedMessage
                                id="searchmodule-choose-dates"
                                defaultMessage="Quelles sont vos dates? <span> Du Samedi au Samedi uniquement</span>"
                                values={{
                                    span: (...chunks) => <span>{chunks}</span>,
                                }}
                            />
                        </h5>
                        <div className="bookingBox-inputDate">
                            <div className="input-block" >
                                <FontAwesomeIcon icon={faCalendarDays}/>
                                <label htmlFor="arrival">
                                    {intl.formatMessage({id:"form-label-arrival", defaultMessage: "Arrivée"})}
                                </label>
                                <input
                                    id="arrival"
                                    type="text"
                                    name="arrival"
                                    placeholder= {intl.formatMessage({ id:"add-date", defaultMessage: "Ajouter une date"})}
                                    readOnly={true}
                                    value={!!range.start?range.start.format("YYYY-MM-DD"):""}
                                />
                            </div>
                            <div className="input-block">
                                <FontAwesomeIcon icon={faCalendarDays}/>
                                <label htmlFor="departure">
                                    {intl.formatMessage({id:"form-label-departure", defaultMessage: "Départ"})}
                                </label>
                                <input
                                    id="departure"
                                    type="text"
                                    name="departure"
                                    placeholder={intl.formatMessage({id:"date-not-defined", defaultMessage: "Non défini"})}
                                    readOnly={true}
                                    value={!!range.end?range.end.format("YYYY-MM-DD"):""}
                                />
                            </div>
                        </div>
                        <RangePickerController
                            selectedRange={range}
                            pickFocus={pickerFocus}
                            onSelectedRangeChange={setRange}
                            onPickFocusChange={setPickerFocus}
                            orientation="vertical"
                            numberOfMonths={window.innerWidth > BREAKPOINTS.desktop ? 2 : 1}
                            minDate={moment()}
                            maxDate={moment().add(1, 'y')}
                            isDayBlocked={isBlockedDate}
                        />
                        <span>
                            {intl.formatMessage({id:"contact-for-special-date", defaultMessage:"Pour toute date spéciale n'hésitez pas à nous contacter."})}
                        </span>
                    </div>
                }
                <div className="filterInputs-footer">
                    <input
                        type="reset"
                        value={ filterModuleInputs? intl.formatMessage(commonDefs.toReset) : intl.formatMessage({id:"btn-delete-all", defaultMessage: "Tout effacer"}) }
                        // onClick={()=>setRentDataState(filterModuleInputs? {...rentDataState, period: false} : {...rentDataState, person: false})}
                        onClick={()=>resetInput(filterModuleInputs ? "stayReset" : "personReset")}
                    />
                    {renderSmartphoneBtn}
                </div>
            </div>
            }
        </>
    );
};

export const FilterSortModule:FunctionComponent = ()=>{
    const { locale } = useLocaleContext();
    const location = useLocation()
    const intl = useIntl();

    //Visibility, Data
    const {isVisible,setIsVisible,searchData,dispatchSearchData} = useContext(FilterModuleContext)

    //Search
    const navigate = useNavigate()
    const [searchParams,setSearchParams] = useSearchParams();

    //Object Type
    const [objectTypes, setObjectTypes] = useState<any>([]);

    //Range
    const [roomsRangeColorRange, setRoomsRangeColorRange] = useState<string>("linear-gradient(to right, var(--borderColor) 0%, var(--red) 0%, var(--red) 100%, var(--borderColor) 100%)");
    const [spanMinRoomPosition, setSpanMinRoomPosition] = useState<string>("0");
    const [spanMaxRoomPosition, setSpanMaxRoomPosition] = useState<string>("6em");
    const [priceColorRange, setPriceColorRange] = useState<string>("linear-gradient(to right, var(--borderColor) 0%, var(--red) 0%, var(--red) 100%, var(--borderColor) 100%)")
    const [spanMinPricePosition, setSpanMinPricePosition] = useState<string>("-0")
    const [spanMaxPricePosition, setSpanMaxPricePosition] = useState<string>("6em")
    const [surfaceColorRange,setSurfaceColorRange] = useState<string>("linear-gradient(to right, var(--borderColor) 0%, var(--red) 0%, var(--red) 0%, var(--borderColor) 0%)")
    const [spanMinSurfacePosition, setSpanMinSurfacePosition] = useState<string>("-0")
    const [maxPrice, setMaxPrice] = useState<number>(searchData.type==="sale"?  10000000 : 10000);

    const handleNbrPerson = (prop:string,increment:boolean)=>{
        let nextObject = {...searchData.person};
        if(prop==='adult'){
            if(increment){
                //@ts-ignore
                nextObject.adult< 12 && nextObject.adult++
            }else{
                //@ts-ignore
                nextObject.adult!==0 && nextObject.adult--
            }
        }else if(prop==='child'){
            if(increment){
                //@ts-ignore
                nextObject.child< 12 && nextObject.child++
            }else{
                //@ts-ignore
                nextObject.child!==0 && nextObject.child--
            }
        }else{
            if(increment){
                //@ts-ignore
                nextObject.exempt< 12 && nextObject.exempt++
            }else{
                //@ts-ignore
                nextObject.exempt!==0 && nextObject.exempt--
            }
        }
        dispatchSearchData({
            type: 'nbrPerson',
            value: nextObject,
        });
    };

    const handleTypeOfArticle = (e:FormEvent)=>{
        const value = e.currentTarget.value
        if(e.currentTarget.checked){//add
            const nextArray = [...searchData.typeOfArticle,value]
            dispatchSearchData({
                type: 'addTypeOfArticle',
                value: nextArray,
                });
        }else{//remove
            const nextArray = [...searchData.typeOfArticle.filter((type:string)=>type!==value)]
            dispatchSearchData({
                type: 'removeTypeOfArticle',
                value: nextArray,
                });
        }
    };

    //Object Type

    const [ getObjectType, { data, loading } ] = useLazyQuery( OBJECT_QUERY, {
        ssr: false,
        variables:{type:outputType(searchData.type)},
        onCompleted:(data:any)=>{
            setObjectTypes(data.searchableAccommodationObjectTypes);
            dispatchSearchData({
                type: 'addTypeOfArticle',
                value: !!searchParams.get("objectTypes") ? searchParams.get("objectTypes")?.split("-") : data.searchableAccommodationObjectTypes,
            });
        }
    });

    useEffect(()=>{ //init Object type choice
        searchData.typeOfArticle.length<1 && getObjectType();
    },[]);

    const renderObjectTypes = useMemo(()=>{
        if(objectTypes === null) return undefined;
        return (
            objectTypes.map((type:string)=>{
                return(
                    <label htmlFor={type} key={type}>
                    <input
                        type="checkbox"
                        id={type}
                        value={type}
                        name={type}
                        checked={searchData.typeOfArticle.includes(type)}
                        onChange={handleTypeOfArticle}
                    />
                    {type}
                    </label>
                )
            })
        )
    },[objectTypes, searchData.typeOfArticle]);

    //Additional filter

    const handleFilter = (e) => {
        const newFilter = e.target.name;
        if(e.currentTarget.checked){//add
            dispatchSearchData({
                type: "filters",
                value: [...searchData.filters, newFilter]
            })
        }else{//remove
            const newArray = searchData.filters!.filter((e:string) => e != newFilter )
            dispatchSearchData({
                type: "filters",
                value: newArray
            })
        }
    };

    const renderAdditionalFilters = useMemo(() => {
        return(
            <div className="filters">
                <label htmlFor="parking">
                    <input
                        type="checkbox"
                        value="parking"
                        id="parking"
                        name="parking"
                        onChange={handleFilter}
                        checked={searchData.filters.includes("parking")}
                        />
                    <FontAwesomeIcon icon={faSquareParking}/>
                </label>
                {searchData.type != "seasonal" &&
                <>
                    <label htmlFor="wifi">
                        <input
                            type="checkbox"
                            value="wifi"
                            id="wifi"
                            name="wifi"
                            onChange={handleFilter}
                            checked={searchData.filters.includes("wifi")}
                            />
                        <FontAwesomeIcon icon={faWifi}/>
                    </label>
                    <label htmlFor="tv">
                        <input
                            type="checkbox"
                            value="tv"
                            id="tv"
                            name="tv"
                            onChange={handleFilter}
                            checked={searchData.filters.includes("tv")}
                            />
                        <Tv/>
                    </label>
                    <label htmlFor="pet">
                        <input
                            type="checkbox"
                            value="pet"
                            id="pet"
                            name="pet"
                            onChange={handleFilter}
                            checked={searchData.filters.includes("pet")}
                            />
                        <FontAwesomeIcon icon={faDog}/>
                    </label>
                </>
                }

                <label htmlFor="fireplace">
                    <input
                        type="checkbox"
                        value="fireplace"
                        id="fireplace"
                        name="fireplace"
                        onChange={handleFilter}
                        checked={searchData.filters.includes("fireplace")}
                        />
                    <FirePlace/>
                </label>
                <label htmlFor="dishwasher">
                    <input
                        type="checkbox"
                        value="dishwasher"
                        id="dishwasher"
                        name="dishwasher"
                        onChange={handleFilter}
                        checked={searchData.filters.includes("dishwasher")}
                        />
                    <DishWasher/>
                </label>
            </div>
        );
    },[searchData]);

    //Room range

    const handleRoomsRangeMin = (e:FormEvent)=>{
        const value = parseInt(e.currentTarget.value)
        dispatchSearchData({
            type: 'roomsRangeMin',
            value: value,
          });
    };

    const handleRoomsRangeMax = (e:FormEvent)=>{
        const value = parseInt(e.currentTarget.value)
        dispatchSearchData({
            type: 'roomsRangeMax',
            value: value,
          });
    };

    const handleRoomsRangeInputUI = useCallback(()=>{
        let percent1 = !!searchData.roomsRange ? ((searchData.roomsRange[0] - 1) / 5) * 100 : 0;
        let percent2 = !!searchData.roomsRange ? ((searchData.roomsRange[1] - 1) / 5) * 100 : 100;
        setRoomsRangeColorRange(`linear-gradient(to right, #dadae5 ${percent1}% , #B02318 ${percent1}% , #B02318 ${percent2}%, #dadae5 ${percent2}%)`);
        let left1 = percent1<1? 0 : (percent1/10) * 1.6 + 1;
        let left2 = (percent2/10) * 1.6 + 1;
        setSpanMinRoomPosition(`${left1.toString()}em`);
        setSpanMaxRoomPosition(`${left2.toString()}em`);
    },[searchData.roomsRange]);

    useEffect(()=>{
        handleRoomsRangeInputUI()
    },[searchData.roomsRange]);

    const showRoom =  (value) => {
        if(value <= 1) return "Studio";
        if(value === 6) return "6+";
        return value;
    };

    const resetModule = ()=>{
        setSurfaceColorRange("linear-gradient(to right, var(--borderColor) 0%, var(--red) 0%, var(--red) 0%, var(--borderColor) 0%)")
        setSpanMinSurfacePosition("0")
        dispatchSearchData({
            type: 'resetFilterModule',
            value: "",
        });
    };

    //Submit data search or navigate according to current url
    const submitSearchData = ()=>{
        setIsVisible(false)
        if(searchData.type==="sale"){
            if(location.pathname.split("/")[2]!="acheter"){
                navigate(`/${locale.basename}/acheter`,{state:searchData,replace:true})
            }else{
                const paramArrays = generateSearchArticleParamData(searchData)
                paramArrays.length>0&&setSearchParams(generateSearchArticleURL(paramArrays))
            }
        }else if(searchData.type==="weekly"){
            if(location.pathname.split("/")[3]!="semaine"){
                navigate(`/${locale.basename}/louer/semaine`,{state:searchData,replace:true})
            }else{
                const paramArrays = generateSearchArticleParamData(searchData)
                paramArrays.length>0&&setSearchParams(generateSearchArticleURL(paramArrays))
            }
        }else if(searchData.type==="seasonal"){
            if(location.pathname.split("/")[3]!="saison"){
                navigate(`/${locale.basename}/louer/saison`,{state:searchData,replace:true})
            }else{
                const paramArrays = generateSearchArticleParamData(searchData)
                paramArrays.length>0&&setSearchParams(generateSearchArticleURL(paramArrays))
            }
        }else if(searchData.type==="annual"){
            if(location.pathname.split("/")[3]!="annee"){
                navigate(`/${locale.basename}/louer/annee`,{state:searchData,replace:true})
            }else{
                const paramArrays = generateSearchArticleParamData(searchData)
                paramArrays.length>0&&setSearchParams(generateSearchArticleURL(paramArrays))
            }
        }else{
            //404!!
        }
    };

    //Surface
    const handleSurface = (e:FormEvent)=>{
        const value = parseInt(e.currentTarget.value)
        dispatchSearchData({
            type: 'surface',
            value: value,
            });
    };

    const handleSurfaceRangeInputUI = ()=>{
        let percent = (searchData.minSurface / 250) * 100;
        setSurfaceColorRange(`linear-gradient(to right, #dadae5 0% , #B02318 0% , #B02318 ${percent}%, #dadae5 ${percent}%)`);
        let left = (percent/10)*1.3
        setSpanMinSurfacePosition(`${left.toString()}em`)
    };

    useEffect(()=>{
        handleSurfaceRangeInputUI()
    },[searchData.minSurface]);

    //Range Price
    const handleMinPrice = (e:FormEvent)=>{
        const value = parseInt(e.currentTarget.value)
        dispatchSearchData({
            type: 'minPrice',
            value: value,
        });
    };

    const handleMaxPrice =(e:FormEvent)=>{
        const value = parseInt(e.currentTarget.value)
        dispatchSearchData({
            type: 'maxPrice',
            value: value,
        });
    };

    const handlePriceRangeInputUI = ()=>{
        let percent1 = !!searchData.price ? (searchData.price[0] / maxPrice) * 100 : 0;
        let percent2 = !!searchData.price ? (searchData.price[1] / maxPrice) * 100 : 100;
        setPriceColorRange(`linear-gradient(to right, #dadae5 ${percent1}% , #B02318 ${percent1}% , #B02318 ${percent2}%, #dadae5 ${percent2}%)`);
        let left1 = percent1<1? 0 : (percent1/10)*0.8-1;
        let left2 = (percent2/10)*1.2+1;
        setSpanMinPricePosition(`${left1.toString()}em`)
        setSpanMaxPricePosition(`${left2.toString()}em`)
    };

    const formatPrice = (value:number):string=>{
        if(value/1000>=1){
            return `${value/1000}K`
        }else{
            return value.toString()
        }
    };

    useEffect(()=>{
        handlePriceRangeInputUI()
    },[searchData.price]);

    useEffect(()=>{
        const body = document.querySelector("body");
        if(isVisible && !!body){body.style.overflow = "hidden"}
        if(!isVisible && !!body){body.style.overflow = "initial"}
    },[isVisible]);

    //Name 

    const handleFilterName = (e) => {
        const criteria = e.target.value;
        dispatchSearchData({
            type: "name",
            value: criteria
        });
    };

    return(
        <div className={`filterInputs ${isVisible ? "show" : "hide"}`} >
        <div className="filterInputs-header">
            <h4>
                {intl.formatMessage({id:"filter-input-filters",defaultMessage: "Filtres"})}
            </h4>
            <FontAwesomeIcon icon={faXmark} onClick={()=>setIsVisible(false)} />
        </div>
        {/*Filters*/}

        <div className="searchModuleInputs">{/*ObjectType*/}
            <h5 className="searchModuleInputs-title">
                {intl.formatMessage({id:"choose-type-of-object", defaultMessage: "Quel(s) type(s) de bien ?"})}
            </h5>
            <div className="searchModuleInputs-block columnWrap">
                {renderObjectTypes}
            </div>
        </div>
        {searchData.type!="weekly"&&<div className="searchModuleInputs">{/*Price*/}
                    <h5 className="searchModuleInputs-title">{intl.formatMessage({id:"price", defaultMessage: "Prix"})}</h5>
                    <div className="searchModuleInputs-block rowWrap">
                        <label className="rangeLabel">
                            <div className="inputRangeColor" style={!!priceColorRange?{background:priceColorRange}:{}}></div>
                            <input name="minPrice" type="range" step={searchData.type==="sale"?50000:100} value={!!searchData.price ? searchData.price[0] : 0} min={0} max={maxPrice} onChange={handleMinPrice}/>
                            <input name="maxPrice" type="range" step={searchData.type==="sale"?50000:100} value={!!searchData.price ? searchData.price[1] : maxPrice } min={0} max={maxPrice} onChange={handleMaxPrice}/>
                            <span style={{left:spanMinPricePosition}}>{`CHF ${!!searchData.price ? formatPrice(searchData.price[0]) : 0}`}</span>
                            <span style={{left:spanMaxPricePosition}}>{`CHF ${!!searchData.price ? formatPrice(searchData.price[1]) : maxPrice}`}</span>
                        </label>
                    </div>
        </div>}
        <div className="searchModuleInputs">{/*Room*/}
            <h5 className="searchModuleInputs-title">
                {intl.formatMessage({id:"choose-minRoom", defaultMessage: "Combien de pièces ?"})}
            </h5>
            <div className="searchModuleInputs-block rowWrap">
                {/* <label className="roomLabel">
                    <input className="roomCheckbox" type="radio" name="room" value="studio" checked={searchData.roomsRange==="studio"?true:false} onChange={handleRoom}/>
                    <span>Studio</span>
                </label>
                <label className="roomLabel">
                    <input className="roomCheckbox" type="radio" name="room"  value="2" checked={searchData.roomsRange==="2"?true:false} onChange={handleRoom}/>
                    <span>2</span>
                </label>
                <label className="roomLabel">
                    <input className="roomCheckbox" type="radio" name="room"  value="3" checked={searchData.roomsRange==="3"?true:false} onChange={handleRoom}/>
                    <span>3</span>
                </label>
                <label className="roomLabel">
                    <input className="roomCheckbox" type="radio" name="room"  value="4" checked={searchData.roomsRange==="4"?true:false} onChange={handleRoom}/>
                    <span>4</span>
                </label>
                <label className="roomLabel">
                    <input className="roomCheckbox" type="radio" name="room"  value="5" checked={searchData.roomsRange==="5"?true:false} onChange={handleRoom}/>
                    <span>5</span>
                </label>
                <label className="roomLabel">
                    <input className="roomCheckbox" type="radio" name="room"  value="6+" checked={searchData.roomsRange==="6+"?true:false} onChange={handleRoom}/>
                    <span>6+</span>
                </label> */}
                <label className="rangeLabel">
                    <div className="inputRangeColor" style={!!roomsRangeColorRange?{background:roomsRangeColorRange}:{}}></div>
                    <input name="minRoom" type="range" step={1} value={!!searchData.roomsRange ? searchData.roomsRange[0] : 0} min={1} max={6} onChange={handleRoomsRangeMin}/>
                    <input name="maxRoom" type="range" step={1} value={!!searchData.roomsRange ? searchData.roomsRange[1] : 6 } min={1} max={6} onChange={handleRoomsRangeMax}/>
                    <span style={{left:spanMinRoomPosition}}>{`${!!searchData.roomsRange ? showRoom(searchData.roomsRange[0]) : "Studio"}`}</span>
                    <span style={{left:spanMaxRoomPosition}}>{`${!!searchData.roomsRange ? showRoom(searchData.roomsRange[1]) : "6+"}`}</span>
                </label>
            </div>
        </div>
        {searchData.type!="sale"&&searchData.type!="annual"?
            searchData.type!="weekly"&&<div className="searchModuleInputs">{/*Nbr person*/}
            <h5 className="searchModuleInputs-title">
                {intl.formatMessage({id:"nbrOfPerson", defaultMessage: "Nombre de personnes"})}
            </h5>
            <div className="nbrPerson">
                <div className="nbrPerson-block">
                    <p>
                        {intl.formatMessage({id:"nbrPerson-adult", defaultMessage: "Adultes"})}
                    </p>
                    <div className="counter">
                        <button type="button" onClick={()=>handleNbrPerson('adult',false)} disabled = {searchData.person.adult=== 0 }>-</button>
                        <span>{searchData.person.adult}</span>
                        <button type="button" onClick={()=>handleNbrPerson('adult',true)} disabled = {searchData.person.adult=== 12 }>+</button>
                    </div>
                </div>
                <div className="nbrPerson-block">
                    <p> {intl.formatMessage({id:"nbrPerson-child", defaultMessage: "Enfants"})}
                        <span className="font14">
                            {intl.formatMessage({id:"nbrPerson-child-age-range", defaultMessage: "De 6 à 16 ans"})}
                        </span>
                    </p>
                    <div className="counter">
                        <button type="button" onClick={()=>handleNbrPerson('child',false)} disabled = {searchData.person.child=== 0 }>-</button>
                        <span>{searchData.person.child}</span>
                        <button type="button" onClick={()=>handleNbrPerson('child',true)} disabled = {searchData.person.child=== 12 }>+</button>
                    </div>
                </div>
                <div className="nbrPerson-block">
                    <p>{intl.formatMessage({id:"nbrPerson-child", defaultMessage : "Enfants"})}
                        <span className="font14">
                        {intl.formatMessage({id:"nbrPerson-exempt-age-range", defaultMessage: "De 0 à 5 ans"})}
                        </span>
                    </p>
                    <div className="counter">
                        <button type="button" onClick={()=>handleNbrPerson('exempt',false)} disabled = {searchData.person.exempt=== 0 }>-</button>
                        <span>{searchData.person.exempt}</span>
                        <button type="button" onClick={()=>handleNbrPerson('exempt',true)} disabled = {searchData.person.exempt=== 12 }>+</button>
                    </div>
                </div>
            </div>
            </div>
            :
            <div className="searchModuleInputs">{/*Surface*/}
                <h5 className="searchModuleInputs-title">
                    {intl.formatMessage({id:"choose-surface", defaultMessage: "Combien de m² minimum ?"})}
                </h5>
                <div className="searchModuleInputs-block rowWrap">
                    <label className="rangeLabel">
                        <div className="inputRangeColor" style={!!surfaceColorRange?{background:surfaceColorRange}:{}}></div>
                        <input id="minSurface" name="minSurface" type="range" value={!!searchData.minSurface?searchData.minSurface:0} step={10} min={0} max={250} onChange={handleSurface}/>
                        <span style={{left:spanMinSurfacePosition}}>{!!searchData.minSurface?searchData.minSurface:0} m²</span>
                    </label>
                </div>
            </div>

        }
        {searchData.type != "sale" && searchData.type != "annual" &&
            <div className="searchModuleInputs">
                <h5>{intl.formatMessage({id:"choose-equipment", defaultMessage: "Quel(s) équipement(s) ?"})}</h5>
                {renderAdditionalFilters}
            </div>
        }
        {searchData.type != "sale" &&
            <div className="filter-name searchModuleInputs">
                <h5>{intl.formatMessage({id:"accomodation-name-title", defaultMessage: "Nom du logement ?"})}</h5>
                <div>
                    <FontAwesomeIcon icon={faMagnifyingGlass}/>
                    <input type="text" value={searchData.name || ""} onChange={handleFilterName} placeholder={intl.formatMessage({id:"search-by-name-criteria", defaultMessage: "Rechercher par nom"})}/>
                </div>
            </div>
        }
        <div className="filterInputs-footer">
            <input type="reset" value={intl.formatMessage(commonDefs.toReset)} onClick={resetModule} />
            <button type="button" className="btn squared primary" onClick={submitSearchData}>
                {intl.formatMessage({id:"btn-show-results", defaultMessage: "Voir les résultats"})}
            </button>
        </div>
        </div>
    );
};

export default SearchModule;
