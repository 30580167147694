import React, { FunctionComponent, ReactNode, useState } from "react";
import useIsHomePage from "../../hooks/useIsHomepage";
import { classNames } from "@ct-react/core";

type Props={
    children:ReactNode,
    link:string
}

const Submenu:FunctionComponent<Props> = ({children,link})=>{
    const [isDown,setIsDown] = useState<boolean>(false);
    const isHomepage:boolean = useIsHomePage();

    const handleOnClick = ()=>{
        setIsDown(!isDown)
    };
    const handleMouseEnter = ()=>{
        setIsDown(true)
    };
    const handleMouseLeave = ()=>{
        setIsDown(false)
    };

    return(
    <li className="navigation-link" onClick={handleOnClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
        <span className={classNames({whiteBorder : isHomepage})}>
            {link}
            <svg  className="navigation-arrow" style={{transform:isDown?"rotate(180deg)":"rotate(0deg)"}} xmlns="http://www.w3.org/2000/svg" width="0.561em" viewBox="0 0 10.773 5.38">
                <path d="M10.377,14.377a.768.768,0,0,1-.492-.177L5.276,10.359a.769.769,0,0,1,.983-1.183l4.117,3.441L14.494,9.3a.788.788,0,1,1,.976,1.237l-4.609,3.71a.768.768,0,0,1-.484.131Z" transform="translate(-4.999 -8.999)"/>
            </svg>
        </span>
        {isDown&&
        <ul className={classNames("submenu",{white : isHomepage , primary : !isHomepage})} >
        {children}
        </ul>
        }

    </li>
    );
}

export default Submenu;
