import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { PrimaryButton } from "../../components/button";
import "./empty-cart.scss";

const EmptyCart:FunctionComponent = ()=>{
    const intl = useIntl();

    return(
        <div className="empty-cart">
            <h3>{intl.formatMessage({ id:"empty-cart-nothing-found",defaultMessage : "Vous n'avez encore rien trouvé ?"})}</h3>
            <p>{intl.formatMessage({id:"empty-cart-explore-accomodations", defaultMessage : "Parcourez tous les hébergements disponibles."})}</p>
            <PrimaryButton value={intl.formatMessage({ id: "to-discover", defaultMessage: "Découvrir" })} to="louer/semaine"/>
        </div>
    );
};

export default EmptyCart;
