import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { PrimaryButton } from "../../components/button";
import "./notFound.scss";
import banner from "../../assets/banner.png";

const NotFound:FunctionComponent = ()=>{
    const intl = useIntl();
    const img = `url(${banner})`;

    return (
        <div className="notFound" style={{backgroundImage:img}}>
            <div className="blackVeil"></div>
            <span className="error">
                404
                <span className="errorText">{intl.formatMessage({id:"page-not-found-1" , defaultMessage: "T’as où la page ? "})}</span>
            </span>
            <span className="errorText2">
                {intl.formatMessage({id:"page-not-found-2" , defaultMessage: "Il semble qu’il y ait une erreur ou la page que vous cherchez n’existe pas. Si le problème persiste n’hésitez pas à nous le faire savoir."})}
            </span>
            <PrimaryButton value={intl.formatMessage({id:"btn-back-home", defaultMessage: "Retour à l'accueil"})} to="/"/>
        </div>
    );
}

export default NotFound;
