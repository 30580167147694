import { gql, useQuery } from "@apollo/client";
import React, { FunctionComponent, useState } from "react";
import { useLocation } from "react-router";
import { ArticleHeader, ArticleImageGrid, ArticleInfo } from "./articleInfo";
import { IMAGE_FIELDS, LOCATION_FIELDS, SALE_FEATURES_ALL_FIELDS, SEO_ARTICLE } from "../../../shared/fragments";
import { SaleArticleCarousel } from "../../components/carousel";
import Section from "../../components/wrapper/section";
import { ArticleMap } from "../../components/maps/maps";
import { useIntl } from "react-intl";
import { useNavigate } from "@ct-react/locale";
import SeoHelmet from "../../components/seo-helmet";
import { commonDefs } from "../../i18n";

const ARTICLE_DESCRIPTION = gql`
    query GetArticleDescription($articleId: ID!){
        articleDescription(articleId: $articleId){
            value
        }
    }
`;
const ARTICLE_SUMMARY = gql`
    ${IMAGE_FIELDS}
    ${LOCATION_FIELDS}
    ${SALE_FEATURES_ALL_FIELDS}
    query GetArticleSummary($articleId: ID!){
        articleSummary(articleId: $articleId){
            ...on SaleAccommodationSummary{
                __typename,
                id,
                title {value},
                images{...ImageFields},
                location{...LocationFields},
                coordinates,
                priceOnDemand,
                objectType,
                price {currency , amount},
                withPlans,
                features{...SaleFeaturesAllFields}
            }
        }
    }
` ;

const SaleInfo:FunctionComponent=()=>{
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const [articleDescription,setArticleDescription] = useState<string|null>(null);
    const [articleSummary,setArticleSummary] = useState<any|null>(null);
    const [displayable,setDisplayable] = useState<boolean>(false);

    const articleId = location.pathname.split("/")[3];

    const { data } = useQuery(SEO_ARTICLE, {
        variables: { id: articleId },
      });

    useQuery(ARTICLE_DESCRIPTION,{
        variables:{
            articleId:articleId
        },
        onCompleted:(data)=>{
            setArticleDescription(data.articleDescription.value)
        }
    });

    useQuery(ARTICLE_SUMMARY,{
        variables:{
            articleId:articleId
        },
        onCompleted:(data)=>{
            setArticleSummary(data.articleSummary)
            setDisplayable(true)
        },
        onError:(error)=>{
            console.error(error);
            navigate("notFound");
        }

    });

    return(
        <>
        {!!data && <SeoHelmet {...{
            ...data.articleSEO, canonical : location.pathname.slice(4)
        }} />}
        <section className="article-section">
            {displayable&&
            <>
            <ArticleHeader props={articleSummary} type="sale"/>
            <div className="article-image">
                <ArticleImageGrid props={articleSummary.images}/>
            </div>
            <ArticleInfo articleId={articleId} description={articleDescription} props={articleSummary} type="sale"/>
            </>
            }
            <Section className="map" title={intl.formatMessage({  id:"where-is-accomodation", defaultMessage: "Où se trouve le logement ?"})}>
                {displayable&&
                    <ArticleMap coordinates={articleSummary.coordinates}/>
                }
            </Section>
        </section>
        <Section
            title={intl.formatMessage({ id:"other-accomodations-to-buy", defaultMessage: "Autres logements à acheter"})}
            subtitle={intl.formatMessage(commonDefs.sale)}
            btnValue={intl.formatMessage({ id:"btn-see-all-accomodations", defaultMessage: "Voir tous les biens"})}
            btnLink="acheter"
            background="var(--light-grey)"
        >
            <SaleArticleCarousel/>
        </Section>
        </>
    );
}

export default SaleInfo;
