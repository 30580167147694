import React, { FormEvent, FunctionComponent, ReactNode } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { LocaleLink } from "@ct-react/locale";
import { classNames } from "@ct-react/core";
import { useIntl } from "react-intl";
import "./button.scss";

type PrimaryButtonProps = {
    small?:boolean,
    squared?:boolean,
    value:string,
    color?:"primary"|"secondary"|"btnWhite",
    to?:string,
    setState?:(e?:any)=>void,
    icon?:ReactNode,
    iconPos?:"left"|"right"
    disabled?:boolean
};

type FormButtonProps = {
    value: string,
    disabled: boolean,
    onSubmit: (e:FormEvent) => void
};

export const PrimaryButton:FunctionComponent<PrimaryButtonProps> = ({
        value,
        to,
        small = false,
        color = "primary",
        squared = false,
        setState,
        icon = null,
        iconPos = "right",
        disabled = false
    }
    )=>{
    const defaultClass = ["btn"];
    defaultClass.push(color);

    const handleOnClick = ()=>{
        setState!=undefined&&setState();
    };

    return(
        <button
            type="button"
            className={classNames(defaultClass, { small, squared })}
            onClick={handleOnClick}
            disabled={disabled}
        >
            {!!to?
            <LocaleLink to={to}>
                {value}
            </LocaleLink>
            :
            <>
                {!!icon&&iconPos==="left"&&icon}
                {value}
                {!!icon&&iconPos==="right"&&icon}
            </>
            }
        </button>
    )
};

export const FavoriteButton:FunctionComponent = ()=>{
    const intl = useIntl();
    return(
        <button className="btn-favorite" type="button" data-gloss={intl.formatMessage({id:"btn-add-favorite", defaultMessage: "Ajouter aux favoris"})}>
            <FontAwesomeIcon icon={faHeart} size="xs"/>
        </button>
    )
};

export const FormButton:FunctionComponent<FormButtonProps> = ({value,disabled,onSubmit})=>{
    return(
        <div className="form-button">
            <button type="submit" className="primary btn squared" disabled={disabled} onClick={onSubmit}>{value}</button>
        </div>
    )
};

