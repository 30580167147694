import React, { FunctionComponent, ReactNode } from "react";
import { ButtonBox, TitleBox } from "./common";
import "./section.scss";

type SectionProps = {
    children:ReactNode,
    className?:string | undefined,
    title?:string,
    subtitle?:string,
    btnValue?:string,
    btnLink?:string,
    btnPosition?:"start"|"center"|"end",
    background?:string,
    titlePosition?:string
}

const Section:FunctionComponent<SectionProps> = ({
    children,
    className = "",
    title = null,
    subtitle = "",
    btnValue = null,
    btnLink = "/",
    btnPosition = "center",
    background = "#FFF",
    titlePosition = "center"
})=>{
    let classes = !className ? "section" : `section ${className}`;

    return(
        <section className={classes} style={{backgroundColor:background}}>
            {!!title&&<TitleBox title={title} subtitle={subtitle} position={titlePosition}></TitleBox>}
            {children}
            {!!btnValue&&<ButtonBox to={btnLink} value={btnValue} position={btnPosition}/>}
        </section>
    );
};

export default Section;
