import React from "react";
import { Helmet } from "react-helmet-async";
import { useAppConfig } from "@ct-react/core";
import { useLocaleContext, useLocaleUrl } from "@ct-react/locale";
import { allLocales } from "@shared/locales";
import { DescriptionScalar } from "app/models/description";

export type SeoHelmetProps = {
  title?: string | DescriptionScalar;
  description?: string | DescriptionScalar;
  image?: string;
  keywords?: string[];
  canonical?: string;
}

const resumeText = (text: string, maxChar: number = 160) =>
  text.length <= maxChar ? text : `${text.slice(0, maxChar - 3)}...`;


const SeoHelmet = (props: SeoHelmetProps) => {

  const { locale } = useLocaleContext();
  const { canonicalBaseUrl: baseUrl } = useAppConfig();
  const { buildLocaleHref } = useLocaleUrl(baseUrl);
  const title = typeof props.title === "string" ? props.title as string : props.title?.value;
  const description = typeof props.description === "string" ? props.title as string : (props.description?.matchRequestedLang ? props.description.value : undefined);

  return (
    <Helmet prioritizeSeoTags>
      {!!title && <title>{title}</title>}
      {!!title && <meta property="og:title" content={title} />}
      {!!description && <meta name="description" content={resumeText(description)} />}
      {!!description && <meta property="og:description" content={resumeText(description)} />}
      {!!props.image && <meta property="og:image" content={props.image} />}
      {!!props.keywords && props.keywords.length > 0 && <meta name="keywords" content={props.keywords.join(", ")} />}
      {!!props.canonical && <link rel="canonical" href={buildLocaleHref(props.canonical!)} />}
      {!!props.canonical && allLocales
        .filter(l => l.code !== locale.code)
        .map((l, i) => <link key={i}
                             rel="alternate"
                             hrefLang={l.code}
                             href={buildLocaleHref(props.canonical!, l)}/>)}
    </Helmet>);

}

export default SeoHelmet;
