import React, { FunctionComponent, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { classNames } from "@ct-react/core";
import {
  ANNUAL_FEATURES_FIELDS,
  IMAGE_FIELDS,
  RENT_FEATURES_FIELDS,
  SALE_FEATURES_FIELDS,
  SEASONAL_FEATURES_FIELDS,
  SEASONAL_PRICE
} from "@shared/fragments";
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { CardArticle, CardArticleSkeleton } from "./card";
import { LayoutContext } from "../contexts/layout";
import { gql, useLazyQuery } from "@apollo/client";
import { ArticleImages } from "../models/articles";
import "./carousel.scss";

type ArticlesCarouselProps = {
    props:any;
    type:"weekly"|"seasonal"|"annual"|"sale";
    isLoading:boolean
};

type DefaultCarousel = {
    children:ReactNode[] //To improve types,
    responsive:boolean,
    slidePerPage:3|4,
    arrow?:boolean,
    pagination?:boolean
};

type ArticleInfoCarouselProps = {
    isActive:boolean,
    setIsActive:React.Dispatch<React.SetStateAction<boolean>>,
    props:ArticleImages[],
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void
};

type RentArticleCarouselProps = {
    type:string
};

const ARTICLE_FOR_SALE = gql `
    ${IMAGE_FIELDS}
    ${SALE_FEATURES_FIELDS}
    query GetSalesSummaries
        {
            search(
                where:{
                    saleAccommodation:{}
                },
                first:6,
            )
            {
                edges {
                    node {
                        ... on SaleAccommodationSummary {
                            id,
                            title {value},
                            priceOnDemand,
                            objectType,
                            novelty
                            promoted
                            price {currency , amount},
                            images { ...ImageFields },
                            features { ...SaleFeaturesFields }
                        }
                    }
                }
            }
        }
`;

const WEEKLY_RENT = gql `
    ${IMAGE_FIELDS}
    ${RENT_FEATURES_FIELDS}
    query GetWeeklyRentSummaries
        {
            search(
                where:{
                    rentalAccommodation:{}
                },
                first:6,
            )
            {
                edges {
                    node {
                        ... on RentalAccommodationSummary {
                            id,
                            title {value},
                            priceOnDemand,
                            price {currency , amount},
                            images { ...ImageFields },
                            features { ...RentalFeaturesFields }
                        }
                    }
                }
            }
        }
`;

const SEASONAL_RENT = gql `
    ${IMAGE_FIELDS}
    ${SEASONAL_FEATURES_FIELDS}
    ${SEASONAL_PRICE}
    query GetSeasonalRentSummaries
        {
            search(
                where:{
                    seasonalAccommodation:{}
                },
                first:6,
            )
            {
                edges {
                    node {
                        ... on SeasonalAccommodationSummary {
                            id,
                            title {value},
                            priceOnDemand,
                            price {...SeasonalPrice},
                            images { ...ImageFields },
                            features { ...SeasonalFeaturesFields }
                        }
                    }
                }
            }
        }
`;

const ANNUAL_RENT = gql `
    ${IMAGE_FIELDS}
    ${ANNUAL_FEATURES_FIELDS}
    query GetAnnualRentSummaries
        {
            search(
                where:{
                    annualAccommodation:{}
                },
                first:6,
            )
            {
                edges {
                    node {
                        ... on AnnualAccommodationSummary {
                            id,
                            title {value},
                            priceOnDemand,
                            price {currency , amount},
                            images { ...ImageFields },
                            features { ...AnnualFeaturesFields }
                        }
                    }
                }
            }
        }
`;


export const SaleArticleCarousel:FunctionComponent = ()=>{
    const [saleContent,setSaleContent] = useState<any|null>(null)
    const [isMounted, setIsMounted] = useState<boolean>(false)

    const [getArticlesForSale,{loading:articleAreLoading,error}] = useLazyQuery(ARTICLE_FOR_SALE, {
        ssr: true,
        fetchPolicy: "cache-first", //Should we cache?
        notifyOnNetworkStatusChange: true,
        onCompleted:(data)=>{
            setSaleContent(data.search.edges)
        }
    });

    useEffect(()=>{
        setIsMounted(true);
    },[])

    useEffect(()=>{
        isMounted && getArticlesForSale();
    },[isMounted])

    if(error){//Must do 404
        console.error(error)
        return <></>
    }else{
        return (<ArticlesCarousel props={saleContent} type="sale" isLoading={articleAreLoading} />);
    }

};

export const RentArticleCarousel:FunctionComponent<RentArticleCarouselProps> = ({type})=>{
    const [rentContent,setRentContent] = useState<any|null>(null);
    const [isMounted, setIsMounted] = useState<boolean>(false)

    const query = ()=>{
        switch (type) {
        case "semaine":
            return WEEKLY_RENT;
            break;
        case "saison":
            return SEASONAL_RENT;
            break;
        case "annee":
            return ANNUAL_RENT;
            break;
        default:
            return WEEKLY_RENT;
            break;
        }
    };

  const matchType = ()=>{
    switch (type) {
      case "semaine":
        return "weekly"
        break;
      case "saison":
        return "seasonal"
        break;
      case "annee":
        return "annual"
        break;
      default:
        return "weekly"
        break;
    }
  };

    const [getArticlesForRent,{loading:articleAreLoading,error}] = useLazyQuery(query(), {
        ssr: true,
        fetchPolicy: "cache-first", //Should we cache?
        notifyOnNetworkStatusChange: true,
        onCompleted:(data)=>{
            setRentContent(data.search.edges)
        }
    });

    useEffect(()=>{
        setIsMounted(true);
    },[])

    useEffect(()=>{
        isMounted && getArticlesForRent();
    },[isMounted])

    if(error){//Must do 404
        console.error(error);
        return <></>
    }else{
        return (<ArticlesCarousel props={rentContent} type={matchType()} isLoading={articleAreLoading} />);
    }
};

export const ArticlesCarousel:FunctionComponent<ArticlesCarouselProps> = ({props,type,isLoading})=>{
    const skeletonSlide = ()=>{
        let cards = [];
        for (let i = 0; i < 3; i++) {
            cards.push(
            <SplideSlide key={i}>
                <CardArticleSkeleton/>
            </SplideSlide>
            );
        }
        return cards;
    };

    return(
        <Splide
            className="articlesCarousel"
            hasTrack={false}
            options={
                {
                    type:"loop",
                    breakpoints: {
                        992: {
                            destroy: true,
                        },
                        1050:{
                            perPage:1,
                        },
                        1450:{
                            perPage:2,
                        }
                    },
                    perPage:3,
                    perMove:1,
                    autoplay:true,
                    pauseOnHover:true,
                    speed:800,
                    interval:2500,
                    start:0,
                    gap:"32px",
                    padding:"16px",
                    pagination:false,
                    rewind:true,
                    arrowPath:"M39.56,18.94L27.4,6.78c-0.58-0.58-1.53-0.58-2.12,0c-0.58,0.58-0.58,1.53,0,2.12l9.61,9.61H1.5C0.67,18.5,0,19.17,0,20 s0.67,1.5,1.5,1.5h33.39l-9.61,9.61c-0.58,0.58-0.58,1.53,0,2.2c0.29,0.29,0.68,0.44,1.06,0.44s0.77-0.15,1.06-0.44l12.16-12.16 C39.84,20.78,40,20.4,40,20C40,19.6,39.84,19.22,39.56,18.94z",
                    classes: {
                        arrows: 'splide__arrows carousel-arrowsBlock',
                        arrow : 'splide__arrow carousel-arrow',
                        prev  : 'splide__arrow--prev carousel-arrowPrev',
                        next  : 'splide__arrow--next carousel-arrowNext',
                        pagination:"splide__pagination carousel-pagination",
                    }
            }}
        >
            <SplideTrack>
                {!isLoading && !!props ?
                props.map((i:any)=>{
                    return(
                        <SplideSlide key={i.node.id}>
                            <CardArticle key={i.node.id} props={i.node} type={type}/>
                        </SplideSlide>
                    )
                })
                :
                skeletonSlide()
                }
            </SplideTrack>
        </Splide>
    )
};

export const Carousel:FunctionComponent<DefaultCarousel>=({children,responsive=true,slidePerPage=3,arrow=true,pagination=true})=>{

    return(
        <Splide
        hasTrack={false}
        className={responsive?"carouselResponsive":"carousel"}
        options={{
        type:"loop",
        mediaQuery: 'min',
        autoplay:true,
        pauseOnHover:true,
        speed:800,
        interval:3500,
        start:0,
        breakpoints: {
              992: {
                  destroy: !responsive,
                  perPage:2,
              },
              1450: {
                perPage:3
              },
              1700: {
                perPage:slidePerPage,
              }
        },
        perPage:1,
        perMove:1,
        arrows:arrow,
        pagination:pagination,
        arrowPath:"M39.56,18.94L27.4,6.78c-0.58-0.58-1.53-0.58-2.12,0c-0.58,0.58-0.58,1.53,0,2.12l9.61,9.61H1.5C0.67,18.5,0,19.17,0,20 s0.67,1.5,1.5,1.5h33.39l-9.61,9.61c-0.58,0.58-0.58,1.53,0,2.2c0.29,0.29,0.68,0.44,1.06,0.44s0.77-0.15,1.06-0.44l12.16-12.16 C39.84,20.78,40,20.4,40,20C40,19.6,39.84,19.22,39.56,18.94z",
        classes: {
            arrows: 'splide__arrows carousel-arrowsBlock',
            arrow : 'splide__arrow carousel-arrow',
            prev  : 'splide__arrow--prev carousel-arrowPrev',
            next  : 'splide__arrow--next carousel-arrowNext',
            pagination:"splide__pagination carousel-pagination",
        },
        }}>
            <SplideTrack>
                {children!=undefined&&
                children.map((node=>{
                    return(
                        <SplideSlide key={uuidv4()}>
                            {node}
                        </SplideSlide>
                    )
                }))
                }
            </SplideTrack>
        </Splide>
    )
};

export const ArticleInfoCarousel:FunctionComponent<ArticleInfoCarouselProps> = ({isActive,setIsActive,props, onClick})=>{
    const layout = useContext(LayoutContext);
    const [currentImgNumber, setCurrentImgNumber] = useState<number>(1);
    const images = [...props];

    const carousel:React.MutableRefObject<Splide|null> = useRef(null);

    const handleImageChange = ()=>{
        if ( carousel.current ) {
            const nbrOfImg = carousel.current.splide?.index;
            nbrOfImg!=undefined&&setCurrentImgNumber(nbrOfImg+1);
        }
    };

    const handleShowCarousel = ()=>{
        setIsActive(false);
    };

    return(
        <div
            className={classNames("article-image-carousel-block",{show:isActive})}
            onClick={onClick}
        >
            <Splide
                className="article-image-carousel"
                onMove={handleImageChange}
                ref={carousel}
                options={{
                    arrows:false,
                    pagination:false,
                    mediaQuery: 'min',
                    start:1,
                    perPage:1,
                    perMove:1,
                    height:"250px",
                    cover:true,
                    breakpoints:{
                        992:{
                            cover:false,
                            height:"40vw",
                            arrows:true,
                            keyboard:true
                        }
                    }
                }}
            >
                {images.map((img:any)=>{
                    return(
                        <SplideSlide key={img.original}>
                            <img src={layout.includes("smartphone")?img.medium:img.large}/>
                        </SplideSlide>
                    )
                })}
            </Splide>
            <FontAwesomeIcon className="article-image-carousel-close" size="2x" icon={faXmark} onClick={handleShowCarousel}/>
            <div className="article-image-carousel-current">
                {`${currentImgNumber} / ${images.length}`}
            </div>
        </div>
    );
};


