import React, { FunctionComponent, useEffect } from "react";
import { useNavigate } from "@ct-react/locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { PrimaryButton } from "../../components/button";
import { classNames } from "@ct-react/core";
import "./form-callback.scss";
import { commonDefs } from "../../i18n";

type FormCallbackProps = {
    succeeded: boolean,
    setState:()=>void
 };

export const FormCallback:FunctionComponent<FormCallbackProps> = ({succeeded,setState}) =>{
    const intl = useIntl();
    const navigate = useNavigate();

    const handleOnClick = ()=>{
        navigate(succeeded ? "/" : "/contact");
    };

    const closeWindow = ()=>{
        document.body.style.overflow = "initial";
        setState();
    };

    useEffect(()=>{
        document.body.style.overflow = "hidden";
    },[])

    return(
        <div className="form-result">
            <div className={classNames({succeeded : succeeded})}>
                <FontAwesomeIcon icon={succeeded? faCheck : faXmark}/>
            </div>
            <h3>
                {succeeded?
                            intl.formatMessage({id:"form-callback-email-received",defaultMessage : "Nous avons bien reçu votre e-mail !"})
                            :
                            intl.formatMessage({id:"form-callback-email-send-failed",defaultMessage : "Echec de l'envoie"})
                }
            </h3>
            <p>
                {succeeded?
                            intl.formatMessage({id:"form-callback-will-reply-asap", defaultMessage : "Nous vous répondrons dés que possible"})
                            :
                            intl.formatMessage({id:"form-callback-issue-occured", defaultMessage : "Un problème est survenue"})
                }
            </p>
            <PrimaryButton  setState={handleOnClick}
                            value={succeeded? intl.formatMessage({id:"btn-back-home",defaultMessage : "Retour à l'accueil"}) : intl.formatMessage({id:"btn-retry",defaultMessage : "Réssayer"}) }
            />
            <FontAwesomeIcon onClick={closeWindow} icon={faXmark} className="close-form-callback"/>
        </div>
    );
};
export const BookingBoxFormCallback:FunctionComponent<FormCallbackProps> = ({succeeded,setState}) =>{
    const intl = useIntl();
    const navigate = useNavigate();

    useEffect(()=>{
        document.body.style.overflow = "hidden";
    },[])

    const handleOnClick = ()=>{
        document.body.style.overflow = "initial";
        navigate(succeeded ? "/checkout/panier" : "/contact");
    };

    const closeWindow = ()=>{
        document.body.style.overflow = "initial";
        setState();
    };

    return(
        <div className="form-result">
            <div className={classNames({succeeded : succeeded})}>
                <FontAwesomeIcon icon={succeeded? faCheck : faXmark}/>
            </div>
            <h3>
                {succeeded?
                            intl.formatMessage({id:"form-callback-article-add-succeded",defaultMessage : "Le bien a bien été ajouté à votre panier !"})
                            :
                            intl.formatMessage({id:"form-callback-article-add-failed",defaultMessage : "Le bien n'a pas pu être ajouté au panier"})
                }
            </h3>
                {succeeded?
                <div className="form-result-buttons">
                    <PrimaryButton  setState={closeWindow}
                                    value={intl.formatMessage({id:"form-callback-article-continue",defaultMessage : "Continuer mes achats"})}
                    />
                    <PrimaryButton  setState={handleOnClick}
                                    value={intl.formatMessage({id:"form-callback-article-goToCart",defaultMessage : "Aller au panier"})}
                    />
                </div>
                :
                <div className="form-result-buttons">
                    <PrimaryButton  setState={handleOnClick}
                                    value={intl.formatMessage(commonDefs.contact)}
                    />

                </div>
                }
            <FontAwesomeIcon onClick={closeWindow} icon={faXmark} className="close-form-callback"/>
        </div>
    );
};

