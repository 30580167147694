import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { faAddressCard, faCartShopping, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@ct-react/core";
import { LocaleLink, useNavigate } from "@ct-react/locale";
import { useLocation } from "react-router";
import "./checkout-stepper.scss";

const CheckOutStepper:FunctionComponent = ()=>{
    const intl = useIntl();
    const location = useLocation();
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState<number>(0);

    useEffect(()=>{
        setActiveIndex(index(location.pathname))
    },[location])

    const index = (path:string)=>{
        if(path.includes("checkout/panier")) return 0 ;
        if(path.includes("checkout/connexion")) return 1;
        if(path.includes("checkout/information")) return 1;
        if(path.includes("checkout/paiement")) return 2;
        return 0
    }

    return(
        <div className="stepper">
            <div className="step">
                <LocaleLink to="/checkout/panier">
                    <div className={classNames("stepper-icone",{isActive : activeIndex>=0})}>
                        <FontAwesomeIcon icon={faCartShopping}/>
                    </div>
                    <div className="stepper-info">
                        <h5>{intl.formatMessage({id:"checkout-stepper-cart", defaultMessage:"Panier"})}</h5>
                    </div>
                </LocaleLink>
            </div>
            <div className={classNames("connector",{isActive : activeIndex>=1})}></div>
            <div className="step">
                <div onClick={()=>navigate("/checkout/information")} className={classNames({"disabled-link" : activeIndex===0})}>
                    <div className={classNames("stepper-icone",{isActive : activeIndex>=1})}>
                        <FontAwesomeIcon icon={faAddressCard}/>
                    </div>
                    <div className="stepper-info">
                        <h5>{intl.formatMessage({ id:"checkout-stepper-information", defaultMessage:"Vos informations"})}</h5>
                    </div>
                </div>
            </div>
            <div className={classNames("connector",{isActive : activeIndex>=2})}></div>
            <div className="step">
                <LocaleLink to="checkout/paiement" className={classNames({"disabled-link" : activeIndex<2})}>
                    <div className={classNames("stepper-icone",{isActive : activeIndex>=2})}>
                        <FontAwesomeIcon icon={faWallet}/>
                    </div>
                    <div className="stepper-info">
                        <h5>{intl.formatMessage({ id:"checkout-stepper-payment", defaultMessage:"Paiement"})}</h5>
                    </div>
                </LocaleLink>
            </div>
        </div>
    );
};

export default CheckOutStepper;
