import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState, } from "react";
import { useQuery } from "@apollo/client";
import { useVisitor } from "@ct-react/visitor";
import { FULL_CART_GQL_DATA } from "@shared/fragments";
import { CartRental } from "../../models/cart";
import CheckOutStepper from "../../components/cart/checkout-stepper";
import { Outlet, useOutletContext } from "react-router-dom";
import CartForm from "../../models/cartForm";
import "./cart.scss";

type CheckoutData = {
    items: CartRental | null,
    sessionId:string,
    userId:string,
    customer : CartForm | null,
    loading:boolean
};

const initialCartData:CheckoutData = {
    items : null,
    sessionId : "",
    userId : "",
    customer : null,
    loading : true
}

const Checkout:FunctionComponent = ()=>{
    const { sessionId, userId } = useVisitor();
    const [cartData, setCartData] = useState<CheckoutData>({...initialCartData, sessionId : sessionId, userId : userId} as CheckoutData);

    const { data, loading } = useQuery<{cart:CartRental}>(FULL_CART_GQL_DATA, {
        variables: { sessionId, userId },
        fetchPolicy: "no-cache",
    });


    useEffect(()=>{
        !!data&& setCartData({...cartData, items: data.cart, loading : loading});
    },[data, loading, sessionId, userId])

    return (
        <div className="cart">
            <CheckOutStepper/>
            <Outlet context={[cartData,setCartData]}/>
        </div>
    );
};

export function useCartData() {
    return useOutletContext<[ CheckoutData, Dispatch<SetStateAction<CheckoutData>> ]>();
}

export default Checkout;
