import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { RentalAccomodationCartItem } from "../../models/cart";
import { PrimaryButton } from "../button";
import "./cart-item.scss";
import Skeleton from "react-loading-skeleton";

type CartItemProps = {
    item:RentalAccomodationCartItem,
    onRemove : (id:string)=>void,
};

const CartItem:FunctionComponent<CartItemProps> = ({item,onRemove})=>{
    const intl = useIntl();

    const diff = moment(item.checkOut).diff(moment(item.checkIn), 'days');
    // const night = diff?intl.formatMessage({ id:"nights", defaultMessage:"nuits"}):intl.formatMessage({ id:"night", defaultMessage:"nuit"});
    const nights = intl.formatMessage({
        id:"nights", defaultMessage: `{days, plural, =1 {# nuit} other {# nuits}}`},
        {
            days:diff ,
        }
        )
    return(
        <article key={item.id} className="cart-item">
            <div className="item-title">
                <h3>{item.title}</h3>
            </div>
            <div className="item-img">
                <img src={item.image.original}></img>
            </div>
            <div className="item-price">
                <h5>{intl.formatMessage({id:"price-detail", defaultMessage : "Détails du prix"})}</h5>
                <div>
                    <p>{intl.formatMessage({id:"cart-item-price-amount", defaultMessage : "Prix unitaire"})}</p>
                    <span>
                    {
                        !!item.price.original?
                        intl.formatNumber(item.price.original, {style: 'currency', currency: item.price.currency})
                        :
                        intl.formatNumber(item.price.amount - (item.price.options || 0), {style: 'currency', currency: item.price.currency})
                    }
                    </span>
                </div>
                {
                    !!item.price.optionDetails &&
                    item.price.optionDetails.map((option:any)=>{
                        if(option.optionType === "TOURIST_TAX"){
                            return (
                                <div key={option.optionType}>
                                    <p>{intl.formatMessage({id:"tourist-tax", defaultMessage : "Taxe de séjour"})}</p>
                                    <span>
                                        {intl.formatNumber(option.amount, {style: 'currency', currency: item.price.currency})}
                                    </span>
                                </div>
                            );
                        }else if(option.optionType === "BENEFIT"){
                            return (
                                <div key={option.optionType}>
                                    <p>{intl.formatMessage({id:"prestation", defaultMessage : "Prestations supplémentaires"})}</p>
                                    <span>
                                        {intl.formatNumber(option.amount, {style: 'currency', currency: item.price.currency})}
                                    </span>
                                </div>
                            );
                        }else if(option.optionType === "CUSTOMER_CHARGE"){
                            return (
                                <div key={option.optionType}>
                                    <p>{intl.formatMessage({id:"customer-charge", defaultMessage : "Frais de service"})}</p>
                                    <span>
                                        {intl.formatNumber(option.amount, {style: 'currency', currency: item.price.currency})}
                                    </span>
                                </div>
                            );
                        }
                    })
                }
                {!!item.price.discount&&(
                <>
                    <div>
                        <p>{intl.formatMessage({ id:"reduction", defaultMessage: "Réduction" })}</p>
                        <span className="discount">{item.price.currency} -{item.price.discount}</span>
                    </div>
                </>
                )}
                <div className="total">
                    <p>{intl.formatMessage({ id:"total", defaultMessage : "Total"})}</p>
                    <span>
                        {intl.formatNumber(item.price.amount, {style: 'currency', currency: item.price.currency})}
                    </span>
                </div>
                {
                    item.price.amount != item.price.downPayment && (
                    <div className="cart-price-account">
                        <p>{intl.formatMessage({ id:"cart-item-price-immediat-account", defaultMessage : "À payer maintenant"})}</p>
                        <span>
                            {intl.formatNumber(item.price.downPayment, {style: 'currency', currency: item.price.currency})}
                        </span>
                    </div>
                    )
                }
            </div>
            <div className="item-date">
                <p>
                    <FontAwesomeIcon icon={faCalendar}/>
                    <span><strong>{moment(item.checkIn).format("D MMM")} - {moment(item.checkOut).format("D MMM")}</strong></span>
                    <span>{nights}</span>
                </p>
                {/* <p>
                    <FontAwesomeIcon icon={faPeopleGroup}/>
                    <span><strong>{intl.formatMessage({ id:"cart-item-date-nbrPerson", defaultMessage: "{nbrPerson} {person}" }, { nbrPerson: 2, person: 2>1? "personnes" : "personnes"   })}</strong></span>
                </p> */}
                <PrimaryButton setState={()=>onRemove(item.id)} small={true} value={intl.formatMessage({ id:"btn-delete", defaultMessage : "Supprimer"})}/>
            </div>
        </article>
    );
};

export const Skeletonitem:FunctionComponent = ()=>{
    const intl = useIntl();

    return(
        <article className="cart-item skeleton">
            <div className="item-title">
            <Skeleton count={1} width={"50%"} height={"2rem"}/>
            </div>
            <Skeleton className="item-img-skeleton"/>
            <div className="item-price">
                <Skeleton count={3} className="skeleton-card-title"/>
            </div>
            <div className="item-date">
                <p>
                    <Skeleton count={1} width={"5rem"}/>
                    <Skeleton count={1} width={"10rem"}/>
                </p>
                <Skeleton className="skeleton-card-btn"/>
            </div>
        </article>
    );
};

export default CartItem;
