import React, { FunctionComponent, useEffect, useMemo, useReducer, useState } from "react";
import { useIntl } from "react-intl";
import * as isoCountries from "i18n-iso-countries";
import { useLocaleContext, useNavigate } from "@ct-react/locale";
import CartFormReducer from "./cartForm.reducer";
import CartForm from "../../models/cartForm";
import { PrimaryButton } from "../../components/button";
import { useCartData } from "./cart";
import "./cart-information.scss";

const initialFormData:CartForm = {
    name: "",
    nameValid: false,
    surname: "",
    surnameValid: false,
    email: "",
    emailValid: false,
    tel: "",
    telValid: false,
    street: "",
    streetValid: false,
    streetNbr: "",
    complementaryAdress: "",
    npa: "",
    npaValid: false,
    city: "",
    cityValid: false,
    country: "",
    countryValid: ""
};

type FormEvent = React.FormEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>;

type Action={
    type:string;
    value:any;
};

const CartInformation:FunctionComponent = ()=>{
    const intl = useIntl();
    const { locale } = useLocaleContext();
    const [cartData, setCardData] = useCartData();
    const [formData, dispatchFormData] = useReducer<(formData: CartForm, action: Action) => any>(CartFormReducer, initialFormData);
    const navigate = useNavigate();
    const [isDisabled,setIsDisabled] = useState<boolean>(true);

    const handleFormData = (e:FormEvent)=>{
        dispatchFormData({
            type: e.currentTarget.name,
            value: e.currentTarget.value,
          });
        if(Object.values(formData).includes(false)){
            setIsDisabled(true);
        }else{
            setIsDisabled(false);
        }
    };

    //unable or disable button
    useEffect(()=>{
        if(Object.values(formData).includes(false)){
            setIsDisabled(true);
        }else{
            setIsDisabled(false);
        }
        setCardData( { ...cartData, customer: formData })
    },[formData]);

    // retrieve customer info if exists
    useEffect(()=>{
        !!cartData.customer && dispatchFormData({
            type:"copy",
            value:cartData.customer
        });
    },[]);

    const countries = useMemo(() => Object.fromEntries(Object
        .entries(isoCountries.getNames(locale.basename, { select: "official" }))
        .sort((a, b) => a[1].localeCompare(b[1]))),
    [ locale ]);

    return(
        <form className="connexion-form">
            <div>
                <div className="input-block name">
                    <label htmlFor="name">{intl.formatMessage({ id:"form-label-name", defaultMessage: "Nom"})}</label>
                    <input
                        id="name"
                        type="text"
                        name="name"
                        placeholder={intl.formatMessage({id:"form-placeholder-name", defaultMessage: "Votre nom"})}
                        required
                        onInput={handleFormData}
                        value={formData.name}
                    />
                    <span>{formData.name===""||!formData.nameValid&& intl.formatMessage({id:"form-span-field-not-valid",  defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="input-block surname">
                    <label htmlFor="surname">{intl.formatMessage({id:"form-label-surname", defaultMessage: "Prénom"})}</label>
                    <input
                        id="surname"
                        type="text"
                        name="surname"
                        value={formData.surname}
                        placeholder={intl.formatMessage({id:"form-placeholder-surname", defaultMessage: "Votre prénom"})}
                        required
                        onInput={handleFormData}
                    />
                    <span>{formData.surname===""||!formData.surnameValid&& intl.formatMessage({id:"form-span-field-not-valid",  defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="input-block email">
                    <label htmlFor="email">{intl.formatMessage({id:"form-label-email", defaultMessage: "Email"})}</label>
                    <input
                        id="email"
                        type="email"
                        name="email"
                        value={formData.email}
                        placeholder={intl.formatMessage({id:"form-placeholder-email", defaultMessage: "Votre email"})}
                        required
                        onInput={handleFormData}
                    />
                    <span>{formData.email===""||!formData.emailValid&& intl.formatMessage({id:"form-span-field-not-valid",  defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="input-block tel">
                    <label htmlFor="tel">{intl.formatMessage({id:"phone", defaultMessage: "Téléphone"})}</label>
                    <input
                        id="tel"
                        type="tel"
                        name="tel"
                        value={formData.tel}
                        placeholder={intl.formatMessage({id:"form-placeholder-phone", defaultMessage: "Votre numéro de téléphone"})}
                        onInput={handleFormData}
                    />
                    <span>{formData.tel===""||!formData.telValid&& intl.formatMessage({id:"form-span-field-not-valid",  defaultMessage: "*Champ non valide"})}</span>
                </div>
            </div>
            <div>
                <div className="input-block street">
                        <label htmlFor="street">{intl.formatMessage({id:"form-label-street", defaultMessage: "Rue"})}</label>
                        <input
                            id="street"
                            type="text"
                            name="street"
                            value={formData.street}
                            placeholder={intl.formatMessage({id:"form-placeholder-street", defaultMessage: "Votre rue"})}
                            required
                            onInput={handleFormData}
                        />
                        <span>{formData.street===""||!formData.streetValid&& intl.formatMessage({id:"form-span-field-not-valid",  defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="input-block streetNbr">
                <label htmlFor="streetNbr">N°</label>
                    <input
                        id="streetNbr"
                        type="text"
                        name="streetNbr"
                        value={formData.streetNbr}
                        placeholder="N°"
                        onInput={handleFormData}
                    />
                </div>
                <div className="input-block complementaryAdress">
                        <label htmlFor="complementaryAdress">{intl.formatMessage({id:"form-complementary-address", defaultMessage: "Adresse complémentaire"})}</label>
                        <input
                            id="complementaryAdress"
                            type="text"
                            name="complementaryAdress"
                            value={formData.complementaryAdress}
                            placeholder={intl.formatMessage({id:"form-complementary-address", defaultMessage: "Adresse complémentaire"})}
                            onInput={handleFormData}
                        />
                </div>
                <div className="input-block npa">
                    <label htmlFor="npa">NPA</label>
                    <input
                        id="npa"
                        type="text"
                        name="npa"
                        placeholder="NPA"
                        value={formData.npa}
                        onInput={handleFormData}
                    />
                    <span>{formData.npa===""||!formData.npaValid&& intl.formatMessage({id:"form-span-field-not-valid",  defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="input-block city">
                    <label htmlFor="city">{intl.formatMessage({id:"form-locality", defaultMessage: "Localité"})}</label>
                    <input
                        id="city"
                        type="text"
                        name="city"
                        value={formData.city}
                        placeholder={intl.formatMessage({id:"form-locality",defaultMessage : "Localité"})}
                        onInput={handleFormData}
                    />
                    <span>{formData.city===""||!formData.cityValid&& intl.formatMessage({id:"form-span-field-not-valid",  defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="input-block country">
                    <label htmlFor="country">{intl.formatMessage({id:"form-label-country", defaultMessage: "Pays"})}</label>
                    <select name="country"
                            value={formData.country || ""}
                            onInput={handleFormData}
                            required
                            >
                        <option value="">{intl.formatMessage({id:"form-placeolder-country",defaultMessage : "Sélectionnez un pays"})}</option>
                        {Object.entries(countries).map(([ val, label ], i) =>
                        <option key={i} value={val}>{label}</option>
                        )}
                    </select>
                </div>
            </div>
            <PrimaryButton  color="btnWhite"
                            value={intl.formatMessage({id:"btn-change-your-reservation", defaultMessage : "Modifier votre réservation"})}
                            setState={()=>navigate("/checkout/panier")}
                            />
            <PrimaryButton  disabled={isDisabled}
                            setState={()=>navigate("/checkout/paiement",{state : formData})}
                            value={intl.formatMessage({id:"btn-define-model-payment",defaultMessage : "Définir le modèle de paiement"})}/>
        </form>
    );
};

export default CartInformation;
