import moment from "moment";
import countries from "i18n-iso-countries";
import {Locale, supportedLocales} from "@ct-react/locale";

export const allLocales = supportedLocales.filter(({ basename }) => basename !== "de");

const importMomentJsLocale = async (locale: Locale) => {
  switch (locale.code.toLowerCase()) {
    case "en-gb":
      // @ts-ignore
      await import("moment/locale/en-gb");
      return "en-gb";
    default:
      // @ts-ignore
      await import("moment/locale/fr-ch");
      return "fr-ch";
  }
}

const loadCountries = async (locale: Locale): Promise<void> => {
  if (!countries.langs().includes(locale.basename))
    countries.registerLocale(await import(`i18n-iso-countries/langs/${locale.basename}.json`));
  return new Promise(resolve => resolve(void 0));
}

export const handleMomentJsLocale = async (locale: Locale, web: boolean = false): Promise<void> =>
{
  return importMomentJsLocale(locale)
    .then(ml => moment.locale(ml))
    .then(() => web ? loadCountries(locale) : void 0);
}
