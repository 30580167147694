import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { IntlProvider } from 'react-intl';
import { Helmet } from "react-helmet-async";
import { useCookies } from "react-cookie";
import { defaultLocale, useLocaleContext } from "@ct-react/locale";
import LocaleRoutes from "./routes";
import { getIntlMessages } from "./i18n";
import { MenuProvider } from "./contexts/menu";
import { FilterModuleProvider } from "./contexts/filterModule";
import { RGPDConsentProvider, RGPDConsentProcessor, RGPDBanner } from "./bundles/client-ui-only";
import Menu from "../app/layout/menu/menu";
import Footer from "../app/layout/footer/footer";
import SearchModule, { FilterSortModule } from "./layout/searchModule/search-module";
import { ButtonToTop } from "./components/wrapper/common";
import "./styles/global.scss";

const cookieConsentOptions = { consentCookieAttributes: { expires: 180 } };

const App =( ) => {

  const { locale } = useLocaleContext();
  const location = useLocation();
  const [ cookieBannerVisible, setCookieBannerVisible ] = useState<boolean>(true);
  const [ cookies ] = useCookies();

  useEffect(() => { //Handle cookie banner
    if (cookies.USE_COOKIE_CONSENT_STATE === undefined) return;
    if (cookies.USE_COOKIE_CONSENT_STATE.firstParty) setCookieBannerVisible(false);
    return;
  }, []);

  useEffect(() => window.scrollTo(0,0), [ location ]);

  return(
    <IntlProvider locale={locale.code}
                  defaultLocale={defaultLocale.code}
                  messages={getIntlMessages(locale)}
                  onError={() => void 0}>
      <RGPDConsentProvider useCookieConsentHooksOptions={cookieConsentOptions}>
        <Helmet>
          <html lang={locale.code} />
          <meta property="og:locale" content={locale.code} />
          <meta property="og:type" content="website" />
        </Helmet>
        <RGPDConsentProcessor />
        <header className="mainHeader">
          <MenuProvider state={false}>
            <Menu/>
          </MenuProvider>
        </header>
        <main>
          <FilterModuleProvider state={false}>
            <SearchModule />
            <FilterSortModule/>
            <LocaleRoutes/>
          </FilterModuleProvider>
        </main>
        {cookieBannerVisible &&
          <RGPDBanner closeModal={setCookieBannerVisible}/>
        }
        <ButtonToTop/>
        <Footer callToAction={true}></Footer>
      </RGPDConsentProvider>
    </IntlProvider>);

};

export default App;
