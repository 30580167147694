import React, { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { classNames } from "@ct-react/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faChevronLeft, faDog, faEllipsis, faMagnifyingGlass, faMap, faSquareParking, faWifi } from "@fortawesome/free-solid-svg-icons";
import { MapAsyncPopupMarker, MapContainer } from "../../bundles/map-implement";
import { Marker } from "../../models/articles";
import { PrimaryButton } from "../../components/button";
import { CardArticle, CardArticleSkeleton } from "../../components/card";
import gsap from "gsap"
import { useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { DishWasher, FilterSort, FirePlace, Tv } from "../../components/wrapper/icones";
import { FilterModuleContext } from "../../contexts/filterModule";
import BREAKPOINTS from "../../utils/breakpoints";
import { commonDefs } from "../../i18n";
import SeoHelmet from "../../components/seo-helmet";
import "./articles.scss";

import image from "../../assets/agence-facade.jpg";
import { log } from "console";
import Skeleton from "react-loading-skeleton";

type ArticlesContainerProps = {
    props:any,
    type:"weekly"|"seasonal"|"annual"|"sale",
    isLoading:boolean,
    allMarkers: Marker[],
    additionalFilter?:string[]
    setAdditionalFilter?: (_:string[]) => void,
    nameCriteria?: string | undefined
    setNameCriteria?: (_:string | undefined) => void
};

type ArticlesPaginationProps = {
    totalCount:number,
    pageInfo:any,
};

export const ArticlesContainer:FunctionComponent<ArticlesContainerProps> = ({
    props,
    type,
    isLoading,
    allMarkers,
    additionalFilter,
    setAdditionalFilter,
    nameCriteria,
    setNameCriteria
})=>{
    const intl = useIntl();
    const {setIsVisible, searchData, dispatchSearchData} = useContext(FilterModuleContext);
    const [showMap, setShowMap] = useState<boolean>(false);
    const [name, setName] = useState<string | undefined>(undefined);
    const [debouncedQuery, setDebouncedQuery] = useState<boolean>(false);
    const articles = !!props ? props.map((edge: any) => edge) : null;

    const handleShowMap = () => {
        setShowMap(!showMap)
    };

    const handleFilter = (e) => {
        const newFilter = e.target.name;
        if(e.currentTarget.checked){//add
            dispatchSearchData({
                type: "filters",
                value: [...searchData.filters, newFilter]
            })
            setAdditionalFilter!([...additionalFilter!, newFilter]);
        }else{//remove
            const newArray = searchData.filters!.filter((e:string) => e != newFilter )
            dispatchSearchData({
                type: "filters",
                value: newArray
            })
            setAdditionalFilter!(newArray);
        }
    };

    const handleFilterName = (e) => {
        const criteria = e.target.value;
        setName(criteria);
        if(!debouncedQuery){
            setDebouncedQuery(true);
            setTimeout(() => {
                setDebouncedQuery(false)
            }, 1000);
        }
    };

    //Debounce filter by name
    useEffect(() => {
        if(!debouncedQuery){
            dispatchSearchData({
                type: "name",
                value: name
            });
            setNameCriteria!(name); 
        }
    },[debouncedQuery]);

    const renderFilterBar = useMemo(() => {
        
    },[name]);

    const container = useRef(null);

    useEffect(() => {
        if(!!container){

            showMap&&gsap.to(
                container.current,
                {
                    gridTemplateColumns:"55% 45%",
                    duration:1,
                    ease:"power1"
                }
            );

            !showMap&&gsap.to(
                container.current,
                {
                    gridTemplateColumns:"100% 40%",
                    duration:1,
                    ease:"power1"
                }
            );
        }
    },[showMap]);

    const mapRef = useRef(null);

    const typeOfLocation = ()=>{
        switch (type) {
            case "weekly":
                return intl.formatMessage({ id:"articles-weekly", defaultMessage: " à la semaine"});
                break;
            case "seasonal":
                return intl.formatMessage({ id:"articles-seasonly", defaultMessage: " à la saison"});
                break;
            case "annual":
                return intl.formatMessage({ id:"articles-annualy", defaultMessage: " à l'année"});
                break;
            default:
                return ""
                break;
        }
    };

    const skeletonCard = ()=>{
        let cards:any = []
        for (let i = 0; i < 8; i++) {
             cards.push(<CardArticleSkeleton key={i}/>)
        }
        return cards;
    };

    const seoData = () => {
        switch (type) {
            case "weekly":
                return {
                    title: intl.formatMessage({ id: "seo-title-weekly-location", defaultMessage: "Maytain | Locations de vacances, appartement, chalet, etc."}),
                    description: intl.formatMessage({ id: "seo-description-weekly-location", defaultMessage: "Découvrez nos chalets et appartements à louer pour des vacances inoubliables à Morgins, Valais, Suisse. Profitez du confort au cœur des montagnes."}),
                    canonical: "/louer/semaine",
                }
                break;
            case "seasonal":
                return {
                    title: intl.formatMessage({ id: "seo-title-seasonal-location", defaultMessage: "Maytain | Locations de saison, appartement ou chalet à Morgins."}),
                    description: intl.formatMessage({ id: "seo-description-seasonal-location", defaultMessage: "Explorez nos offres de locations saisonnières à Morgins. Tout type d'habitation pour votre séjour à la montagne."}),
                    canonical: "/louer/saison",
                }
                break;
            case "annual":
                return {
                    title: intl.formatMessage({ id: "seo-title-annual-location", defaultMessage: "Maytain | Locations de vacances, appartement, chalet, etc."}),
                    description: intl.formatMessage({ id: "seo-description-annual-location", defaultMessage: "Vivez à Morgins, avec nos maisons et appartements disponibles à la location annuelle. Profitez du confort et d'un cadre exceptionnel pour votre quotidien."}),
                    canonical: "/louer/annee",
                }
                break;
            case "sale":
                return {
                    title: intl.formatMessage({ id: "seo-title-sale-location", defaultMessage: "Maytain | Résidence de montagne à vendre, maison, bâtiment, etc. "}),
                    description: intl.formatMessage({ id: "seo-description-sale-location", defaultMessage: "Investissez dans une résidence unique. Découvrez nos maisons et propriétés à vendre, idéales pour un cadre de vie moderne en pleine nature montagnarde."}),
                    canonical: "/acheter",
                }
                break;
            default:
                break;
        }
    };

    return(
        <>
            <SeoHelmet
                {...seoData()}
                image={image}
            />
            <div className={classNames("articles-header",{empty:!isLoading && articles===null})} style={!!articles?{}:{backgroundColor:"var(--light-grey)"}}>
                {isLoading && 
                <div>
                    <Skeleton width={"50%"} height={"100%"}/>
                    <div className="filters">
                        <Skeleton width={"16px"} height={"16px"}/>
                        <Skeleton width={"16px"} height={"16px"}/>
                        <Skeleton width={"16px"} height={"16px"}/>
                        <Skeleton width={"16px"} height={"16px"}/>
                    </div>
                </div>
                }
                {!isLoading && !!articles &&
                    <div>
                        <h1>
                            {intl.formatMessage({ id:"articles-title", defaultMessage: "Logements à {type} à Morgins"}, {
                            type : type==="sale"
                                ? intl.formatMessage({id: "sold", defaultMessage: "vendre"})
                                : intl.formatMessage(commonDefs.toRent).toLowerCase()})
                            }
                            &nbsp;
                            {typeOfLocation()}
                        </h1>
                        {type != "sale" &&
                        <div className="filters">
                            <label htmlFor="parking">
                                <input
                                    type="checkbox"
                                    value="parking"
                                    id="parking"
                                    name="parking"
                                    onChange={handleFilter}
                                    defaultChecked={additionalFilter?.includes("parking")}
                                    />
                                <FontAwesomeIcon icon={faSquareParking}/>
                            </label>
                            <label htmlFor="wifi">
                                <input
                                    type="checkbox"
                                    value="wifi"
                                    id="wifi"
                                    name="wifi"
                                    onChange={handleFilter}
                                    defaultChecked={additionalFilter?.includes("wifi")}
                                    />
                                <FontAwesomeIcon icon={faWifi}/>
                            </label>
                            <label htmlFor="tv">
                                <input
                                    type="checkbox"
                                    value="tv"
                                    id="tv"
                                    name="tv"
                                    onChange={handleFilter}
                                    defaultChecked={additionalFilter?.includes("tv")}
                                    />
                                <Tv/>
                            </label>
                            {type != "annual" &&
                            <label htmlFor="pet">
                                <input
                                    type="checkbox"
                                    value="pet"
                                    id="pet"
                                    name="pet"
                                    onChange={handleFilter}
                                    defaultChecked={additionalFilter?.includes("pet")}
                                    />
                                <FontAwesomeIcon icon={faDog}/>
                            </label>
                            }
                            <label htmlFor="fireplace">
                                <input
                                    type="checkbox"
                                    value="fireplace"
                                    id="fireplace"
                                    name="fireplace"
                                    onChange={handleFilter}
                                    defaultChecked={additionalFilter?.includes("fireplace")}
                                    />
                                <FirePlace/>
                            </label>
                            <label htmlFor="dishwasher">
                                <input
                                    type="checkbox"
                                    value="dishwasher"
                                    id="dishwasher"
                                    name="dishwasher"
                                    onChange={handleFilter}
                                    defaultChecked={additionalFilter?.includes("dishwasher")}
                                    />
                                <DishWasher/>
                            </label>
                        </div>
                        }
                    </div>
                }
                {!isLoading && articles===null &&
                    <div>
                        <h4>
                            {intl.formatMessage({id:"articles-no-accomodations-correspond", defaultMessage: "Aucun bien ne correspond aux filtres de recherche."})}
                        </h4>
                        {type != "sale" &&
                        <div className="filters">
                                    <label htmlFor="parking">
                                        <input
                                            type="checkbox"
                                            value="parking"
                                            id="parking"
                                            name="parking"
                                            onChange={handleFilter}
                                            defaultChecked={additionalFilter?.includes("parking")}
                                            />
                                        <FontAwesomeIcon icon={faSquareParking}/>
                                    </label>
                                    <label htmlFor="wifi">
                                        <input
                                            type="checkbox"
                                            value="wifi"
                                            id="wifi"
                                            name="wifi"
                                            onChange={handleFilter}
                                            defaultChecked={additionalFilter?.includes("wifi")}
                                            />
                                        <FontAwesomeIcon icon={faWifi}/>
                                    </label>
                                    <label htmlFor="tv">
                                        <input
                                            type="checkbox"
                                            value="tv"
                                            id="tv"
                                            name="tv"
                                            onChange={handleFilter}
                                            defaultChecked={additionalFilter?.includes("tv")}
                                            />
                                        <Tv/>
                                    </label>
                                    <label htmlFor="pet">
                                        <input
                                            type="checkbox"
                                            value="pet"
                                            id="pet"
                                            name="pet"
                                            onChange={handleFilter}
                                            defaultChecked={additionalFilter?.includes("pet")}
                                            />
                                        <FontAwesomeIcon icon={faDog}/>
                                    </label>
                                    <label htmlFor="fireplace">
                                        <input
                                            type="checkbox"
                                            value="fireplace"
                                            id="fireplace"
                                            name="fireplace"
                                            onChange={handleFilter}
                                            defaultChecked={additionalFilter?.includes("fireplace")}
                                            />
                                        <FirePlace/>
                                    </label>
                                    <label htmlFor="dishwasher">
                                        <input
                                            type="checkbox"
                                            value="dishwasher"
                                            id="dishwasher"
                                            name="dishwasher"
                                            onChange={handleFilter}
                                            defaultChecked={additionalFilter?.includes("dishwasher")}
                                            />
                                        <DishWasher/>
                                    </label>
                        </div>
                        }
                    </div>
                }
                <div className="filter-name">
                    <FontAwesomeIcon icon={faMagnifyingGlass}/>
                    <input type="text" value={name || ""} onChange={handleFilterName} placeholder={intl.formatMessage({id:"search-by-name-criteria", defaultMessage: "Rechercher par nom"})}/>
                </div>
                <div className="toggle-map">
                    {intl.formatMessage({id:"articles-map", defaultMessage: "Carte"})}
                    <label className="switch">
                        <input className="toggle-btn" type="checkbox" onChange={handleShowMap} checked={showMap}/>
                        <span className="slider round"></span>
                    </label>
                </div>
                {!isLoading && articles === null &&
                    <p>
                        {intl.formatMessage({id:"articles-modify-your-filters", defaultMessage: "Nous n'avons pas de bien immobilier à vous présenter dans cette catégorie. Merci de nous contacter pour toute demandes particulières."})}
                    </p>
                }
                <div className="articles-btnMap" >
                    <div onClick={handleShowMap}>
                        <FontAwesomeIcon icon={faMap}/>
                        {intl.formatMessage({id:"articles-see-map", defaultMessage: "Voir la carte"})}
                    </div>
                    <div onClick={()=>setIsVisible(true)}>
                        <FilterSort/>
                        {intl.formatMessage({id:"articles-sort-filters", defaultMessage: "Trier et filtrer"})}
                    </div>
                </div>
            </div>
            <div ref={container} className="articles-container" style={!isLoading && articles===null ? {display:"none"} : undefined}>
                <ul itemScope itemType="https://schema.org/OfferCatalog" className="articles" style={window.innerWidth>BREAKPOINTS.big_desktop? !isLoading && !!articles && articles.length>3? {gridTemplateColumns: "repeat(auto-fit, minmax(355px, auto))"} : {gridTemplateColumns : "repeat(auto-fit, minmax(355px, 32.9%))"} : undefined}>
                    {isLoading&&skeletonCard()}
                    {!isLoading&&!!articles&&articles.map((article:any, index:number)=>{
                        return(
                            <li key={index}>
                                <CardArticle key={article.node.id} index={index+1} props={article.node} bookingSuggestion={article.bookingSuggestion!? article.bookingSuggestion : null} type={type} />
                            </li>
                        )
                    })}
                </ul>
                <div className={classNames("articles-map",{showMap:showMap})}>
                    <div className="articles-map-button" onClick={handleShowMap}>
                        <PrimaryButton color="btnWhite" small={true} value={intl.formatMessage({id:"btn-list", defaultMessage: "Liste"})} icon={<FontAwesomeIcon icon={faChevronLeft} />} iconPos="left"/>
                    </div>
                    {(!isLoading && allMarkers!) &&
                    <MapContainer
                        className="overloaded-map"
                        center={[46.237680373887855,6.857830543902882]}
                        zoom={15}
                        clusterize={true}
                        ref={mapRef}
                        >
                        {allMarkers.map((marker:Marker)=><MapAsyncPopupMarker marker={marker} type={type} key={marker.id} mapRef={mapRef}/>)}
                    </MapContainer>}
                </div>
                <div className="articles-btnMap" >
                    <div onClick={handleShowMap}>
                        <FontAwesomeIcon icon={faMap}/>
                        {intl.formatMessage({id:"articles-see-map", defaultMessage: "Voir la carte"})}
                    </div>
                    <div onClick={()=>setIsVisible(true)}>
                        <FilterSort/>
                        {intl.formatMessage({id:"articles-sort-filters", defaultMessage: "Trier et filtrer"})}
                    </div>
                </div>
            </div>
        </>
    );
};

export const ArticlesPagination:FunctionComponent<ArticlesPaginationProps> = (
    {
        totalCount,
        pageInfo
    }
    )=>{
    const [searchParams, setSearchParams] = useSearchParams();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth"});

    const pagination:React.ReactNode[] = [];

    if(!!pageInfo.linkCursors){
        for (const page of pageInfo.linkCursors) {
            pagination.push(
                <div key={page.page} onClick={()=>changePage("after" , page.cursor)} className={classNames("pagination",{currentPage:page.page=== pageInfo.page})}>
                    {page.page}
                </div>
                )
        }
    }

    const changePage = (dir:string | null ,cursor:string | null)=>{
        if(dir === null) {setSearchParams()} //page 1
        if(dir === "before") {setSearchParams(`dir=before&page=${cursor}`)} //previous page
        if(dir === "after") {setSearchParams(`dir=after&page=${cursor}`)} //next page

    }

    return(
        <div className="articles-pagination">
        <div className="pagination-container">
            {/* <button disabled={!pageInfo.hasPreviousPage} className="pagination pagination-before" onClick={()=>changePage(null,null)}>
                <FontAwesomeIcon icon={faAnglesLeft}/>
            </button> */}

            {
            pageInfo.pageCount > 1 &&
                <button disabled={!pageInfo.hasPreviousPage} className="pagination pagination-before" onClick={()=>changePage("before", pageInfo.previousCursor)}>
                    <FontAwesomeIcon icon={faAngleLeft}/>
                </button>
            }

            <div onClick={()=>changePage("before","OA==")} className={classNames("pagination",{currentPage: pageInfo.page === 1})}>
            1
            </div>

            {pageInfo.page > 3 && <FontAwesomeIcon icon={faEllipsis}/>}

            {pagination}

            {(pageInfo.page <= pageInfo.pageCount - 3 && pageInfo.pageCount > 4 ) && <FontAwesomeIcon icon={faEllipsis}/>}

            {
            pageInfo.pageCount > 1 &&
                <div onClick={()=>changePage("after", pageInfo.lastCursor)} className={classNames("pagination",{currentPage: pageInfo.page === pageInfo.pageCount})}>
                    {pageInfo.pageCount}
                </div>
            }

            {
            pageInfo.pageCount > 1 &&
                <button disabled={!pageInfo.hasNextPage} className="pagination pagination-after" onClick={()=>changePage("after", pageInfo.nextCursor)}>
                    <FontAwesomeIcon icon={faAngleRight}/>
                </button>
            }

            {/* <button disabled={!pageInfo.hasNextPage} className="pagination pagination-after" onClick={()=>changePage("after", pageInfo.lastCursor)}>
                <FontAwesomeIcon icon={faAnglesRight}/>
            </button> */}
        </div>
        </div>
    )
};

