import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { useOutletContext, useSearchParams } from "react-router-dom";
import PostType from "../../models/post";
import moment from "moment";
import parse from 'html-react-parser';
import "./post.scss";

const Post:FunctionComponent = ()=>{
  const [searchParams, setSearchParams] = useSearchParams();
  const {posts, loading}= useOutletContext<any>();
  const [post, setPost] = useState<PostType | null>(null);

  useEffect(()=>{
    window.scrollTo(0,0)
    const id = searchParams.get("id")
    if(!!posts) setPost(posts.find((post:PostType)=>post.id === id))
  },[]);

  const renderPost = useMemo(()=>{

    if(post === null) return <>Loading...</>


    const image = post.data.find((d:any)=> d.type === "Image");
    const title = post.data.find((d:any)=> d.slug === "blog-title");
    const content = post.data.find((d:any)=> d.slug === "blog-content");
    const date = moment(post.data.publishedDate).format("DD MMM YY");

    return(
      <div className="post">
        <div className="post-img">
          <img src={image.originalUrl} alt=""/>
        </div>
        <div className="post-label">
          {/* <div>
            <FontAwesomeIcon icon={faTag}/> catégorie
          </div> */}
          <div>
            <FontAwesomeIcon icon={faCalendar}/> {date}
          </div>
        </div>
        <h2>{title.text[0].description}</h2>
        <div className="post-lead">
          {parse(content.html[0].description)}
        </div>
      </div>
    )
  },[post,posts])

  return (
    <>
      {renderPost}
    </>
  )
}

export default Post
