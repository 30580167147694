import moment from "moment";
import React, { FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCamera, faDog, faShower, faSquareParking, faWifi } from "@fortawesome/free-solid-svg-icons";
import { classNames } from "@ct-react/core";
import { LocaleLink, useLocaleContext } from "@ct-react/locale";
import { buildArticleUrl } from "@shared/models/urls";
import { AnnualArticleSummary, Discounts, RentArticleFeature, RentArticleSummary, SaleArticleSummary } from "../models/articles";
import Post from "../models/post";
import { ArticlePrice } from "../pages/articleInfo/articleInfo";
import { PrimaryButton } from "./button";
import { Charge, DishWasher, FirePlace, Smoker, Tv } from "./wrapper/icones";
import DiscountSvg from "../assets/svgs/discount.svg";
import "./card.scss";


type CardArticleEquipmentProps = {
    props:any
    type:"weekly"|"seasonal"|"annual"|"sale"
};

type CardArticleProps = {
    props:SaleArticleSummary|RentArticleSummary|AnnualArticleSummary,
    type:"weekly"|"seasonal"|"annual"|"sale",
    bookingSuggestion?: any | null,
    index?: number | undefined
};

type CardArticleDiscountProps = {
    discount : [Discounts]
};

type CardPostProps = {
    post:Post
}

const CardArticleEquipment:FunctionComponent<CardArticleEquipmentProps> = ({props,type})=>{
    const intl = useIntl();
    const features= {...props}

    if(type==="sale"){
        return(
            <div className="card-equipment sale">
                    {features.parkings > 0 &&
                    <>
                    <div className="card-icone">
                        <FontAwesomeIcon icon={faSquareParking}/>
                        {features.parkings} {intl.formatMessage({id:"card-article-parking-place", defaultMessage: "Places"})}
                    </div>
                    </>
                    }
                    {features.bathrooms > 0 &&
                    <>
                    <div className="card-icone">
                        <FontAwesomeIcon icon={faShower}/>
                        {features.bathrooms} {intl.formatMessage({id:"card-article-nbr-bathrooms", defaultMessage: "Salles de bains"})}
                    </div>
                    </>
                    }
                    {features.charges &&
                    <>
                    <div className="card-icone" data-gloss={intl.formatMessage({id:"charges", defaultMessage: "Charges"})}>
                            <Charge/>
                            {features.charges.currency} {features.charges.amount}
                    </div>
                    </>
                    }
            </div>
        );
    }else{
        return(
            <div className="card-equipment rent">
                <div
                    className="card-icone"
                    data-gloss={intl.formatMessage({id:"card-article-with-parking-", defaultMessage: "Parking disponible"})}
                    style={!features.withParking ? {display: "none"} : undefined}
                    >
                        <FontAwesomeIcon icon={faSquareParking}/>
                </div>
                <div
                    className="card-icone"
                    data-gloss={intl.formatMessage({id:"card-article-with-wifi", defaultMessage: "Wifi disponible"})}
                    style={!features.withWIFI ? {display: "none"} : undefined}
                >
                    <FontAwesomeIcon icon={faWifi}/>
                </div>
                <div
                    className="card-icone tv"
                    data-gloss={intl.formatMessage({id:"card-article-with-tv", defaultMessage: "Avec TV"})}
                    style={!features.withTV ? {display: "none"} : undefined}
                >
                    <Tv/>
                </div>
                <div
                    className="card-icone smoke"
                    data-gloss={intl.formatMessage({id:"card-article-smoker-ok", defaultMessage: "Fumeur"})}
                    style={!features.isSmokerCompliant ? {display: "none"} : undefined}
                >
                    <Smoker/>
                </div>
                <div
                    className="card-icone pet"
                    data-gloss={intl.formatMessage({id:"card-article-pet-allowed", defaultMessage: "Animaux admis"})}
                    style={!features.isPetCompliant ? {display: "none"} : undefined}
                >
                    <FontAwesomeIcon icon={faDog}/>
                </div>
                <div
                    className="card-icone"
                    data-gloss={intl.formatMessage({id:"card-article-with-chimney", defaultMessage: "Avec pôele à bois"})}
                    style={!features.withFireplace ? {display: "none"} : undefined}
                >
                    <FirePlace/>
                </div>
                <div
                    className="card-icone"
                    data-gloss={intl.formatMessage({id:"card-article-with-dishwasher", defaultMessage: "Avec lave-vaisselle"})}
                    style={!features.withDishwasher ? {display: "none"} : undefined}
                >
                    <DishWasher/>
                </div>
            </div>
        );
    }
};

const CardArticleChips:FunctionComponent<CardArticleProps> = ({props,type})=>{
    const intl = useIntl();
    return(
        <div className="card-chips">
            {
                (props as SaleArticleSummary).features.isSold &&
                <div className="card-chip isSold">
                    {intl.formatMessage({id:"card-article-chip-sold", defaultMessage: "Vendu"})}
                </div>
            }
            {
                props.novelty &&
                <div itemProp="itemCondition" content="https://schema.org/NewCondition" className="card-chip novelty">
                    {intl.formatMessage({id:"card-article-chip-novelty", defaultMessage: "Nouveauté"})}
                </div>
            }
            {
                props.promoted &&
                <div className="card-chip promoted">
                    {intl.formatMessage({id:"card-article-chip-promoted", defaultMessage: "En promotion"})}
                </div>
            }
        </div>
    );
};

const CardArticleDiscount:FunctionComponent<CardArticleDiscountProps> = ({discount})=>{
    const intl = useIntl();
    if(!!discount && discount.length > 0){
        return(
            <div className="card-discount">
                <DiscountSvg />
                <div>
                    {intl.formatMessage({id:"card-article-reduction-percentage", defaultMessage: "Réduction de {percentage}%"},{percentage: discount[0].percentageValue * 100})}
                    <br/>
                    {intl.formatMessage(
                        {id:"card-article-reduction-dates", defaultMessage: "À partir du {from} au {to}"},
                        {
                        from: moment(discount[0].period[0]).format("DD MMM"),
                        to:moment(discount[0].period[1]).format("DD MMM")
                        }
                    )}
                </div>
            </div>
        );
    }else{
        return <></>;
    }
};

export const CardArticle:FunctionComponent<CardArticleProps> = ({props,type,bookingSuggestion,index})=>{
    const intl = useIntl();
    const data = {...props};

    const typeForShema = (t) => {
        switch (t) {
            case "weekly":
                return "Location à la semaine"
                break;
            case "seasonal":
                return "Location à la saison"                
                break;
            case "annual":
                return "Location à l'année"                
                break;
            case "sale":
                return "Propriété à la vente"                
                break;
            default:
                break;
        }
    };

    const rooms = ()=>{
        const room = data.features.rooms;
        if(room===null){
            return "";
        }else if(room === 1){
            return intl.formatMessage({id:"card-article-studio", defaultMessage: "Studio"})+" | ";
        }else if(room > 1){
            return intl.formatMessage(
                {id:"card-article-nbrRoom", defaultMessage: "{rooms} pièces"},
                {rooms : data.features.rooms}
            )+" | ";
        }else{
            return "";
        }
    };

    const bedrooms = ()=>{
        const bedrooms = data.features.bedrooms;
        if(bedrooms===null){
            return "";
        }else if(bedrooms > 1){
            return intl.formatMessage(
                {id:"card-article-nbrBedrooms", defaultMessage: "{bedrooms ,plural, =1 { # chambre } other { # chambres }}"},
                {bedrooms : data.features.bedrooms}
            )+" | ";
        }else{
            return "";
        }
    };

    return(
        <article itemProp="itemListElement" itemScope itemType="https://schema.org/Offer" className="card">
            <meta itemProp="url" content={buildArticleUrl(data.id, type)}/>
            <meta itemProp="category" content={typeForShema(type)}/>
            <meta itemProp="image" content={data.images[0].original}/>
            <meta itemProp="position" content={index?.toString()}/>
            <CardArticleChips props={data} type={type}/>
            {type === "weekly"  && <CardArticleDiscount discount={(data as RentArticleSummary).discounts}/>}
            {/* <FavoriteButton/> */}
            <div className="card-carousel">
                { data.images.length>0 ?
                    <Splide aria-label="Images" options={{
                        type:"slide",
                        height:"14em",
                        cover:true,
                        drag:false,
                        start:0,
                        pagination:false,
                        rewind:true,
                        classes: {
                            arrows: 'splide__arrows cardImage-arrowsBlock',
                            arrow : 'splide__arrow cardImage-arrow',
                        },

                    }}>
                        {data.images.map((img:any)=>{
                            return(
                                <SplideSlide key={img.medium}>
                                    <img src={img.medium} alt={`Photo du bien ${data.title.value}`}/>
                                </SplideSlide>
                            )
                        })}
                    </Splide>
                :
                    <div className="emptyImg">
                        <FontAwesomeIcon icon={faCamera}/>
                        <p>
                            {intl.formatMessage({id:"no-image", defaultMessage: "Aucune image disponible"})}
                        </p>
                    </div>
                }
            </div>
            <div className="card-info">
                <div className="card-room">
                <meta itemProp="numberOfRooms" content={!!data.features.rooms ? data.features.rooms.toString() : undefined}/>
                <meta itemProp="floorSize" content={!!data.features.livingSpace ? data.features.livingSpace.toString() : undefined}/>
                {type === "weekly" && <meta itemProp="occupancy" content={!!(data.features as RentArticleFeature).maxGuests ? (data.features as RentArticleFeature).maxGuests.toString() : undefined}/>}
                    {rooms()} {bedrooms()} {data.features.livingSpace} m² {type === "weekly" && (` | ${(data.features as RentArticleFeature).maxGuests} pers.`)}
                </div>
                <h5 itemProp="name" className="card-title">{data.title.value}</h5>
                <CardArticleEquipment props={data.features} type={type}/>
                <div className="card-price">
                    <ArticlePrice props={data} type={type} suggestion={bookingSuggestion}/>
                    <PrimaryButton small={true}
                                   to={buildArticleUrl(data.id, type)}
                                   value={intl.formatMessage({id:"btn-see-details", defaultMessage: "Voir les détails"})}
                                   color="primary"/>
                </div>
            </div>
        </article>
    );
};

export const CardPost:FunctionComponent<CardPostProps> = ({post})=>{
    const {locale} = useLocaleContext();

    const image = post.data?.find((d:any)=> d.type === "Image");
    const title = post.data?.find((d:any)=> d.slug === "blog-title");
    const date = moment(post.data?.publishedDate).format("DD MMM YY");

    const renderTitle = useMemo(()=>{
        if(locale.basename == "fr")  return <h5>{title.text.find((t:any)=>t.languageCode == "FR").description}</h5>;
        if(title.text.find((t:any)=>t.languageCode == "EN") === undefined) return <h5>{title.text.find((t:any)=>t.languageCode == "FR").description}</h5>;
        if(locale.basename == "en") return <h5>{title.text.find((t:any)=>t.languageCode == "EN").description}</h5>;
    },[locale])

    return(
        <LocaleLink className="cardPost" to={`/blog/post?id=${post.id}`}>
            <div className="cardPost-image" style={{background:`url(${image.originalUrl})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
            </div>
            <div className="cardPost-activity">
                <span className="cardPost-Date">
                    <FontAwesomeIcon icon={faCalendar}/>
                    {date}
                </span>
                {/* <span>
                    <FontAwesomeIcon icon={faBookmark}/>
                    Catégorie
                </span> */}
            </div>
            <div className="cardPost-title">
                {renderTitle}
            </div>
        </LocaleLink>
    )
};

export const CardArticleSkeleton:FunctionComponent = ()=>{
    return(
        <div className="card">
            <Skeleton className="card-carousel"/>
            <div className="card-info">
                <Skeleton width="50%"/>
                <Skeleton count={2} className="skeleton-card-title"/>
                <Skeleton/>
                <div className="card-price">
                    <Skeleton width="8rem"/>
                    <Skeleton className="skeleton-card-btn"/>
                </div>
            </div>
        </div>    )
};

export const CardPostSkeleton:FunctionComponent = ()=>{
    return(
        <div className="cardPost skeleton">
            <Skeleton className="cardPost-image"/>
            <div className="cardPost-activity">
                <Skeleton count={1} className="skeleton-card-title"/>
            </div>
            <div className="cardPost-title">
                <Skeleton count={2} className="skeleton-card-title"/>
            </div>
        </div>
    )
};

