import React, { FunctionComponent, useContext, useMemo, useRef } from "react";
import gsap from "gsap";
import { useLocation } from "react-router-dom";
import { MenuContext } from "../../contexts/menu";
import useIsHomePage from "../../hooks/useIsHomepage";

const BurgerMenu:FunctionComponent = ()=>{
    const { isDown,setIsDown } = useContext(MenuContext);
    const location = useLocation()
    const isHomepage:boolean = useIsHomePage();

    const svg = useRef(null);

    const handleOnClick = (event:React.MouseEvent)=>{
        const el = event.currentTarget.firstElementChild
        if(!!el){
            if(isDown){
                closeMenu(el)
            }else{
                openMenu(el)
            }
        }
    }

    const openMenu = (el:Element)=>{
        setIsDown(true)
        const first = el.firstElementChild

        gsap.to(first,{attr:{y1:37.11,y2:37,x1:23,x2:58}})
        gsap.to(el.lastElementChild,{attr:{y1:37.11,y2:37,x1:23,x2:58}})
        !!first&&gsap.to(first.nextElementSibling,{autoAlpha:0})
        gsap.to(first,{attr:{y1:50.11,y2:25,x1:26,x2:55},delay:0.4})
        gsap.to(el.lastElementChild,{attr:{y1:25.11,y2:50,x1:26,x2:55},delay:0.4})
    }

    const closeMenu = (el:Element)=>{
        setIsDown(false)
        const first = el.firstElementChild
        gsap.to(first,{attr:{y1:37.11,y2:37,x1:23,x2:58}})
        gsap.to(el.lastElementChild,{attr:{y1:37.11,y2:37,x1:23,x2:58}})
        !!first&&gsap.to(first.nextElementSibling,{autoAlpha:1})
        gsap.to(first,{attr:{y1:25.11,y2:25,x1:23,x2:58},delay:0.4})
        gsap.to(el.lastElementChild,{attr:{y1:50.11,y2:50,x1:23,x2:58},delay:0.4})
    }

    const pageHasChanged =(el: React.MutableRefObject<null>)=>{
        if(!!el.current){
            const svg:Element = el.current
            const first = svg.firstElementChild
            gsap.to(first,{attr:{y1:37.11,y2:37,x1:23,x2:58}})
            gsap.to(svg.lastElementChild,{attr:{y1:37.11,y2:37,x1:23,x2:58}})
            !!first&&gsap.to(first.nextElementSibling,{autoAlpha:1})
            gsap.to(first,{attr:{y1:25.11,y2:25,x1:23,x2:58},delay:0.4})
            gsap.to(svg.lastElementChild,{attr:{y1:50.11,y2:50,x1:23,x2:58},delay:0.4})

        }else{
            return
        }
    }

    const newPage = useMemo(()=>{pageHasChanged(svg)},[location])


    return(
    <div className="burger" onClick={handleOnClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="5em" viewBox="0 0 80 80" ref={svg}>
            <line id="menuLine1" x1="23" y1="25.11" x2="58" y2="25" stroke={isHomepage?"var(--blue)":"var(--white)"} strokeLinecap="round" strokeWidth="3"/>
            <line id="menuLine2" x1="23" y1="37.11" x2="58" y2="37" stroke={isHomepage?"var(--blue)":"var(--white)"} strokeLinecap="round" strokeWidth="3"/>
            <line id="menuLine3" x1="23" y1="50.11" x2="58" y2="50" stroke={isHomepage?"var(--blue)":"var(--white)"} strokeLinecap="round" strokeWidth="3"/>
        </svg>
    </div>
    )
}

export default BurgerMenu
