import React, { FunctionComponent, useContext } from "react";
import { classNames } from "@ct-react/core";
import { LocaleLink, } from "@ct-react/locale";
import { useIntl } from "react-intl";
import MenuBanderole from "./menu-banderole";
import Navigation from "./navigation";
import { MenuContext } from "../../contexts/menu";
import { LayoutContext } from "../../contexts/layout";
import BurgerMenu from "./burgerMenu";
import Language from "./language";
import { BasketIcone, ProfilIcone } from "./navigation-icone";
import useIsHomePage from "../../hooks/useIsHomepage";
import "./navigation.scss";

const Menu:FunctionComponent = ()=>{
    const intl = useIntl();
    const layout = useContext(LayoutContext)
    const { isDown } = useContext(MenuContext);
    const isHomepage:boolean = useIsHomePage();

    return(
            <>
            <MenuBanderole/>
            <div className={classNames("menu",{white:isHomepage, primary:!isHomepage})}>
                <nav>
                    <div className={`navigation-logo ${layout.includes("smartphone")&& isDown? "hide" : "show"}`}>
                        <LocaleLink to="/" aria-label={intl.formatMessage({id:"btn-back-home", defaultMessage:"Retour à l'accueil"})}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15em" viewBox="0 0 246.094 64.542">
                                    <g className={classNames({secondaryText:isHomepage , whiteText:!isHomepage })}>
                                        <path d="M17.081,43.053H.549V41.1L2.2,40.979c2.135-.183,2.44-.243,2.44-2.44V21.274L0,16.882v-.366l13.666-5.735v7.809c1.341-4.454,4.392-7.32,9.272-7.32,4.149,0,7.381,1.829,8.358,7.5,1.463-4.758,4.818-7.5,9.578-7.5,4.941,0,8.661,2.623,8.661,11.407V38.538c0,2.2.305,2.257,2.44,2.44l1.648.122v1.952H37.091V41.1l1.16-.122c2.135-.243,2.256-.243,2.256-2.44V24.386c0-6.344-1.707-7.748-4.147-7.748S31.6,18.1,31.6,24.446V38.538c0,2.2.121,2.2,2.256,2.44l1.16.122v1.952H19.155V41.1l1.16-.122c2.135-.243,2.257-.243,2.257-2.44V24.386c0-6.344-1.708-7.748-4.149-7.748s-4.758,1.465-4.758,7.809V38.538c0,2.2.121,2.2,2.257,2.44l1.158.122Z" transform="translate(0 5.933)"/>
                                        <path d="M37.252,35.237c0-5.795,6.772-7.686,11.59-9.455,6.345-2.319,7.015-4.394,7.015-7.5a4.769,4.769,0,0,0-4.941-5.065c-4.575,0-6.466,3.539-5.124,8.908a18.783,18.783,0,0,1-4.088.487c-3.294,0-4.637-1.829-4.637-3.355,0-2.561,3.783-4.635,7.748-6.161a26.281,26.281,0,0,1,10-2.074c5.308,0,9.64,2.257,9.457,8.661L63.789,38.9h4.758v1.952L56.712,43.593l-1.22-5.429a8.207,8.207,0,0,1-8.3,5.552c-5.8,0-9.944-3.964-9.944-8.479m18.056-1.891.06-9.333a22.325,22.325,0,0,1-3.293,2.256c-3.355,2.074-4.759,4.575-4.759,7.686,0,2.808,1.465,6.04,4.028,6.04,1.829,0,3.9-1.831,3.964-6.649" transform="translate(20.398 6.063)"/>
                                        <path d="M73.341,42.773l-10.92-25.5c-.975-2.257-1.463-3.111-3.6-3.355l-.977-.122V11.845H75.293V13.8l-1.281.122c-2.136.183-2.746,1.1-1.952,2.989l6.221,15.067,6.04-13.359c1.463-3.3.488-4.394-1.648-4.637L80.967,13.8V11.845H93.045V13.8l-1.22.122c-2.2.243-2.989,1.1-4.149,3.66L72.547,50.643c-2.378,5.124-4.27,7.381-7.686,7.381a4.3,4.3,0,0,1-4.332-4.575c0-3.477,3.966-6.283,10.494-5.612Z" transform="translate(31.832 6.518)"/>
                                        <path d="M86.752,38V19.335H80.591v-.366l14.762-12.2h.428v7.869h12.2l-2.929,4.7H95.781V35.927c0,4.575,1.524,5.855,4.27,5.855a8.837,8.837,0,0,0,4.942-1.769l.975,1.588a18.675,18.675,0,0,1-11.1,4.087c-4.88,0-8.114-1.22-8.114-7.686" transform="translate(44.349 3.724)"/>
                                        <path d="M99.662,35.237c0-5.795,6.772-7.686,11.59-9.455,6.345-2.319,7.015-4.394,7.015-7.5a4.769,4.769,0,0,0-4.941-5.065c-4.575,0-6.466,3.539-5.124,8.908a18.784,18.784,0,0,1-4.088.487c-3.294,0-4.637-1.829-4.637-3.355,0-2.561,3.783-4.635,7.748-6.161a26.282,26.282,0,0,1,10-2.074c5.308,0,9.64,2.257,9.457,8.661L126.2,38.9h4.758v1.952l-11.835,2.744-1.22-5.429a8.207,8.207,0,0,1-8.3,5.552c-5.8,0-9.944-3.964-9.944-8.479m18.056-1.891.06-9.333a22.407,22.407,0,0,1-3.293,2.256c-3.355,2.074-4.759,4.575-4.759,7.686,0,2.808,1.465,6.04,4.028,6.04,1.829,0,3.9-1.831,3.964-6.649" transform="translate(54.742 6.063)"/>
                                        <path d="M122.379,47.034l1.648-.122c2.135-.183,2.439-.243,2.439-2.44V27.208l-4.635-4.392V22.45l13.664-5.735V44.472c0,2.2.305,2.257,2.44,2.44l1.648.122v1.952h-17.2ZM130.919,0a5.881,5.881,0,0,1,5.735,5.673,5.7,5.7,0,1,1-11.407,0A5.869,5.869,0,0,1,130.919,0" transform="translate(67.042 0)"/>
                                        <path d="M136.27,41.1l1.648-.122c2.133-.183,2.439-.243,2.439-2.44V21.274l-4.635-4.392v-.366l13.664-5.735v7.809c1.343-4.454,4.392-7.32,9.272-7.32,4.942,0,8.663,2.623,8.663,11.407V38.538c0,2.2.305,2.257,2.44,2.44l1.646.122v1.952H154.875V41.1l1.16-.122c2.136-.243,2.257-.243,2.257-2.44V24.386c0-6.344-1.708-7.748-4.149-7.748s-4.758,1.465-4.758,7.809V38.538c0,2.2.122,2.2,2.257,2.44l1.16.122v1.952H136.27Z" transform="translate(74.686 5.933)"/>
                                    </g>
                                    <g className={classNames({primaryText:isHomepage , whiteText:!isHomepage})}>
                                        <path d="M74.7,36.4h.8L79.08,44.42H77.913l-1.063-2.364h-3.4l-1,2.364H71.276Zm1.677,4.642-1.287-2.888L73.9,41.041Z" transform="translate(39.223 20.03)"/>
                                        <path d="M81.737,40.514h2.677v3.38a7.291,7.291,0,0,1-2.922.639,4.337,4.337,0,0,1-3.166-1.155,3.823,3.823,0,0,1-1.184-2.853,3.968,3.968,0,0,1,1.226-2.973,4.249,4.249,0,0,1,3.077-1.189,5.415,5.415,0,0,1,1.279.147,10.254,10.254,0,0,1,1.53.533v1.164a5.6,5.6,0,0,0-2.831-.828,3.01,3.01,0,0,0-2.2.891,3.158,3.158,0,0,0,0,4.407,3.2,3.2,0,0,0,2.307.862,5.381,5.381,0,0,0,1.639-.315l.109-.034V41.529H81.737Z" transform="translate(42.451 20.01)"/>
                                        <path d="M83.663,36.429h4.521v1.015H84.805v2.432H88.07V40.9H84.805v2.477h3.487v1.017H83.663Z" transform="translate(46.039 20.046)"/>
                                        <path d="M94.513,36.429H95.6V44.4h-.983l-5.331-6.142V44.4H88.209V36.429h.926l5.378,6.195Z" transform="translate(48.541 20.046)"/>
                                        <path d="M101.814,42.591v1.256a5.949,5.949,0,0,1-2.9.685A4.469,4.469,0,0,1,96.646,44a3.966,3.966,0,0,1-1.473-1.479,4.018,4.018,0,0,1-.543-2.039,3.892,3.892,0,0,1,1.242-2.929,4.261,4.261,0,0,1,3.063-1.188,7.174,7.174,0,0,1,2.792.657v1.226a5.505,5.505,0,0,0-2.735-.8,3.157,3.157,0,0,0-2.274.865,3.052,3.052,0,0,0-.016,4.324,3.172,3.172,0,0,0,2.277.845,5.165,5.165,0,0,0,2.832-.89" transform="translate(52.075 20.01)"/>
                                        <path d="M100.925,36.429h4.521v1.015h-3.378v2.432h3.265V40.9h-3.265v2.477h3.487v1.017h-4.629Z" transform="translate(55.538 20.046)"/><rect width="1.143" height="7.976" transform="translate(167.908 56.476)"/>
                                        <path d="M117.29,36.429h1.067V44.4H117.21V38.2l-2.451,3.082h-.212l-2.48-3.082V44.4h-1.141V36.429h1.081l2.649,3.276Z" transform="translate(61.041 20.046)"/>
                                        <path d="M123.933,36.429H125V44.4h-1.147V38.2L121.4,41.281h-.212l-2.48-3.082V44.4h-1.141V36.429h1.081L121.3,39.7Z" transform="translate(64.697 20.046)"/>
                                        <path d="M128.265,36.355a4.289,4.289,0,0,1,3.077,1.17,3.879,3.879,0,0,1,1.229,2.935,3.825,3.825,0,0,1-1.24,2.921,4.414,4.414,0,0,1-3.13,1.155,4.157,4.157,0,0,1-3-1.155,3.854,3.854,0,0,1-1.2-2.9,3.941,3.941,0,0,1,1.209-2.958,4.22,4.22,0,0,1,3.059-1.17m.045,1.085a3.094,3.094,0,0,0-2.249.856,2.919,2.919,0,0,0-.882,2.187,2.864,2.864,0,0,0,.884,2.146,3.281,3.281,0,0,0,4.442-.017,2.917,2.917,0,0,0,.893-2.175,2.85,2.85,0,0,0-.893-2.132,3.033,3.033,0,0,0-2.194-.865" transform="translate(68.235 20.006)"/>
                                        <path d="M133.889,44.4h-2.7V36.428h2.088a3.642,3.642,0,0,1,1.543.267,1.9,1.9,0,0,1,1.135,1.806,1.738,1.738,0,0,1-1.215,1.685,2.465,2.465,0,0,1,1.391.719,1.872,1.872,0,0,1,.516,1.324,1.942,1.942,0,0,1-.313,1.09,2.293,2.293,0,0,1-.922.783,3.4,3.4,0,0,1-1.521.3m-.578-6.959h-.981v2.4h.76a1.925,1.925,0,0,0,1.3-.349,1.164,1.164,0,0,0,.384-.9q0-1.146-1.462-1.147m.087,3.414h-1.068v2.53h1.126a3.64,3.64,0,0,0,1.27-.147,1.2,1.2,0,0,0,.536-.453,1.187,1.187,0,0,0,.2-.662,1.129,1.129,0,0,0-.214-.668,1.263,1.263,0,0,0-.608-.451,3.8,3.8,0,0,0-1.245-.149" transform="translate(72.192 20.046)"/><rect width="1.143" height="7.976" transform="translate(211.142 56.476)"/>
                                        <path d="M138.828,36.429h1.143v6.936h3.59V44.4h-4.733Z" transform="translate(76.396 20.046)"/><rect width="1.143" height="7.976" transform="translate(222.137 56.476)"/>
                                        <path d="M145.919,37.246h4.521v1.015h-3.38v2.432h3.266v1.023H147.06v2.477h3.488v1.017h-4.629Zm1.952-2.3.788,1.713h-.639l-1.313-1.713Z" transform="translate(80.298 19.23)"/>
                                        <path d="M150.466,44.4V36.429h2a2.885,2.885,0,0,1,1.922.6,2.01,2.01,0,0,1,.71,1.62,1.989,1.989,0,0,1-1.347,1.964,3.561,3.561,0,0,1,.747.685,13.715,13.715,0,0,1,1.028,1.513q.416.679.668,1.022l.423.571h-1.36l-.347-.526c-.011-.019-.034-.051-.068-.1l-.223-.315-.353-.583-.381-.622a6.086,6.086,0,0,0-.648-.783,2.472,2.472,0,0,0-.532-.419,1.793,1.793,0,0,0-.8-.129h-.3V44.4Zm1.485-7h-.343v2.518h.434a3.217,3.217,0,0,0,1.191-.149,1.127,1.127,0,0,0,.5-.448,1.3,1.3,0,0,0,.18-.682,1.217,1.217,0,0,0-.2-.682,1.072,1.072,0,0,0-.563-.431,4.054,4.054,0,0,0-1.2-.126" transform="translate(82.801 20.047)"/>
                                        <path d="M155.754,36.429h4.521v1.015H156.9v2.432h3.265V40.9H156.9v2.477h3.487v1.017h-4.629Z" transform="translate(85.71 20.046)"/>
                                    </g>
                            </svg>
                        </LocaleLink>
                    </div>
                    {layout.includes("smartphone")&&
                    <div className={classNames("smartphone-menuIcones",{show:isDown,hide:!isDown})}>
                        <Language/>
                        <ProfilIcone/>
                        <BasketIcone/>
                    </div>
                    }
                    <BurgerMenu/>
                    <Navigation/>
                </nav>
            </div>
            </>
    )
}

export default Menu
