import React, { FunctionComponent, ReactNode } from "react";
import "./fourColumn.scss"

type FourColumnProps = {
    children:ReactNode
}

const FourColumn:FunctionComponent<FourColumnProps> = ({children})=>{
    return(
        <div className="fourColumn">
            {children}
        </div>
    )
}

export default FourColumn
