import { useLocation } from "react-router"


const useIsHomePage = ()=>{
    const location = useLocation();

    const isHomepage:boolean = location.pathname === "/fr/" || location.pathname === "/en/";

    return isHomepage;
}

export default useIsHomePage;