import React, { FunctionComponent } from "react";
import { MapContainer, MapMarker } from "../../bundles/map-implement";
import ArticleMarker from "../../assets/svgs/map-marker.svg";
import "./maps.scss";

type ArticleMapProps =  {
    coordinates:number[]
};

export const ArticleMap:FunctionComponent<ArticleMapProps> = ({coordinates})=>{

    return(
        <div className="article-map">
            <MapContainer center={coordinates} zoom={17}>
                <MapMarker position={coordinates} icon={<ArticleMarker/>} />
            </MapContainer>
        </div>
    )
};
