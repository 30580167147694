import React, { FunctionComponent } from "react";
import './banner.scss';

import banner from "../assets/photo-depuis-cabine.jpg";

type Props = {
    title?:string,
    subtitle?:string,
    full?: boolean
};

const Banner:FunctionComponent<Props> = ({title = "", subtitle = null, full = false})=>{
    const className = full?"full-banner":"small-banner";
    const img = `url(${banner})`;

    return(
        <section className={className+" banner"} style={{backgroundImage:img}}>
            <h1 className="banner-title">
                {title}
                {!!subtitle&&<><br/>{subtitle}</>}
                </h1>
            <div className="blackVeil"></div>
        </section>
    );
};

export default Banner;
