import React, { Dispatch, FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faCamera,
  faChevronLeft,
  faDog,
  faDownload,
  faHeart,
  faParking,
  faSmoking,
  faWifi
} from '@fortawesome/free-solid-svg-icons';
import parse from 'html-react-parser';
import moment from "moment";
import { useNavigate } from "react-router";
import { classNames } from "@ct-react/core";
import { ArticleInfoCarousel } from "../../components/carousel";
import ContactForm from "../../components/form/forms";
import {
  DishWasher,
  FirePlace,
  NoDishWasher,
  NoFirePlace,
  NoParking,
  NoPet,
  NoSmoker,
  NoTv,
  NoWifi,
  ShareIcones,
  Tv
} from "../../components/wrapper/icones";
import { ArticleImages, RentArticleSummary, SaleArticleSummary } from "../../models/articles";
import BookingBox from "../../components/form/bookingBox";
import { gql, useQuery } from "@apollo/client";
import { IMAGE_FIELDS } from "@shared/fragments";
import gsap from "gsap";
import { formatPrice } from "../../utils/price";
import { useIntl } from "react-intl";
import { LayoutContext } from "../../contexts/layout";
import "./articleInfo.scss";
import { translateMatchableIntlDef, useLocaleContext } from "@ct-react/locale";

type ArticleHeaderProps = {
    props:SaleArticleSummary|RentArticleSummary,
    type:"weekly"|"seasonal"|"annual"|"sale",
    weeklyLocationPrice?:any
};

type SaleProps = {
    props:SaleArticleSummary,
};

type RentProps = {
    props:RentArticleSummary,
};

type ImagesProps = {
    props:ArticleImages[],
};

type ArticleTitleProps = {
    classNames?:string,
    props:SaleArticleSummary|RentArticleSummary
};

type ArticlePriceProps = {
    classNames?:string,
    props:any,
    type:"weekly"|"seasonal"|"annual"|"sale",
    weeklyLocationPrice?:any,
    suggestion?:any | null
};

type ArticleInfoProps = {
    articleId: string,
    description:string | null,
    props:any,
    type:"weekly"|"seasonal"|"annual"|"sale",
    weeklyLocationPrice?:any,
    setWeeklyLocationPrice:Dispatch<any>,
};

type ArticleFloorsProps = {
    articleId:string,
};

export const ArticleHeader:FunctionComponent<ArticleHeaderProps>=({props,type,weeklyLocationPrice})=>{
    const intl = useIntl();
    const navigate = useNavigate();
    const data = {...props};

    const goBack = ()=>{
        navigate(-1)
    };

    const renderPrice = useMemo(()=>{
        return <ArticlePrice props={data} type={type} weeklyLocationPrice={weeklyLocationPrice} />
    },[weeklyLocationPrice]);

    return(
            <header className="article-header">
                <div>
                    <div onClick={goBack}>
                        <FontAwesomeIcon className="article-header-chevronLeft" icon={faChevronLeft} color="var(--red)"/>
                        <span className="article-header-navigator">
                            {intl.formatMessage({id:"btn-back",  defaultMessage: "Retour"})}
                        </span>
                    </div>
                </div>
                <ArticleTitle props={data}/>
                <div className="article-header-info">
                    {renderPrice}
                    <div className="article-header-icones">
                        <div className="article-header-icone" data-gloss={intl.formatMessage({ id: "print-tooltip", defaultMessage: "Imprimer en PDF" })}>
                            <FontAwesomeIcon icon={faDownload} onClick={()=>window.print()}/>
                        </div>
                    </div>
                </div>
            </header>
    );
};

export const ArticleTitle:FunctionComponent<ArticleTitleProps>= ({props,classNames})=>{
    const intl = useIntl();
    const data = {...props};
    const features = {...data.features};
    return(
        <hgroup className={classNames}>
            <span>
                {!!features.rooms&&`${features.rooms} ${intl.formatMessage({id:"rooms",  defaultMessage: "Pièces"})} |`}  {!!features.livingSpace&&`${features.livingSpace} m²`}
            </span>
            <h1 className={`article-header-h1 ${!!classNames&&classNames}`}>
                {data.title.value}
            </h1>
        </hgroup>
    );
};

export const ArticlePrice:FunctionComponent<ArticlePriceProps> =({props,classNames,type,weeklyLocationPrice,suggestion})=>{
    const data = {...props};
    const intl = useIntl();
    const classes = `price-span ${!!classNames&&classNames}`;

    //PRICE ON DEMAND
    if(data.priceOnDemand) return(<span className={classes}>{intl.formatMessage({ id:"price-on-request-only", defaultMessage: "Prix sur demande"})}</span>);

    //WEEKLY OBJET DETAILS
    if(!!weeklyLocationPrice && !weeklyLocationPrice){
        return(<span className={classes}>
            {intl.formatMessage(
                { id:"price-and-date-articleInfo", defaultMessage: "Du {checkin} au {checkout} / {currency} {price}.-"},
                {
                    checkin:moment(weeklyLocationPrice.checkIn).format("DD-MM-YY"),
                    checkout:moment(weeklyLocationPrice.checkOut).format("DD-MM-YY"),
                    currency:weeklyLocationPrice.price.currency,
                    price:formatPrice(weeklyLocationPrice.price.amount)
                }
            )}
            </span>);
    }

    //WEEKLY WITH PERIOD SUGGESTION BOOKABLE
    if(!!suggestion && suggestion.bookable) {
        const customer_charge = suggestion.extras.find((e:any) => e.type === "CUSTOMER_CHARGE");
        const priceWithCharge = suggestion.price.amount + customer_charge.amount;
        return(
            <div>
                <span className={classes}>
                    {suggestion.price.isDiscounted &&
                    <>
                        <meta itemProp="priceCurrency" content={suggestion.price.currency}></meta>
                        <span itemProp="price" content={suggestion.price.original} className="strike">{suggestion.price.currency}{suggestion.price.original}.-</span>
                    </>
                    }
                    <span itemProp="price" className={suggestion.price.isDiscounted? "discount" : ""}>{suggestion.price.currency} {formatPrice(priceWithCharge)}.-</span>
                </span>
                <span className="period-span">
                {intl.formatMessage(
                    {id: "seasonly-period-price-period", defaultMessage: "Du {from} au {to}"},
                    {
                    from: moment(suggestion.checkIn).format("DD/MM/YY"),
                    to: moment(suggestion.checkOut).format("DD/MM/YY")
                    })}
                </span>
            </div>
        );
    }

    //WEEKLY CARD WITHOUT PERIOD SUGGESTION
    if(type === "weekly" && !!data.price.amount) return(
        <>
            <meta itemProp="priceCurrency" content={data.price.currency}></meta>
            <span itemProp="price" content={data.price.amount} className={classes}>
                Dès {data.price.currency} {formatPrice(data.price.amount)}.- / {intl.formatMessage({id:"week",  defaultMessage: "semaine"})}
            </span>
        </>
    );

    //SEASONAL
    if(type === "seasonal" ) {
      //IS MONTHLY
      return (
        <div>
        <meta itemProp="priceCurrency" content={data.price.value.currency}></meta>
          <span itemProp="price" content={data.price.value.amount} className={classes}>
            {data.price.isMonthlyPrice
              ? <>{data.price.value.currency} {formatPrice(data.price.value.amount)}.-/{intl.formatMessage({
                id: "month",
                defaultMessage: "mois"
              })}</>
              : <>{data.price.value.currency} {formatPrice(data.price.value.amount)}.-</>
            }
            <span className="period-span">
              {intl.formatMessage(
                {id: "seasonly-period-price-period", defaultMessage: "Du {from} au {to}"},
                {
                  from: moment(data.price.period[0]).format("DD/MM/YY"),
                  to: moment(data.price.period[1]).format("DD/MM/YY")
                })}
            </span>
          </span>
        </div>);
    }
    //SALE
    if(type === "sale" && !!data.price.amount){
        return(
            <span className={classes}>
                {data.price.currency} {formatPrice(data.price.amount)}.-
            </span>
        );
    }
    //ANNUAL
    if(type === "annual" && !!data.price.amount){
        const availability = !!data.availableFrom ?
            intl.formatMessage({id:"availaible-from",defaultMessage:"Libre à partir du {date}"},{date: moment(data.availableFrom).format("DD/MM/YY")})
            :
            intl.formatMessage({id:"availaible-now",defaultMessage:"Libre immédiatement"})
            ;
        return(
            <div>
                <meta itemProp="priceCurrency" content={data.price.currency}></meta>
                <span itemProp="price" content={data.price.amount} className={classes}>
                    {data.price.currency} {formatPrice(data.price.amount)}.- / {intl.formatMessage({id:"month",  defaultMessage: "mois"})}
                    <span className="period-span">
                        {availability}
                    </span>
                </span>
            </div>
        );
    }

    //DEFAULT
    return (<span className={classes}>{intl.formatMessage({ id:"price-on-request-only", defaultMessage: "Prix sur demande"})}</span>);
};

export const ArticleImageGrid:FunctionComponent<ImagesProps> = ({props})=>{
    const intl = useIntl();
    const layout = useContext(LayoutContext);
    const [showCarousel,setShowCarousel] = useState<boolean>(false);
    const images = [...props];

    const handleShowCarousel = (e:React.MouseEvent<HTMLElement>)=>{
        if(e.target.type === "button") return;
        e.nativeEvent.stopImmediatePropagation();
        setShowCarousel(!showCarousel);
    };

    const handleKeyDown = (e:React.KeyboardEvent)=>{
        e.key === "Escape" && setShowCarousel(false);
    };

    useEffect(() => {
        // Ajouter l'écouteur d'événement lors du montage du composant
        window.addEventListener('keydown', handleKeyDown);

        // Retirer l'écouteur d'événement lors du démontage du composant
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

    useEffect(()=>{
        if(showCarousel) document.body.style.overflow = "hidden";
        if(!showCarousel) document.body.style.overflow = "initial";
    },[showCarousel])

    if(images.length<=0){
        return(
            <div className="article-image-empty">
                <FontAwesomeIcon icon={faCamera}/>
                <p>
                    {intl.formatMessage({id:"no-image", defaultMessage: "Aucune image disponible"})}
                </p>
            </div>
        )
    }

    return(
        <>
        <ArticleInfoCarousel
            isActive={showCarousel}
            setIsActive={setShowCarousel}
            props={images}
            onClick={handleShowCarousel}
        />
        <div className="article-image-grid" onClick={handleShowCarousel}>
            <div style={{backgroundImage:`url(${images[0].original})`}}></div>
            <div style={!!images[1] ? {backgroundImage:`url(${images[1].original})`} : {backgroundImage:`url(${images[0].original})`}}></div>
            <div style={!!images[2] ? {backgroundImage:`url(${images[2].original})`} : {backgroundImage:`url(${images[0].original})`}}></div>
            <div style={!!images[3] ? {backgroundImage:`url(${images[3].original})`} : {backgroundImage:`url(${images[0].original})`}}></div>
            <div style={!!images[4] ? {backgroundImage:`url(${images[4].original})`} : {backgroundImage:`url(${images[0].original})`}}></div>
            <div className="grid-btn" onClick={handleShowCarousel}>
                <FontAwesomeIcon icon={faCamera}/>
                {intl.formatMessage({ id:"carousel-see-photos", defaultMessage: "Voir les {length} photos"},{length:images.length})}
            </div>
        </div>
        </>
    );
};

export const ArticleInfo:FunctionComponent<ArticleInfoProps> = ({articleId, description,props,type,weeklyLocationPrice,setWeeklyLocationPrice})=>{
    const intl = useIntl();
    const [changeForm, setChangeForm] = useState<boolean>(false);
    const data = {...props};

    const returnForm = ()=>{
        if(data.priceOnDemand || type!="weekly" || changeForm ){
            return (
                <>
                <h2>
                    {intl.formatMessage({ id:"cd-contact-us", defaultMessage: "Contactez-nous"})}
                </h2>
                <p>
                    {type==="sale"&& intl.formatMessage({ id:"articleInfo-contact-us-for-visit", defaultMessage: "Programmons ensemble une visite !"})}
                </p>
                <ContactForm articleId={articleId} type={type} />
            </>
            )
        }else{
            return(
                <BookingBox props={data} changeForm={()=>setChangeForm(true)} setPrice={setWeeklyLocationPrice}/>
            )
        }
    };

    const renderPrice = useMemo(()=>{
        return <ArticlePrice props={data} type={type} weeklyLocationPrice={weeklyLocationPrice} />
    },[weeklyLocationPrice]);

    return(
        <div className="article-info">
            <ArticleTitle props={data} classNames="article-info-title"/>
            {renderPrice}
            <div className="article-description">
                <h2>
                    {intl.formatMessage({ id:"description", defaultMessage: "Description"})}
                </h2>
                {!!description ? parse(description) : intl.formatMessage({ id:"no-description", defaultMessage: "Ce bien ne possède pas de description pour le moment. Pour plus d'informations veuillez nous contacter."}) }
                {type==="sale"?
                    (
                        <SaleArticleEquipment props={data}/>
                    )
                :
                    (
                        <RentArticleEquipement props={data}/>
                    )
                }
            {data.withPlans&&
                <ArticleFloors articleId={data.id}/>
            }
            </div>
            <article className="article-form">
                {returnForm()}
                <ShareIcones />
            </article>
        </div>
    );
};

export const SaleArticleEquipment:FunctionComponent<SaleProps> = ({props})=>{
    const intl = useIntl();
    const summary =  {...props}
    const features = summary.features;

    return(
        <div className="article-sale-equipment">
            <ul>
                <li><b>{intl.formatMessage({ id:"price", defaultMessage: "Prix"})}          </b><b><span>:</span></b> <p>{summary.priceOnDemand? intl.formatMessage({ id:"price-on-request-only", defaultMessage: "Prix sur demande"}) : `${formatPrice(summary.price.amount)}`}</p></li>
                <li><b>{intl.formatMessage({ id:"bedroom", defaultMessage: "Chambre"})}       </b><b><span>:</span></b> <p>{!!features.bedrooms? features.bedrooms : "-"}</p></li>
                <li><b>{intl.formatMessage({ id:"bathroom", defaultMessage: "Salle de bain"})} </b><b><span>:</span></b> <p>{!!features.bathrooms? features.bathrooms : "-"}</p></li>
                <li><b>{intl.formatMessage({ id:"parking", defaultMessage: "Parking"})}       </b><b><span>:</span></b> <p>{features.withParking? intl.formatMessage({ id:"yes", defaultMessage: "Oui"}) : intl.formatMessage({ id:"no", defaultMessage: "Non"})}</p></li>
                <li><b>{intl.formatMessage({ id:"furniture", defaultMessage: "Meublé"})}        </b><b><span>:</span></b> <p>{!!features.isFurnished? intl.formatMessage({ id:"yes", defaultMessage: "Oui"}) : intl.formatMessage({ id:"no", defaultMessage: "Non"})}</p></li>
                <li><b>{intl.formatMessage({ id:"charges", defaultMessage: "Charges"})}       </b><b><span>:</span></b> <p>{!!features.charges? `${formatPrice(features.charges.amount)}${features.charges.currency}` : "-"}</p></li>
            </ul>
            <ul>
                <li><b>{intl.formatMessage({ id:"surface", defaultMessage: "Surface"})}             </b><b><span>:</span></b> <p>{!!features.livingSpace?features.livingSpace:"-"}</p></li>
                <li><b>{intl.formatMessage({ id:"orientation", defaultMessage: "Orientation"})}         </b><b><span>:</span></b> <p>{translateMatchableIntlDef(intl, "orientations", features.orientation)}</p></li>
                <li><b>{intl.formatMessage({ id:"wc", defaultMessage: "WC"})}                  </b><b><span>:</span></b> <p>{!!features.restrooms?features.restrooms:"-"}</p></li>
                <li><b>{intl.formatMessage({ id:"heater", defaultMessage: "Chauffage"})}           </b><b><span>:</span></b> <p>{!!features.heatings ? features.heatings.map(he => translateMatchableIntlDef(intl, "heatingSystems", he)).join(", ") : "-"}</p></li>
                <li><b>{intl.formatMessage({ id:"sale-stranger", defaultMessage: "Vente étranger"})}      </b><b><span>:</span></b> <p>{features.isSellableToForeigners?"Oui":"Non"}</p></li>
                <li><b>{intl.formatMessage({ id:"renovation-funds", defaultMessage: "Fonds de rénovation"})} </b><b><span>:</span></b> <p>{!!features.renovationFund?`${formatPrice(features.renovationFund.amount)}${features.renovationFund.currency}`:"-"}</p></li>
            </ul>
        </div>
    )
};

export const RentArticleEquipement:FunctionComponent<RentProps> = ({props})=>{
    const intl = useIntl();
    const data = {...props};
    const features = data.features

    return(
        <div className="article-rent-equipment">
                <h2>
                    {intl.formatMessage({ id:"articleInfo-what-propose-accomodation", defaultMessage: "Ce que propose ce logement"})}
                </h2>
                <ul>
                    <li className={classNames({"line-through":!features.isSmokerCompliant})}>
                        {features.isSmokerCompliant?
                        (
                            <FontAwesomeIcon icon={faSmoking}/>
                        )
                        :
                        (
                            <NoSmoker/>
                        )
                        }
                        {intl.formatMessage({ id:"smoker", defaultMessage: "Fumeur"})}
                    </li>
                    <li className={classNames({"line-through":!features.withParking})}>
                        {features.withParking?
                        (
                            <FontAwesomeIcon icon={faParking}/>
                        )
                        :
                        (
                            <NoParking/>
                        )
                        }
                        {intl.formatMessage({ id:"free-parking", defaultMessage: "Parking gratuit"})}
                    </li>
                    <li className={classNames({"line-through":!features.isPetCompliant})}>
                        {features.isPetCompliant?
                        (
                            <FontAwesomeIcon icon={faDog}/>
                        )
                        :
                        (
                            <NoPet/>
                        )
                        }
                        {intl.formatMessage({ id:"pet-allowed", defaultMessage: "Animaux acceptés"})}
                    </li>
                    <li className={classNames({"line-through":!features.withWIFI})}>
                        {features.withWIFI?
                        (
                            <FontAwesomeIcon icon={faWifi}/>
                        )
                        :
                        (
                            <NoWifi/>
                        )
                        }
                        {intl.formatMessage({ id:"wifi-availaible", defaultMessage: "Wifi sur place"})}
                    </li>
                    <li className={classNames({"line-through":!features.withTV})}>
                        {features.withTV?
                        (
                            <Tv/>
                        )
                        :
                        (
                            <NoTv/>
                        )
                        }
                        {intl.formatMessage({ id:"with-tv", defaultMessage: "Télévision"})}
                    </li>
                    <li className={classNames({"line-through":!features.withFireplace})}>
                        {features.withFireplace?
                        (
                            <FirePlace/>
                        )
                        :
                        (
                            <NoFirePlace/>
                        )
                        }
                        {intl.formatMessage({ id:"with-chimney", defaultMessage: "Cheminée"})}
                    </li>
                    <li className={classNames({"line-through":!features.withDishwasher})}>
                        {features.withDishwasher?
                        (
                            <DishWasher/>
                        )
                        :
                        (
                            <NoDishWasher/>
                        )
                        }
                        {intl.formatMessage({ id:"with-dishwasher", defaultMessage: "Lave-vaisselle"})}
                    </li>
                </ul>
        </div>
    );
};

const FLOOR_IMAGES = gql`
    ${IMAGE_FIELDS}
    query GetImageFloor($articleId: ID!){
    accommodationPlans(id: $articleId) {
      ...ImageFields
    }
  }
`;

export const ArticleFloors:FunctionComponent<ArticleFloorsProps> = ({articleId})=>{
    const intl = useIntl();
    const [imageFloor, setImageFloor] = useState<any|null>(null)
    const [displayable,setDisplayable] = useState<boolean>(false)
    const [imgIsVisible, setImgIsVisible] = useState<boolean>(false)

    useQuery(FLOOR_IMAGES,{
        variables:{
            articleId:articleId
        },
        onCompleted:(data)=>{
            setImageFloor(data.accommodationPlans)
            setDisplayable(true)
        }
    })

    const img = useRef(null)

    useEffect(()=>{
        imgIsVisible&& gsap.to(img.current,{height:"auto",autoAlpha:1,marginTop:"1rem"})
        !imgIsVisible&& gsap.to(img.current,{height:0,autoAlpha:0,marginTop:0})
    },[imgIsVisible])

    return(
        <div className="article-floorImage">
            <h2>
                {intl.formatMessage({ id:"floor-plan", defaultMessage: "Plans des étages"})}
            </h2>
            <div className="article-floorImage-accordion">
                {intl.formatMessage({ id:"floors", defaultMessage: "Étages"})}
                <FontAwesomeIcon icon={imgIsVisible?faArrowCircleUp:faArrowCircleDown} color="var(--red)" onClick={()=>setImgIsVisible(!imgIsVisible)}/>
                {displayable&&
                <img ref={img} src={imageFloor[0].extraLarge} alt=""/>
                }
            </div>
        </div>
    )
};



