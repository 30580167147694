import React, { FunctionComponent } from "react";
import Banner from "../../components/banner";

const Weather:FunctionComponent = ()=>{
    return (
        <>
            <Banner title="Météo"/>
        </>
    )
}

export default Weather
