import { Locale } from "@ct-react/locale";
import French from "./fr-CH.json";
import English from "./en-GB.json";
import { defineMessages } from "react-intl";

export const getIntlMessages = (locale: Locale) => {
  switch (locale.basename) {
    case "fr": return French;
    case "en": return English;
    default: throw new Error(`missing translations for locale ${locale.code}`);
  }
};

export const commonDefs = defineMessages({
  rent: { id: "cd-rent", defaultMessage: "Location" },
  toRent: { id: "cd-to-rent", defaultMessage: "Louer" },
  sale: { id: "cd-sale", defaultMessage: "Vente" },
  toBuy: { id: "cd-to-buy", defaultMessage: "Acheter" },
  putRent: { id: "cd-put-rent", defaultMessage: "Mettre en location" },
  putSale: { id: "cd-put-on-sale", defaultMessage: "Mettre en vente" },
  contact: { id: "cd-contact-us", defaultMessage: "Contactez-nous" },
  toReset: { id: "cd-to-reset", defaultMessage: "Réinitialiser" },
  toValidate: { id: "cd-to-validate", defaultMessage: "Valider" }
});
