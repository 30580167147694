import React, { FunctionComponent } from "react";

const Profil:FunctionComponent = ()=>{

    return (
        <h1>Profil page</h1>
    )
}

export default Profil
