import React, { FunctionComponent } from "react";
import { faFacebookF, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";
import { useAppConfig } from "@ct-react/core";
import { useLocaleUrl } from "@ct-react/locale";
import "./icones.scss";



export const NoWifi:FunctionComponent = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" transform="scale(1.5)" viewBox="0 0 18 18">
            <g fill="var(--blue)">
                <path d="M16,13.62l-5.15-4.03c-0.27-0.21-0.53-0.42-0.8-0.62c0.54,0.09,1.06,0.28,1.53,0.57
                    c0.3,0.18,0.57,0.41,0.87,0.61c0.3,0.21,0.72,0.15,0.95-0.14c0.25-0.31,0.21-0.75-0.09-1.01c-0.47-0.41-1-0.75-1.58-1.01
                    c-1.08-0.49-2.27-0.69-3.45-0.56C8.18,7.43,8.07,7.4,7.98,7.33C7.34,6.83,6.7,6.34,6.06,5.85c-0.03-0.03-0.06-0.06-0.11-0.1
                    c0.02-0.01,0.04-0.02,0.06-0.03C6.57,5.54,7.13,5.4,7.7,5.28c1.43-0.23,2.89-0.09,4.24,0.42c0.8,0.28,1.56,0.69,2.24,1.19
                    c0.31,0.24,0.6,0.51,0.91,0.76c0.14,0.12,0.32,0.18,0.51,0.16c0.4-0.04,0.69-0.4,0.64-0.8c-0.02-0.18-0.11-0.35-0.25-0.47
                    c-1.02-0.98-2.24-1.73-3.57-2.21c-1.35-0.49-2.79-0.68-4.22-0.55C7.04,3.85,5.91,4.14,4.86,4.61C4.72,4.7,4.54,4.67,4.42,4.56
                    c-0.58-0.48-1.18-0.94-1.78-1.4C2.43,2.95,2.08,2.95,1.87,3.16C1.66,3.38,1.66,3.73,1.88,3.94C1.9,3.96,1.93,3.99,1.96,4.01
                    C2.74,4.62,3.52,5.23,4.3,5.84l8.46,6.63c0.85,0.67,1.7,1.34,2.56,2c0.16,0.15,0.38,0.2,0.59,0.14c0.19-0.07,0.33-0.24,0.35-0.44
                    C16.29,13.96,16.19,13.74,16,13.62L16,13.62z"/>
                <path d="M10.12,11.8c-0.27-0.19-0.53-0.39-0.77-0.61C9.21,11.06,9.01,11,8.82,11.03c-0.79,0.11-1.34,0.83-1.25,1.62
                    C7.67,13.45,8.4,14,9.19,13.89c0.72-0.1,1.25-0.71,1.25-1.43C10.47,12.2,10.34,11.95,10.12,11.8L10.12,11.8z"/>
                <path d="M5.55,8.35L5.55,8.35c-0.28,0.2-0.55,0.41-0.82,0.62C4.4,9.19,4.33,9.63,4.55,9.95
                    C4.56,9.97,4.57,9.98,4.59,10c0.24,0.31,0.69,0.36,1,0.12C5.6,10.11,5.6,10.1,5.61,10.1c0.25-0.2,0.51-0.38,0.77-0.56
                    c0.17-0.1,0.34-0.19,0.51-0.27C6.49,8.95,6.1,8.64,5.71,8.34C5.66,8.31,5.6,8.32,5.55,8.35z"/>
                <path d="M1.88,6.64L1.88,6.64C1.65,6.91,1.66,7.31,1.9,7.57c0.23,0.27,0.63,0.32,0.92,0.11
                    c0.34-0.26,0.67-0.55,1.01-0.83L2.64,5.94C2.38,6.17,2.12,6.39,1.88,6.64z"/>
            </g>
        </svg>
    )
};

export const Tv:FunctionComponent = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" transform="scale(1.5)" viewBox="0 0 18 18">
            <path fill="var(--blue)" d="M2.48,13.97c0.06,0.92,0.86,1.61,1.78,1.55c3.16,0,6.32,0,9.49,0c0.17,0,0.35-0.02,0.52-0.06
            c0.75-0.17,1.28-0.85,1.25-1.62V6.63c0-0.06,0-0.11,0-0.17c-0.02-0.48-0.27-0.93-0.67-1.21c-0.3-0.21-0.66-0.31-1.02-0.3
            c-0.97-0.01-1.95-0.02-2.92-0.03h-0.22c0.06-0.07,0.1-0.11,0.14-0.15c0.39-0.39,0.78-0.78,1.16-1.17c0.06-0.06,0.11-0.12,0.16-0.19
            c0.18-0.27,0.12-0.63-0.15-0.82c-0.03-0.02-0.06-0.04-0.1-0.05c-0.26-0.12-0.57-0.04-0.75,0.19c-0.67,0.67-1.34,1.34-2.01,2
            C9.1,4.77,9.05,4.81,8.98,4.88C8.86,4.75,8.75,4.62,8.63,4.5c-0.6-0.6-1.19-1.2-1.79-1.79c-0.21-0.27-0.6-0.31-0.86-0.1
            C5.92,2.66,5.87,2.73,5.83,2.8C5.69,3.06,5.76,3.38,5.99,3.57C6.42,4.01,6.86,4.44,7.3,4.88L7.28,4.92H4.2c-0.03,0-0.06,0-0.09,0
            C3.2,4.94,2.47,5.69,2.48,6.61c0,0,0,0,0,0c-0.01,2.39-0.01,4.77,0,7.16C2.48,13.83,2.48,13.9,2.48,13.97 M14.51,10.45
            c-0.01,0.33-0.28,0.6-0.62,0.59c-0.34-0.01-0.6-0.3-0.59-0.64c0.01-0.32,0.27-0.58,0.6-0.59h0.02c0.33,0.01,0.6,0.28,0.59,0.62l0,0
            C14.51,10.43,14.51,10.44,14.51,10.45 M13.88,7.77h0.02c0.33,0,0.6,0.26,0.6,0.58h0c0,0.01,0,0.02,0,0.03
            c-0.01,0.33-0.28,0.59-0.61,0.59c0,0,0,0,0,0c-0.33-0.01-0.6-0.27-0.62-0.6C13.3,8.04,13.56,7.78,13.88,7.77 M4.1,8.25
            c0-0.03,0-0.06,0-0.1c0.03-0.92,0.8-1.65,1.73-1.61h4.71c0.78-0.03,1.48,0.49,1.67,1.25c0.04,0.17,0.06,0.34,0.05,0.52
            c0.01,1.26,0,2.53,0.01,3.79c0.02,0.31-0.03,0.62-0.17,0.9c-0.29,0.56-0.88,0.91-1.51,0.89c-0.8,0.01-1.6,0-2.4,0h0H5.82
            c-0.03,0-0.06,0-0.09,0c-0.92-0.02-1.65-0.79-1.63-1.72c0,0,0,0,0,0C4.1,10.87,4.1,9.57,4.1,8.25"/>
        </svg>
    )
};

export const NoTv:FunctionComponent = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" transform="scale(1.5)" viewBox="0 0 18 18">
            <g>
                <path fill="transparent" d="M9.84,6.95l-2.37,0l4.09,3.23c0-0.49,0-0.97,0-1.46c0.01-0.17-0.01-0.35-0.05-0.52
                    C11.31,7.44,10.61,6.92,9.84,6.95z"/>
                <path fill="transparent" d="M13.2,9.37C13.2,9.37,13.2,9.37,13.2,9.37c0.33,0.01,0.61-0.26,0.61-0.59c0-0.01,0-0.02,0-0.03h0
                    c-0.01-0.33-0.28-0.59-0.6-0.58h-0.02l0,0c-0.33,0.01-0.59,0.27-0.61,0.6C12.59,9.1,12.86,9.37,13.2,9.37z"/>
                <path fill="transparent" d="M13.81,10.83c0.01-0.33-0.26-0.61-0.59-0.62h-0.02c0,0,0,0,0,0c-0.34,0.01-0.61,0.29-0.59,0.63
                    c0,0.07,0.02,0.13,0.04,0.19l0.5,0.4c0.02,0,0.03,0.01,0.04,0.01c0.33,0.01,0.61-0.26,0.62-0.59l0,0
                    C13.81,10.85,13.81,10.84,13.81,10.83z"/>
            </g>
            <g>
                <path fill="transparent" d="M13.2,9.37L13.2,9.37c0.33,0.01,0.61-0.26,0.61-0.59V8.76h0c-0.01-0.33-0.28-0.59-0.6-0.58h-0.02l0,0
                    c-0.33,0.01-0.59,0.27-0.61,0.6C12.59,9.1,12.86,9.37,13.2,9.37z"/>
                <path fill="transparent" d="M13.81,10.83c0.01-0.33-0.26-0.61-0.59-0.62h-0.02c0,0,0,0,0,0c-0.34,0.01-0.61,0.29-0.59,0.63
                    c0,0.07,0.02,0.13,0.04,0.19l0.5,0.4c0.02,0,0.03,0.01,0.04,0.01c0.33,0.01,0.61-0.26,0.62-0.59l0,0V10.83z"/>
                <path fill="transparent" d="M9.84,6.95l-2.37,0l4.09,3.23V8.71c0.01-0.17-0.01-0.35-0.05-0.52C11.31,7.44,10.61,6.92,9.84,6.95z"/>
                <path fill="var(--blue)" d="M11.39,13.41c-0.29,0.56-0.88,0.91-1.51,0.89c-0.8,0.01-1.6,0-2.4,0h0H5.12c-0.03,0-0.06,0-0.09,0
                    c-0.92-0.02-1.65-0.79-1.63-1.72c0,0,0,0,0,0c-0.01-1.31-0.01-2.62,0-3.93c0-0.03,0-0.06,0-0.1C3.42,7.95,3.78,7.4,4.33,7.12
                    L2.46,5.65C2.02,5.97,1.77,6.48,1.78,7.01c-0.01,2.39-0.01,4.78,0,7.16c-0.05,0.92,0.65,1.71,1.57,1.76c0,0,0,0,0,0
                    c0,0,10.05-0.02,10.22-0.06l0,0c0.4-0.09,0.75-0.33,0.98-0.67l-3-2.37C11.54,13.03,11.48,13.23,11.39,13.41z"/>
                <path fill="var(--blue)" d="M16.08,13.75l-1.26-0.99V7c0-0.05,0-0.09,0-0.14c-0.02-0.48-0.27-0.93-0.67-1.21c-0.3-0.21-0.66-0.31-1.03-0.3
                    c-0.97-0.01-1.95-0.02-2.92-0.03H10c0.06-0.07,0.1-0.11,0.14-0.15c0.39-0.39,0.77-0.78,1.16-1.17c0.06-0.06,0.11-0.12,0.16-0.19
                    c0.19-0.26,0.12-0.63-0.14-0.81c-0.03-0.02-0.07-0.04-0.1-0.06c-0.26-0.12-0.57-0.04-0.75,0.19c-0.67,0.67-1.34,1.34-2.01,2
                    C8.4,5.18,8.36,5.22,8.28,5.29C8.17,5.16,8.06,5.03,7.94,4.91c-0.6-0.6-1.19-1.2-1.79-1.79C6.05,2.99,5.9,2.91,5.74,2.89H5.6
                    C5.4,2.91,5.23,3.03,5.13,3.2C5,3.47,5.06,3.79,5.29,3.97c0.44,0.44,0.87,0.87,1.31,1.31L6.59,5.33H5.41L4.64,4.72
                    c0,0-2.1-1.65-2.1-1.65C2.49,3.03,2.37,2.98,2.36,2.97c-0.2-0.06-0.42,0-0.55,0.18c-0.13,0.17-0.13,0.4-0.03,0.57
                    C1.8,3.77,1.83,3.8,1.87,3.84l13.56,10.74c0.23,0.18,0.56,0.13,0.74-0.1C16.34,14.25,16.3,13.93,16.08,13.75L16.08,13.75z
                    M11.55,10.17L7.46,6.95l2.37,0c0.78-0.03,1.47,0.49,1.67,1.25c0.04,0.17,0.06,0.34,0.05,0.52V10.17z M13.19,8.17L13.19,8.17
                    l0.02,0c0.33,0,0.6,0.26,0.6,0.58h0v0.03c-0.01,0.33-0.28,0.59-0.61,0.59h0c-0.33-0.01-0.6-0.27-0.62-0.6
                    C12.6,8.44,12.86,8.19,13.19,8.17z M13.2,11.44c-0.01,0-0.03,0-0.04-0.01l-0.5-0.4c-0.02-0.06-0.04-0.13-0.04-0.19
                    c-0.01-0.34,0.26-0.62,0.59-0.63c0,0,0,0,0,0h0.02c0.33,0.01,0.6,0.28,0.59,0.62v0.02l0,0C13.81,11.19,13.53,11.45,13.2,11.44z"/>
            </g>
        </svg>
    )
};

export const NoParking:FunctionComponent = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 18 18" transform="scale(1.5)">
            <g fill="var(--blue)">
                <path d="M8.72,6.1l-2.27,0l2.07,1.64h0.2c0.45,0,0.81,0.36,0.82,0.8l1.39,1.09c0.16-0.34,0.25-0.71,0.25-1.08
                    C11.17,7.19,10.07,6.1,8.72,6.1z" fill="transparent"/>
                <path d="M8.72,11H7.49v1.23c0,0.23-0.18,0.41-0.41,0.41H6.27c-0.23,0-0.41-0.18-0.41-0.41V8.29L2.59,5.71v8.16
                    c0,0.68,0.55,1.23,1.23,1.23h8.99c0.4,0,0.78-0.2,1.01-0.53l-4.58-3.61C9.06,10.98,8.89,11,8.72,11z"/>
                <path d="M16.11,13.72l-2.08-1.64V4.87c0-0.68-0.55-1.23-1.23-1.23H3.81c-0.13,0-0.26,0.02-0.39,0.07L2.55,3.02
                    C2.31,2.84,1.98,2.88,1.8,3.12C1.63,3.34,1.67,3.67,1.89,3.85l13.5,10.65l0.07,0.05c0.23,0.18,0.57,0.13,0.74-0.1
                    C16.37,14.23,16.33,13.9,16.11,13.72L16.11,13.72z M10.92,9.63L9.54,8.53c-0.01-0.45-0.37-0.8-0.82-0.8h-0.2L6.45,6.1l2.27,0
                    c1.35,0,2.45,1.1,2.45,2.45C11.17,8.92,11.09,9.29,10.92,9.63z"/>
            </g>
        </svg>
    )
};

export const NoPet:FunctionComponent = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 18 18" transform="scale(1.5)" >
            <g fill="var(--blue)">
                <path fill="transparent" d="M11.89,5.77c0.2,0,0.36-0.16,0.36-0.36c0-0.2-0.16-0.36-0.36-0.36c-0.2,0-0.36,0.16-0.36,0.36
                    C11.53,5.61,11.69,5.77,11.89,5.77z"/>
                <path d="M11.41,7.92h1.55c0.99,0,1.79-0.8,1.79-1.79V5.59c0-0.3-0.24-0.54-0.54-0.54l-1.25,0l-0.4-0.4
                    c-0.2-0.2-0.48-0.31-0.76-0.31h-1.16l-0.38-0.5C10.09,3.6,9.75,3.55,9.52,3.73c-0.11,0.08-0.19,0.2-0.21,0.34l-0.52,3.1l2.5,1.43
                    L11.41,7.92z M11.89,5.05c0.2,0,0.36,0.16,0.36,0.36c0,0.2-0.16,0.36-0.36,0.36c-0.2,0-0.36-0.16-0.36-0.36
                    C11.53,5.22,11.69,5.05,11.89,5.05z"/>
                <path d="M4.06,7.9C3.74,7.91,3.45,7.69,3.37,7.38L3.28,7.03C3.18,6.64,2.8,6.41,2.41,6.51C2.03,6.6,1.8,6.99,1.89,7.38
                    l0.09,0.35C2.15,8.41,2.65,8.96,3.3,9.21v5.15c0,0.4,0.32,0.72,0.72,0.72h0.72c0.4,0,0.72-0.32,0.72-0.72v-2.58
                    c0.55,0.28,1.17,0.43,1.79,0.43c0.62,0,1.23-0.15,1.79-0.43v2.57c0,0.4,0.32,0.72,0.72,0.72h0.72c0.4,0,0.72-0.32,0.72-0.72v-1.9
                    L5.4,7.9H4.06z"/>
                <path d="M16.11,13.7l-4.94-3.9V9.35L9.03,8.11L2.55,2.99C2.31,2.81,1.98,2.86,1.8,3.09C1.63,3.32,1.67,3.64,1.89,3.82
                    l13.57,10.7c0.23,0.18,0.57,0.13,0.74-0.1C16.37,14.2,16.33,13.88,16.11,13.7L16.11,13.7z"/>
            </g>
        </svg>
    )
};

export const FirePlace:FunctionComponent = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 18 18" transform="scale(1.6)" >
            <g fill="var(--blue)">
                <polygon points="7.16,9.89 7.16,9.89 7.16,9.89 	"/>
                <path d="M9.52,11.37c-0.04,0.18-0.03,0.37,0.05,0.54c0.14,0.34,0.29,0.37,0.38,0.83C9.98,12.89,10,13.03,10,13.18
                    c0.2-0.25,0.31-0.56,0.32-0.89c0.14,0.14,0.26,0.31,0.34,0.49c0.21,0.38,0.23,0.83,0.05,1.23c0.86-0.27,1.19-0.96,1.03-2.02
                    c-0.06-0.56-0.31-1.08-0.7-1.48c-0.02,0.39-0.16,0.76-0.4,1.07c-0.22-1.8-1.09-3.22-2.03-3.38c0.08,0.33,0.05,0.68-0.09,0.99
                    c-0.26,0.63-0.54,0.67-0.71,1.52c-0.05,0.26-0.08,0.53-0.08,0.79c-0.37-0.46-0.57-1.03-0.58-1.61c-0.26,0.26-0.47,0.56-0.61,0.9
                    c-0.41,0.72-0.43,1.6-0.05,2.34c0.23,0.58,0.77,0.98,1.39,1.04c-0.1-0.23-0.12-0.48-0.08-0.72c0.04-0.31,0.17-0.59,0.38-0.81
                    c0.01,0.21,0.09,0.42,0.22,0.59C8.53,12.23,9.01,11.45,9.52,11.37z"/>
                <polygon points="15.93,4.58 15.93,3.84 2.07,3.84 2.07,4.57 2.64,5.14 15.39,5.14 	"/>
                <path d="M9,7.22c1.93,0,3.5,1.57,3.5,3.5v3.44h2.54V5.39H2.95v8.77H5.5v-3.45C5.5,8.79,7.07,7.22,9,7.22z"/>
            </g>
        </svg>
    )
};

export const NoFirePlace:FunctionComponent = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" transform="scale(1.5)" viewBox="0 0 18 18">
            <g fill="var(--blue)">
                <polygon fill="transparent" points="3.83,5.43 3.82,5.43 4.14,5.68 4.15,5.68 	"/>
                <path fill="transparent" d="M9,7.5c-0.25,0-0.49,0.03-0.73,0.08l4.22,3.33C12.44,9.02,10.9,7.5,9,7.5z"/>
                <polygon points="7.16,10.17 7.16,10.18 7.16,10.18 	"/>
                <path d="M5.5,14.45v-3.45c0-0.86,0.32-1.64,0.83-2.25L2.95,6.09v8.36H5.5z"/>
                <path d="M15.44,14.58c0.23,0.18,0.57,0.13,0.74-0.1c0.17-0.23,0.13-0.55-0.09-0.73l0,0l-1.05-0.83V5.68H5.86L5.54,5.43
                    h9.85l0.54-0.56V4.12H3.89L2.53,3.05C2.3,2.87,1.96,2.92,1.79,3.15C1.62,3.38,1.65,3.7,1.87,3.88L15.44,14.58z M9,7.5
                    c1.9,0,3.44,1.52,3.49,3.41L8.27,7.58C8.51,7.53,8.75,7.5,9,7.5z"/>
                <polygon points="12.5,14.45 13.55,14.45 12.5,13.63 	"/>
                <path d="M7.82,11c-0.05,0.26-0.08,0.53-0.08,0.79c-0.37-0.46-0.57-1.03-0.58-1.61c-0.26,0.26-0.47,0.56-0.61,0.9
                    c-0.41,0.72-0.43,1.6-0.05,2.34c0.23,0.58,0.77,0.98,1.39,1.04c-0.1-0.23-0.12-0.48-0.08-0.72c0.04-0.31,0.17-0.59,0.38-0.81
                    c0.01,0.21,0.09,0.42,0.22,0.59c0.12-0.99,0.6-1.77,1.11-1.86c-0.04,0.18-0.03,0.37,0.05,0.54c0.14,0.34,0.29,0.37,0.38,0.83
                    C9.98,13.17,10,13.32,10,13.46c0.2-0.25,0.31-0.56,0.32-0.89c0.14,0.14,0.26,0.31,0.34,0.49c0.21,0.38,0.23,0.83,0.05,1.23
                    c0.63-0.2,0.97-0.63,1.05-1.26l-3.63-2.86C8.01,10.36,7.9,10.59,7.82,11z"/>
            </g>
        </svg>
    )
};

export const DishWasher:FunctionComponent = ()=>{
    return(
        <svg  xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" transform="scale(1.5)" viewBox="0 0 18 18" >
            <g>
                <path fill="transparent" d="M9.58,7.87v5.68c0.02,0,0.04,0.01,0.06,0.01c0.61-0.03,1.09-0.54,1.08-1.16c0.01-1.12,0-2.25,0-3.37
                    c0.01-0.29-0.1-0.58-0.3-0.79C10.21,7.99,9.9,7.86,9.58,7.87z"/>
                <path fill="transparent" d="M4.44,6.71h9.12V4.44H4.44V6.71z M12.43,5.01c0.31,0,0.56,0.25,0.57,0.56c0,0,0,0.01,0,0.01
                    c0,0.31-0.26,0.57-0.57,0.57c-0.31,0-0.57-0.26-0.57-0.57C11.86,5.26,12.11,5.01,12.43,5.01z M10.71,5.01
                    c0.31-0.01,0.57,0.24,0.57,0.55c0,0,0,0.01,0,0.01c0,0.31-0.25,0.57-0.56,0.57c-0.31,0-0.57-0.25-0.57-0.56
                    C10.14,5.27,10.39,5.01,10.71,5.01z M8.99,5.01c0,0,0.01,0,0.01,0c0.31,0,0.57,0.25,0.57,0.57c0,0.31-0.26,0.57-0.57,0.57
                    c-0.31,0-0.57-0.25-0.57-0.57C8.43,5.26,8.68,5.01,8.99,5.01z"/>
                <path fill="transparent" d="M11.87,7.87L11.87,7.87l0,5.69c0.09-0.01,0.17-0.02,0.26-0.04c0.53-0.12,0.89-0.61,0.87-1.15
                    c0-0.62,0-1.25,0-1.87c0-0.53,0.01-1.06-0.01-1.59C12.95,8.32,12.46,7.86,11.87,7.87z"/>
                <path fill="transparent" d="M5.01,9.01c0,0.34,0,0.67,0,1.01C5,10.34,5.06,10.66,5.2,10.95c0.16,0.39,0.48,0.7,0.88,0.85
                    c0.11,0.03,0.23,0.06,0.34,0.07v0.84c-0.25,0.08-0.5,0.17-0.75,0.25c-0.04,0.01-0.1,0.04-0.1,0.07c-0.01,0.18,0,0.35,0,0.53h2.28
                    c0-0.16,0-0.31,0-0.46c0.01-0.06-0.03-0.12-0.09-0.13c0,0,0,0,0,0c-0.22-0.07-0.44-0.14-0.66-0.22C7.06,12.73,7,12.68,7,12.64
                    C7,12.39,7,12.13,7,11.9c0.21-0.06,0.42-0.15,0.62-0.25c0.37-0.24,0.63-0.61,0.73-1.03c0.09-0.53,0.12-1.08,0.08-1.62H5.01z"/>
                <path fill="var(--blue)" d="M14.3,3.54c-0.24-0.18-0.54-0.27-0.84-0.25c-2.97,0-5.95,0-8.92,0c-0.08,0-0.17,0-0.25,0.01
                    C3.99,3.33,3.72,3.48,3.54,3.7C3.36,3.95,3.27,4.24,3.29,4.54c0,2.97,0,5.94,0,8.91c0,0.08,0,0.17,0.01,0.25
                    c0.02,0.3,0.17,0.57,0.4,0.76c0.24,0.18,0.53,0.26,0.83,0.25c2.98,0,5.96,0,8.94,0c0.08,0,0.16,0,0.24-0.01
                    c0.29-0.03,0.56-0.17,0.75-0.39c0.18-0.24,0.27-0.53,0.25-0.83c0-1.49,0-2.98,0-4.47c0,0,0,0,0,0c0-1.47,0-2.94,0-4.41
                    c0-0.1,0-0.2-0.01-0.3C14.68,4,14.53,3.73,14.3,3.54z M8.35,10.62c-0.1,0.43-0.36,0.8-0.73,1.03c-0.2,0.1-0.41,0.18-0.62,0.25
                    c0,0.22,0,0.48,0,0.74c0,0.03,0.06,0.08,0.1,0.1c0.22,0.08,0.44,0.15,0.66,0.22c0,0,0,0,0,0c0.06,0.01,0.1,0.07,0.09,0.13
                    c0,0.15,0,0.3,0,0.46H5.58c0-0.17,0-0.35,0-0.53c0-0.02,0.06-0.06,0.1-0.07c0.25-0.09,0.5-0.17,0.75-0.25v-0.84
                    c-0.12-0.02-0.23-0.04-0.34-0.07c-0.4-0.15-0.72-0.45-0.88-0.85c-0.14-0.29-0.21-0.61-0.2-0.93c0-0.33,0-0.67,0-1.01h3.43
                    C8.46,9.55,8.44,10.09,8.35,10.62z M10.71,12.4c0.01,0.61-0.46,1.13-1.08,1.16c-0.02,0-0.04,0-0.06-0.01V7.87
                    c0.32-0.01,0.63,0.12,0.84,0.37c0.2,0.22,0.3,0.5,0.3,0.79C10.71,10.15,10.72,11.28,10.71,12.4z M12.99,12.37
                    c0.03,0.54-0.34,1.03-0.87,1.15c-0.09,0.02-0.17,0.03-0.26,0.04V7.87l0,0c0.59,0,1.08,0.45,1.12,1.04
                    c0.02,0.53,0.01,1.06,0.01,1.59C12.99,11.13,13,11.75,12.99,12.37z M13.56,6.71H4.44V4.44h9.12V6.71z"/>
                <path fill="var(--blue)" d="M9,6.14c0.31,0,0.57-0.25,0.57-0.57c0-0.31-0.25-0.57-0.57-0.57c0,0-0.01,0-0.01,0
                    c-0.31,0-0.56,0.26-0.56,0.57C8.43,5.89,8.69,6.14,9,6.14z"/>
                <path fill="var(--blue)" d="M10.72,6.14c0.31,0,0.56-0.26,0.56-0.57c0,0,0-0.01,0-0.01C11.27,5.25,11.02,5,10.71,5.01
                    c-0.31,0-0.56,0.26-0.56,0.57C10.15,5.89,10.41,6.14,10.72,6.14z"/>
                <path fill="var(--blue)" d="M12.42,6.14c0.31,0,0.57-0.25,0.57-0.57c0,0,0-0.01,0-0.01c0-0.31-0.26-0.56-0.57-0.56
                    c-0.31,0-0.57,0.25-0.57,0.57C11.86,5.89,12.11,6.14,12.42,6.14z"/>
            </g>
        </svg>
    )
};

export const NoDishWasher:FunctionComponent = ()=>{
    return(
        <svg  xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" transform="scale(1.5)" viewBox="0 0 18 18">
            <path fill="transparent" d="M10.71,9.35c0.01-0.29-0.1-0.58-0.3-0.79C10.21,8.32,9.9,8.18,9.58,8.19v0.42l1.14,0.9
                C10.71,9.46,10.71,9.4,10.71,9.35z"/>
            <path fill="transparent" d="M7.58,7.03h5.98V4.77H4.71L7.58,7.03z M12.43,5.33c0.31,0,0.56,0.25,0.57,0.56c0,0,0,0.01,0,0.01
                c0,0.31-0.26,0.57-0.57,0.57c-0.31,0-0.57-0.26-0.57-0.57C11.86,5.58,12.11,5.33,12.43,5.33z M10.71,5.33
                c0.31-0.01,0.57,0.24,0.57,0.55c0,0,0,0.01,0,0.01c0,0.31-0.25,0.57-0.56,0.57c-0.31,0-0.57-0.25-0.57-0.56
                C10.14,5.59,10.39,5.34,10.71,5.33z M8.99,5.33c0,0,0.01,0,0.01,0c0.31,0,0.57,0.26,0.57,0.57c0,0.31-0.26,0.57-0.57,0.57
                c-0.31,0-0.57-0.25-0.57-0.57C8.43,5.59,8.68,5.33,8.99,5.33z"/>
            <path fill="transparent" d="M12.99,10.83c0-0.53,0.01-1.06-0.01-1.59c-0.04-0.59-0.53-1.05-1.12-1.04l0,0v2.22l1.13,0.89
                C12.99,11.15,12.99,10.99,12.99,10.83z"/>
            <path fill="transparent" d="M5.01,10.34C5,10.66,5.06,10.98,5.2,11.27c0.16,0.39,0.48,0.7,0.88,0.85c0.11,0.03,0.23,0.06,0.34,0.07v0.84
                c-0.25,0.08-0.5,0.17-0.75,0.25c-0.04,0.01-0.1,0.04-0.1,0.07c-0.01,0.18,0,0.35,0,0.53h2.28c0-0.16,0-0.31,0-0.46
                c0.01-0.06-0.03-0.12-0.09-0.13c0,0,0,0,0,0c-0.22-0.07-0.44-0.14-0.66-0.22C7.06,13.05,7,13,7,12.97C7,12.71,7,12.45,7,12.23
                c0.21-0.06,0.42-0.15,0.62-0.25c0.37-0.24,0.63-0.61,0.73-1.03c0.03-0.19,0.04-0.38,0.06-0.56L7.08,9.33H5.01
                C5.01,9.67,5.01,10.01,5.01,10.34z"/>
            <path fill="transparent" d="M12.13,13.85c0.16-0.04,0.29-0.11,0.41-0.2l-0.67-0.53v0.77C11.95,13.88,12.04,13.87,12.13,13.85z"/>
            <path fill="transparent" d="M9.64,13.88c0.61-0.03,1.09-0.54,1.08-1.16c0-0.17,0-0.35,0-0.52l-1.14-0.9v2.57
                C9.6,13.88,9.62,13.88,9.64,13.88z"/>
            <polygon fill="transparent" points="14.69,13.99 14.71,14.01 14.71,14.01 14.69,13.99 "/>
            <g>
                <path fill="transparent" d="M9.58,8.19v0.42l1.14,0.9c0-0.05,0-0.1,0-0.15c0.01-0.29-0.1-0.58-0.3-0.79C10.21,8.32,9.9,8.18,9.58,8.19z"/>
                <path fill="transparent" d="M7.58,7.03h5.98V4.77H4.71L7.58,7.03z M12.43,5.33c0.31,0,0.56,0.25,0.57,0.56c0,0,0,0.01,0,0.01
                    c0,0.31-0.26,0.57-0.57,0.57c-0.31,0-0.57-0.26-0.57-0.57C11.86,5.58,12.11,5.33,12.43,5.33z M10.71,5.33
                    c0.31-0.01,0.57,0.24,0.57,0.55c0,0,0,0.01,0,0.01c0,0.31-0.25,0.57-0.56,0.57c-0.31,0-0.57-0.25-0.57-0.56
                    C10.14,5.59,10.39,5.34,10.71,5.33z M8.99,5.33c0,0,0.01,0,0.01,0c0.31,0,0.57,0.26,0.57,0.57c0,0.31-0.26,0.57-0.57,0.57
                    c-0.31,0-0.57-0.25-0.57-0.57C8.43,5.59,8.68,5.33,8.99,5.33z"/>
                <path fill="transparent" d="M11.87,8.19L11.87,8.19l0,2.23l1.13,0.89c0-0.16,0.01-1.54-0.01-2.07C12.95,8.65,12.46,8.19,11.87,8.19z"/>
                <path fill="var(--blue)" d="M7.08,9.33L3.29,6.35v7.43c0,0.08,0,0.17,0.01,0.25c0.02,0.3,0.17,0.57,0.4,0.76
                    c0.24,0.18,0.53,0.26,0.83,0.25c2.98,0,5.96,0,8.94,0c0.08,0,0.16,0,0.24-0.01c0.15-0.01,0.29-0.06,0.42-0.12l-1.59-1.25
                    c-0.12,0.09-0.26,0.17-0.41,0.2c-0.09,0.02-0.17,0.03-0.26,0.04v-0.77l-1.15-0.91c0,0.17,0,0.35,0,0.52
                    c0.01,0.61-0.46,1.13-1.08,1.16c-0.02,0-0.04,0-0.06-0.01v-2.57l-1.17-0.92c-0.02,0.19-0.03,0.38-0.06,0.56
                    c-0.1,0.43-0.36,0.8-0.73,1.03c-0.2,0.1-0.41,0.18-0.62,0.25c0,0.22,0,0.48,0,0.74c0,0.03,0.06,0.08,0.1,0.1
                    c0.22,0.08,0.44,0.15,0.66,0.22c0,0,0,0,0,0c0.06,0.01,0.1,0.07,0.09,0.13c0,0.15,0,0.3,0,0.46H5.58c0-0.17,0-0.35,0-0.53
                    c0-0.02,0.06-0.06,0.1-0.07c0.25-0.09,0.5-0.17,0.75-0.25v-0.84c-0.12-0.02-0.23-0.04-0.34-0.07c-0.4-0.15-0.72-0.45-0.88-0.85
                    c-0.14-0.29-0.21-0.61-0.2-0.93c0-0.33,0-0.67,0-1.01H7.08z"/>
                <path fill="var(--blue)" d="M16.1,13.76l-1.39-1.1l0,0c0-1.11,0-7.94-0.01-8.03c-0.02-0.3-0.17-0.57-0.4-0.76
                    c-0.24-0.18-0.54-0.27-0.84-0.25c0,0-9.09,0-9.18,0.01C4.04,3.65,3.83,3.77,3.65,3.93L2.53,3.05C2.3,2.87,1.96,2.92,1.79,3.15
                    C1.62,3.38,1.65,3.7,1.87,3.88l13.57,10.7c0.23,0.18,0.57,0.13,0.74-0.1C16.36,14.26,16.32,13.93,16.1,13.76L16.1,13.76z
                    M10.71,9.51l-1.14-0.9V8.19c0.32-0.01,0.63,0.12,0.84,0.37c0.2,0.22,0.3,0.5,0.3,0.79C10.71,9.4,10.71,9.46,10.71,9.51z
                    M12.99,11.31l-1.13-0.89V8.19l0,0c0.59,0,1.08,0.45,1.12,1.04C13,9.76,12.99,11.15,12.99,11.31z M13.56,7.03H7.58L4.71,4.77h8.85
                    V7.03z"/>
                <path fill="var(--blue)" d="M9,6.47c0.31,0,0.57-0.25,0.57-0.57c0-0.31-0.25-0.57-0.57-0.57c0,0-0.01,0-0.01,0
                    c-0.31,0-0.56,0.26-0.56,0.57C8.43,6.21,8.69,6.47,9,6.47z"/>
                <path fill="var(--blue)" d="M10.72,6.47c0.31,0,0.56-0.26,0.56-0.57c0,0,0-0.01,0-0.01c-0.01-0.31-0.26-0.56-0.57-0.55
                    c-0.31,0-0.56,0.26-0.56,0.57C10.15,6.22,10.41,6.47,10.72,6.47z"/>
                <path fill="var(--blue)" d="M12.42,6.47c0.31,0,0.57-0.25,0.57-0.57c0,0,0-0.01,0-0.01c0-0.31-0.26-0.56-0.57-0.56
                    c-0.31,0-0.57,0.25-0.57,0.57C11.86,6.21,12.11,6.46,12.42,6.47z"/>
            </g>
        </svg>

    )
};

export const Smoker:FunctionComponent = ()=>{
    return(
        <svg  xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" transform="scale(1.5)" viewBox="0 0 18 18">
            <g fill="var(--blue)">
                <path d="M13.96,10.78h-1.11c-0.1,0-0.18,0.08-0.18,0.18v3.31c0,0.1,0.08,0.18,0.18,0.18h1.11
                    c0.1,0,0.18-0.08,0.18-0.18v-3.31C14.14,10.86,14.06,10.78,13.96,10.78z"/>
                <path d="M16.16,10.78h-1.11c-0.1,0-0.18,0.08-0.18,0.18v3.31c0,0.1,0.08,0.18,0.18,0.18h1.11
                    c0.1,0,0.18-0.08,0.19-0.18v-3.31C16.35,10.86,16.27,10.78,16.16,10.78z"/>
                <path d="M12.85,6.79c-0.56-0.36-0.9-0.98-0.91-1.64V3.72c0-0.1-0.08-0.18-0.18-0.18h-1.11c-0.1,0-0.18,0.08-0.18,0.18
                    v1.53c0.03,1,0.55,1.91,1.39,2.45l0,0c0.52,0.36,0.83,0.1,0.82,0.73v0.69c0,0.1,0.08,0.18,0.18,0.18h1.11
                    c0.1,0,0.18-0.08,0.18-0.18V8.43C14.15,7.44,13.66,7.36,12.85,6.79z"/>
                <path d="M11.57,10.78H2.75c-0.61,0-1.11,0.49-1.11,1.11v1.47c0,0.61,0.49,1.11,1.11,1.11v0h8.81
                    c0.2,0,0.37-0.17,0.37-0.37v-2.94C11.94,10.95,11.77,10.78,11.57,10.78z"/>
                <path d="M14.35,5.54L14.35,5.54c-0.14-0.09-0.22-0.23-0.22-0.39V3.72c0-0.1-0.08-0.18-0.18-0.18h-1.11
                    c-0.1,0-0.18,0.08-0.18,0.18v1.43c-0.01,0.51,0.24,0.99,0.66,1.28c0.97,0.62,1.55,0.85,1.55,2v0.69c0,0.1,0.08,0.18,0.18,0.18h1.11
                    c0.1,0,0.18-0.08,0.18-0.18V8.43C16.35,6.93,15.6,6.38,14.35,5.54z"/>
            </g>
        </svg>
    )
};

export const NoSmoker:FunctionComponent = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" transform="scale(1.5)" viewBox="0 0 18 18">
            <g fill="var(--blue)">
                <path d="M15.45,15.54c0.23,0.18,0.57,0.13,0.74-0.1c0.17-0.23,0.13-0.55-0.09-0.73l0,0l-1.98-1.56
                    c0.01-0.02,0.01-0.04,0.01-0.06V9.78c0-0.1-0.08-0.18-0.18-0.18h-1.11c-0.1,0-0.18,0.08-0.18,0.18v2.21l-0.73-0.57V9.96
                    c0-0.2-0.16-0.37-0.37-0.37H9.63L2.55,4C2.31,3.83,1.98,3.87,1.8,4.1C1.63,4.33,1.67,4.65,1.89,4.84L15.45,15.54z"/>
                <path d="M2.75,13.27h8.17l-1.4-1.11L7.66,10.7l-1.4-1.11H2.75c-0.61,0-1.11,0.49-1.11,1.11v1.47
                    C1.65,12.78,2.14,13.27,2.75,13.27z"/>
                <path d="M11.85,6.52c0.52,0.36,0.83,0.1,0.82,0.73v0.69c0,0.1,0.08,0.18,0.18,0.18h0h1.11c0.1,0,0.18-0.08,0.18-0.18
                    V7.25c0-0.99-0.48-1.07-1.3-1.64c-0.56-0.36-0.9-0.98-0.91-1.64V2.54c0-0.1-0.08-0.18-0.18-0.18h-1.11c-0.1,0-0.18,0.08-0.18,0.18
                    v1.53C10.49,5.06,11.01,5.98,11.85,6.52z"/>
                <path d="M14.36,4.35L14.36,4.35L14.36,4.35c-0.14-0.08-0.22-0.23-0.22-0.39V2.54c0-0.1-0.08-0.18-0.18-0.18h-1.11
                    c-0.1,0-0.18,0.08-0.18,0.18v1.43c-0.01,0.51,0.24,0.99,0.66,1.28c0.97,0.62,1.55,0.85,1.55,2v0.69c0,0.1,0.08,0.18,0.18,0.18h1.11
                    c0.1,0,0.18-0.08,0.18-0.18V7.24C16.35,5.74,15.6,5.19,14.36,4.35z"/>
                <polygon points="16.16,9.59 16.16,9.59 16.16,9.59 	"/>
                <path d="M14.87,9.78v3.31c0,0.1,0.08,0.18,0.18,0.18h1.11c0.1,0,0.18-0.08,0.19-0.18V9.78c0-0.1-0.08-0.18-0.18-0.18
                    h-1.11C14.95,9.59,14.87,9.68,14.87,9.78z"/>
            </g>
        </svg>
    )
};

export const ShareIcones = ()=>{
    const intl = useIntl();
    const { baseUrl } = useAppConfig();
    const { currentUrl: url } = useLocaleUrl(baseUrl);

    const onCopy = () => navigator.clipboard.writeText(url).then();

    return(
        <div className="share-icones">
            <a href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`} target="_blank" className="share-icone" data-gloss={intl.formatMessage({id:"icone-share-facebook", defaultMessage: "Partager sur facebook"})} style={{backgroundColor:"#4968AD"}}>
                <FontAwesomeIcon icon={faFacebookF}/>
            </a>
            <a href={`whatsapp://send?text=${encodeURIComponent(url)}`} className="share-icone" data-gloss={intl.formatMessage({id:"icone-share-whattapp", defaultMessage: "Partager sur Whatsapp"})} style={{backgroundColor:"#65CF72"}}>
                <FontAwesomeIcon icon={faWhatsapp}/>
            </a>
            <a href={`mailto:?body=${encodeURIComponent(url)}`} className="share-icone primary" data-gloss={intl.formatMessage({id:"icone-share-email", defaultMessage: "Partager par email"})}>
                <FontAwesomeIcon icon={faEnvelope}/>
            </a>
          <button className="share-icone secondary"
                  data-gloss={intl.formatMessage({id:"icone-copy-clipboard", defaultMessage: "Copier dans le presse-papier"})}
                  onClick={onCopy}>
            <CopyPast />
          </button>
        </div>
    )
};

export const Laundry:FunctionComponent = ()=>{
    return(
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" height="1.1rem" viewBox="0 0 22.67 16.59">
            <g id="Groupe_1233" fill="var(--red)">
                <path id="Tracé_16038" className="st1" d="M0,11.09c0.06-0.28,0.1-0.56,0.17-0.84C0.75,8.04,2.73,6.5,5.01,6.49
                    c4.82-0.01,9.64-0.01,14.45,0c1.78,0.01,3.22,1.47,3.21,3.26c-0.01,1.59-1.18,2.94-2.75,3.17c-0.17,0.03-0.35,0.04-0.53,0.04
                    c-4.75,0-9.5,0-14.26,0c-0.72,0.04-1.36-0.48-1.47-1.19c-0.11-0.79,0.45-1.52,1.24-1.62c0.04-0.01,0.09-0.01,0.13-0.01
                    c0.1-0.01,0.21,0,0.31,0h13.9c0.39,0,0.59-0.14,0.58-0.41s-0.19-0.39-0.58-0.39c-4.69,0-9.37,0-14.06,0
                    c-1.14-0.05-2.14,0.76-2.33,1.89c-0.19,1.21,0.64,2.34,1.85,2.53c0.04,0.01,0.07,0.01,0.11,0.01c0.15,0.01,0.3,0.01,0.44,0.01
                    c5.24,0,10.48,0,15.72,0c0.53-0.05,1.05,0.17,1.38,0.59c0.33,0.4,0.39,0.96,0.16,1.42c-0.21,0.48-0.67,0.8-1.2,0.82
                    c-0.1,0.01-0.21,0.01-0.31,0.01H5.22c-2.63,0.06-4.87-1.89-5.19-4.49C0.03,12.06,0.02,12.02,0,11.97V11.09"/>
                <path id="Tracé_16039" className="st1" d="M22.09,6.67l-0.17-0.13c-0.72-0.57-1.61-0.88-2.52-0.87c-4.78,0-9.56,0-14.34,0
                    C3.49,5.65,1.98,6.28,0.89,7.41C0.86,7.44,0.83,7.47,0.79,7.5c0,0-0.01,0-0.05,0c0.09-0.43,0.18-0.85,0.27-1.27
                    c0.22-1.02,0.43-2.05,0.66-3.07C2.07,1.33,3.69,0.01,5.57,0C9.55-0.01,13.53,0,17.51,0.01c1.9,0.01,3.52,1.35,3.9,3.21
                    c0.24,1.1,0.46,2.21,0.68,3.31C22.09,6.58,22.09,6.63,22.09,6.67"/>
            </g>
        </svg>

    )
};

export const Charge:FunctionComponent = ()=>{
    return(
        <svg className="charge" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path fill="var(--primary)" d="m153.37,92.85c-4.71-4.63-9.32-9.35-14.04-13.96-1.06-1.04-1.46-2.11-1.45-3.57.06-6,.05-12,0-18-.03-3.76-2.75-6.49-6.33-6.51-3.65-.02-6.35,2.7-6.43,6.53-.04,1.8,0,3.6-.03,5.4,0,.41-.13.83-.24,1.49-.56-.49-.93-.79-1.26-1.12-3.77-3.76-7.53-7.53-11.3-11.29-.69-.69-1.4-1.37-2.19-1.94-7.11-5.1-16.3-4.36-22.49,1.82-14.25,14.23-28.48,28.47-42.72,42.71-.34.34-.68.7-.99,1.07-1.98,2.45-1.87,6.01.26,8.37,2.07,2.3,5.59,2.78,8.19,1.06.68-.45,1.27-1.05,1.85-1.63,13.98-13.97,27.95-27.94,41.93-41.91,2.71-2.71,4.81-2.7,7.53,0,2.07,2.06,4.14,4.14,6.21,6.2,12.04,12.04,24.08,24.07,36.11,36.12,1.94,1.95,4.19,2.77,6.85,1.92,2.56-.82,3.99-2.67,4.43-5.31.04-.26.15-.5.24-.75v-.23c-.39-2.79-2.24-4.63-4.13-6.49Z"/>
            <path fill="var(--primary)" d="m141.45,114.7c0-1.47-.45-2.67-1.51-3.73-12.4-12.37-24.77-24.76-37.16-37.13-2.04-2.04-3.7-2.05-5.72-.04-12.42,12.4-24.82,24.82-37.24,37.21-1,.99-1.46,2.14-1.46,3.52,0,8.92-.05,17.85.02,26.77.06,7.18,5.2,12.17,12.38,12.19l58.91-.05c1.64-.04,3.4-.41,4.87-1.11,4.72-2.22,6.86-6.18,6.9-11.31.04-4.35,0-8.7,0-13.05,0-4.42-.01-8.85,0-13.27Z"/>
            <path fill="var(--white)" d="m92.55,125.1c-1.1,0-2.2.03-3.3,0-1.93-.07-2.44-.95-1.48-2.61,2.42-4.22,12.17-20.94,14.59-25.11.08-.14.16-.28.25-.42.41-.67.83-1.42,1.79-1.13,1.04.32.94,1.23.84,2.04-.58,4.74-1.18,9.48-1.78,14.22-.17,1.38,0,1.6,1.37,1.61,2.08.01,4.16,0,6.24,0,2.39,0,2.85.76,1.66,2.81-4.22,7.28-14.04,24.15-14.73,25.31-.41.68-.86,1.4-1.82,1.05-.92-.34-.88-1.19-.79-1.98.5-4.09,1.02-8.19,1.53-12.28.06-.49.11-.97.17-1.46.24-2.02.22-2.04-1.86-2.04-.9,0-1.79,0-2.69,0,0,0,0,0,0-.01Z"/>
        </svg>
    );
}

export const CopyPast:FunctionComponent = ()=>{
    return(
        <svg version="1.1" width="1.2rem" height="1.2rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.98 18">
            <path fill="var(--white)" id="Shape" className="st0" d="M5.1,17.84c-0.9,0.01-1.64-0.71-1.65-1.61V4.98C3.46,4.08,4.2,3.36,5.1,3.37h9.09
                c0.9-0.01,1.64,0.71,1.65,1.61v11.26c-0.02,0.9-0.75,1.62-1.65,1.61H5.1z M0.14,13.02V1.76c0.02-0.9,0.75-1.62,1.65-1.61h9.92v1.61
                H1.79v11.26H0.14z"/>
        </svg>
    )
};

export const FilterSort:FunctionComponent = ()=>{
    return(
        <svg version="1.1" width="1.3rem" height="1.3rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.86 14.18">
            <g transform="translate(-698.812 -756.268)" fill="var(--white)">
                <path d="M705.42,756.56c1.56,0,3.11,0,4.67,0c0.5-0.03,0.98,0.19,1.27,0.59
                    c0.41,0.57,0.34,1.35-0.16,1.84c-0.79,0.89-1.6,1.78-2.4,2.66c-0.33,0.37-0.67,0.73-0.99,1.11c-0.09,0.11-0.14,0.25-0.15,0.39
                    c-0.01,1.89,0,3.79-0.02,5.69c0,0.28-0.08,0.56-0.22,0.8c-0.28,0.49-0.91,0.66-1.4,0.38c-0.05-0.03-0.1-0.06-0.14-0.1
                    c-0.79-0.56-1.55-1.15-2.32-1.74c-0.29-0.24-0.45-0.6-0.41-0.98c0.01-1.34,0-2.67,0.01-4.01c0-0.18-0.06-0.35-0.19-0.48
                    c-1.12-1.23-2.23-2.46-3.34-3.69c-0.27-0.27-0.43-0.63-0.43-1.01c-0.01-0.79,0.63-1.44,1.42-1.45c0.03,0,0.07,0,0.1,0
                    C702.29,756.56,703.85,756.56,705.42,756.56z"/>
                <path d="M713.89,764.47c-0.72,0-1.45,0-2.17,0c-0.46,0.04-0.9-0.21-1.09-0.63
                    c-0.28-0.55-0.05-1.23,0.5-1.51c0.18-0.09,0.38-0.13,0.58-0.12c1.46,0,2.93,0,4.39,0c0.09,0,0.19,0.01,0.28,0.03
                    c0.6,0.12,1,0.7,0.88,1.3c-0.09,0.44-0.43,0.79-0.88,0.88c-0.17,0.02-0.34,0.04-0.52,0.03
                    C715.21,764.48,714.55,764.47,713.89,764.47z"/>
                <path d="M713.89,770.14c-0.72,0-1.45,0-2.17,0c-0.46,0.05-0.9-0.21-1.09-0.63
                    c-0.28-0.55-0.06-1.23,0.5-1.51c0.19-0.09,0.4-0.14,0.61-0.12c1.45,0.01,2.89,0,4.34,0c0.1,0,0.2,0.01,0.3,0.03
                    c0.6,0.11,1,0.69,0.89,1.3c-0.08,0.45-0.44,0.81-0.9,0.89c-0.12,0.02-0.23,0.03-0.35,0.03
                    C715.31,770.14,714.6,770.14,713.89,770.14z"/>
                <path d="M715.04,758.78c-0.46,0-0.93,0.03-1.39-0.01c-0.6-0.14-0.97-0.73-0.83-1.33
                    c0.09-0.41,0.42-0.74,0.83-0.83c0.77-0.05,1.55-0.04,2.33-0.05c0.14,0,0.28,0.01,0.42,0.04c0.53,0.09,0.9,0.56,0.87,1.09
                    c0.04,0.54-0.35,1.01-0.88,1.08C715.94,758.8,715.49,758.8,715.04,758.78L715.04,758.78z"/>
            </g>
        </svg>
    )
};

