import CartForm from "../../models/cartForm";

type Action={
    type:string;
    value:any;
}

const emailChain = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const textChain = /[^a-zéàèüûúùöôõóòäêâîïëçñA-ZÂÀÁÄÃÜÛÚÙÊËÉÈÔÓÒÖÕîïíì\s0-9']/gmu;
const telChain = /[^+0-9]/gm;

export default function CartFormReducer(formData:CartForm, action:Action) {
    switch (action.type) {
        case ("copy") : {
            return(
                {
                    ...action.value
                }
            )
        }
        case ("name"):{
            if(textChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        name:action.value,
                        nameValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        name:action.value,
                        nameValid:true,
                    }
                )
            }
        };
        case ("surname"):{
            if(textChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        surname:action.value,
                        surnameValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        surname:action.value,
                        surnameValid:true
                    }
                )
            }
        };
        case ("email"):{
            if(!emailChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        email:action.value,
                        emailValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        email:action.value,
                        emailValid:true
                    }
                )
            }
        };
        case ("tel"):{
            if(telChain.test(action.value)){
                return(
                    {
                        ...formData,
                        tel:action.value,
                        telValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        tel:action.value,
                        telValid:true,
                    }
                )
            }
        };
        case ("street"):{
            if(textChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        street:action.value,
                        streetValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        street:action.value,
                        streetValid:true,
                    }
                )
            }
        };
        case ("streetNbr"):{
            return(
                {
                    ...formData,
                    streetNbr:action.value,
                }
            )
        };
        case ("complementaryAdress"):{
            return(
                {
                    ...formData,
                    complementaryAdress:action.value,
                }
            )
        };
        case ("npa"):{
            if(textChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        npa:action.value,
                        npaValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        npa:action.value,
                        npaValid:true,
                    }
                )
            }
        };
        case ("city"):{
            if(textChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        city:action.value,
                        cityValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        city:action.value,
                        cityValid:true,
                    }
                )
            }
        };
        case ("country"):{
            if(action.value===""){
                return(
                    {
                        ...formData,
                        country:action.value,
                        countryValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        country:action.value,
                        countryValid:true,
                    }
                )
            }
        };
        default:{
            throw Error('Unknown action: ' + action.type);
        };
    }
}
