import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import Banner from "../../components/banner";
import SeoHelmet from "../../components/seo-helmet";
import Section from "../../components/wrapper/section";

import "./links.scss";
import image from "../../assets/chalets-agence-.jpg";

const Links:FunctionComponent = ()=>{
    const intl = useIntl();

    return (
        <>
            <SeoHelmet
                title={intl.formatMessage({ id: "seo-title-links", defaultMessage: "Maytain | liens utiles à Morgins, Valais"})}
                description={intl.formatMessage({id:"seo-description-links", defaultMessage:"Vous souhaitez vivre ou passer des vacances dans les Alpes suisses à Morgins ? Nous mettons quelques liens à votre disposition."})}
                canonical="/liens"
                image={image}
            />
            <Banner title={intl.formatMessage({id:"links-title",  defaultMessage: "Liens utiles"})}/>
            <Section
            title={intl.formatMessage({ id: "section-links-title", defaultMessage: "Quelques liens utiles"})}
            subtitle={intl.formatMessage({ id: "links", defaultMessage: "Liens"})}
            >
                <ul className="link-list">
                    <li>
                        <a target="_blank" href="https://www.regiondentsdumidi.ch/fr/destination/6-villages-stations/morgins-121/">
                            {intl.formatMessage({ id:"links-btn-discover-morgins", defaultMessage: "Découvrir Morgins"})}
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.regiondentsdumidi.ch/fr/webcams-149/">
                            {intl.formatMessage({ id:"links-btn-webcams", defaultMessage: "Les webcams en direct"})}
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.regiondentsdumidi.ch/" >
                            {intl.formatMessage({ id:"links-btn-tourism-agency", defaultMessage: "Office du Tourisme Région Dents du Midi"})}
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.skipass-pds-ch.ch/" >
                            {intl.formatMessage({ id:"links-btn-porte-du-soleil", defaultMessage: "Les Portes du Soleil : 2 pays, 1 seul pass"})}
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://tpc.ch/" >
                            {intl.formatMessage({ id:"links-btn-tpc", defaultMessage: "Les transports depuis et vers Morgins"})}
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.meteosuisse.admin.ch/previsions-locales/morgins/1875.html#forecast-tab=detail-view">
                            {intl.formatMessage({ id:"links-btn-weather", defaultMessage: "Les prévisions météo"})}
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.troistorrents.ch/" >
                            {intl.formatMessage({ id:"links-btn-municipality", defaultMessage: "La commune de Troistorrents"})}
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.regiondentsdumidi.ch/fr/activites/nos-incontournables/nos-incontournables-de-l-ete/multi-pass-19387/" >
                            {intl.formatMessage({ id:"links-btn-multipass", defaultMessage: "Tout savoir sur le Multi Pass"})}
                        </a>
                    </li>
                </ul>
            </Section>
        </>
    )
}

export default Links
