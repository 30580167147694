import { useEffect, useState } from "react"
import useIsHomePage from "../hooks/useIsHomepage";
import { useLocation } from "react-router"


const useShowSearchModule = ()=>{
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const isHomepage = useIsHomePage();
    const location = useLocation()


    useEffect(()=>{
        if(isHomepage){
            setIsVisible(true)
        }else if(location.pathname==="/fr/location/semaine" || location.pathname==="/en/location/weekly"){
            setIsVisible(true)
        }else{
            setIsVisible(false)
        }
    },[location])

    return isVisible
}

export default useShowSearchModule
