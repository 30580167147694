import { SearchData } from "../models/searchData";
import moment from "moment/moment";

export const generateSearchArticleParamData = (data: SearchData) => {
  let params:any[]=[]
  let minGuests = data.person.adult+data.person.child+data.person.exempt
  !!data.price && params.push(["price",data.price.join("-")]);
  !!data.roomsRange&& params.push(["roomsRange",data.roomsRange.join("-")]);
  data.minSurface>0&&params.push(["minSurface",data.minSurface])
  minGuests!=0&&params.push(["minGuests",minGuests])
  data.stay.from!=undefined&&params.push(["from",moment(data.stay.from).format("YYYY-MM-DD")])
  data.stay.to!=undefined&&params.push(["to",moment(data.stay.to).format("YYYY-MM-DD")])
  data.typeOfArticle.length>0&&params.push(["objectTypes",data.typeOfArticle.join("-")])
  data.filters.map((e:any) => params.push([e,"true"]))
  data.name && params.push(["name", data.name])
  return params
}

export const generateSearchArticleURL = (data: any) => data.map((a:any)=>a.join("=")).join("&");
