export enum ArticleType {
  RENTAL = "RentalAccommodationSummary",
  SEASONAL = "SeasonalAccommodationSummary",
  ANNUAL = "AnnualAccommodationSummary",
  SALE = "SaleAccommodationSummary"
}

export const buildArticleUrl = (id: string, type: ArticleType | "weekly" | "seasonal" | "annual" | "sale"): string => {
  switch(type) {
    case ArticleType.RENTAL:
    case "weekly":
      return `/louer/semaine/${id}`;
    case ArticleType.SEASONAL:
    case "seasonal":
      return `/louer/saison/${id}`;
    case ArticleType.ANNUAL:
    case "annual":
      return `/louer/annee/${id}`;
    case ArticleType.SALE:
    case "sale":
      return `/acheter/${id}`;
  }
}
