import loadable from "@loadable/component";

loadable.lib(
  () => import(/* webpackChunkName: "client-ui" */"@use-cookie-consent/react"),
  { ssr: false });

export const RGPDConsentProvider = loadable(
  () => import(/* webpackChunkName: "client-ui" */"@use-cookie-consent/react"),
  { ssr: false, resolveComponent: module => module.CookieConsentProvider });

export const RGPDConsentProcessor = loadable(
  () => import(/* webpackChunkName: "client-ui" */"./cookie-consent-provider"),
  { ssr: false });

export const RGPDBanner = loadable(
  () => import(/* webpackChunkName: "client-ui" */"./cookie-banner"),
  { ssr: false });
