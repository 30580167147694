import React, { FunctionComponent, MouseEvent, ReactNode, useRef } from "react"
import gsap from "gsap"
import "./subInput.scss"
import { classNames } from "@ct-react/core"
import { useIntl } from "react-intl";
import { commonDefs } from "../../i18n";

type Props={
    children:ReactNode,
    title:string | ReactNode,
    type:string,
    onReset:Function,
    modulePosition:"down"|"up",
    className?:string
}


const SubInput:FunctionComponent<Props> = ({type,title,onReset,children,modulePosition,className})=>{
    const reset = (e:MouseEvent)=>{
        onReset(e.currentTarget.id);
    };
    const intl = useIntl();
    const inputRef = useRef(null);

    const hide = ()=>{
        if(inputRef){
            gsap.to(inputRef.current,{autoAlpha:0,y:"-10%",duration:0,});
            gsap.to(inputRef.current,{autoAlpha:0,y:"0%",duration:0,delay:1,clearProps: "y,visibility,opacity"});
        }
    };
    const subInputPosition = modulePosition==="down"? {bottom:"126%",top:"inherit"}:{top:"126%",bottom:"inherit"};
    const arrowPosition = modulePosition==="down"? {bottom:"-0.6vw",top:"inherit"}:{top:"-0.6vw",bottom:"inherit"};
    const hideArrowPosition = modulePosition==="down"? {bottom:"0vw",top:"inherit"}:{top:"0vw",bottom:"inherit"};

    return(
    <div className={classNames("subInput",className)} style={subInputPosition} ref={inputRef}>
        <div className="subInput-arrow" style={arrowPosition}></div>
        <div className="subInput-hideArrow" style={hideArrowPosition}></div>
        <h5 className="subInput-title">{title}</h5>
        <div className="subInput-inputs">
            {children}
        </div>
        <div className="subInput-validation">
        <p id={type+"Reset"} onClick={reset} className="bold-black">{intl.formatMessage(commonDefs.toReset)}</p>
        <p className="bold-red" onClick={hide} >{intl.formatMessage(commonDefs.toValidate)}</p>
        </div>
    </div>
    )
}

export default SubInput
