import {gql} from "@apollo/client";

//COMMUN
export const IMAGE_FIELDS = gql`
  fragment ImageFields on Image {
    isMain
    medium
    large
    extraLarge
    original
  }
`;

export const LOCATION_FIELDS = gql`
  fragment LocationFields on Location {
    street,
    streetComplement,
    zip,
    city,
    state,
    country,
    resume
  }
`;

export const PRICE_FIELDS = gql`
  fragment PriceField on Price {
    currency
    amount
  }
`;

export const SEO_ARTICLE = gql`
  query SeoArticle($id:ID!){
    articleSEO(articleId: $id) {
      title {
        value
        matchRequestedLang
      }
      description {
        value
        matchRequestedLang
      }
      image
      keywords
      translatableKeywords {
        key
        value
      }
    }
  }
`

//SALE

export const SALE_FEATURES_FIELDS = gql`
  fragment SaleFeaturesFields on SaleAccommodationFeatures {
    rooms
    bedrooms
    livingSpace
    parkings
    bathrooms
    charges {amount, currency}
    renovationFund {amount, currency}
    isSold
  }
`;

export const SALE_FEATURES_ALL_FIELDS = gql`
  ${PRICE_FIELDS}
  fragment SaleFeaturesAllFields on SaleAccommodationFeatures {
    __typename
    totalSpace
    livingSpace
    rooms
    bedrooms
    bathrooms
    restrooms
    parkings
    heatings
    orientation
    withDishwasher
    withWashingMachine
    withFireplace
    withPool
    withSauna
    withLift
    withParking
    withGarage
    withBalcony
    withTerrace
    withOutdoorSpace
    isNew
    isFurnished
    isSellableToForeigners
    charges {
      ...PriceField
    }
    workingCapitalFund {
      ...PriceField
    }
    renovationFund {
      ...PriceField
    }
  }
`;

//RENT WEEKLY
export const RENT_FEATURES_FIELDS = gql`
  fragment RentalFeaturesFields on RentalAccommodationFeatures {
    rooms
    bedrooms
    livingSpace
    maxGuests
    withParking
    withDishwasher
    withFireplace
    withWIFI
    withTV
    isSmokerCompliant
    isPetCompliant
  }
`;

export const RENT_FEATURES_ALL_FIELDS = gql`
  fragment RentalFeaturesAllFields on RentalAccommodationFeatures {
    totalSpace
    livingSpace
    rooms
    bedrooms
    bathrooms
    restrooms
    parkings
    orientation
    withDishwasher
    withWashingMachine
    withFireplace
    withPool
    withSauna
    withLift
    withParking
    withGarage
    withBalcony
    withTerrace
    withOutdoorSpace
    maxGuests
    withWIFI
    withTV
    withSmokeAlarm
    withCarbonAlarm
    isSmokerCompliant
    isPetCompliant
    isDisabledPersonCompliant
  }
`;

//RENT SEASONLY

export const SEASONAL_FEATURES_FIELDS = gql`
  fragment SeasonalFeaturesFields on SeasonalAccommodationFeatures {
    rooms
    bedrooms
    livingSpace
    withDishwasher
    withWashingMachine
    withFireplace
    withParking
    withWIFI
    withTV
    isPetCompliant
  }
`;

export const SEASONAL_FEATURES_ALL_FIELDS = gql`
  fragment SeasonalFeaturesAllFields on SeasonalAccommodationFeatures {
    totalSpace
    livingSpace
    rooms
    bedrooms
    bathrooms
    restrooms
    parkings
    orientation
    withDishwasher
    withWashingMachine
    withFireplace
    withPool
    withSauna
    withLift
    withParking
    withGarage
    withBalcony
    withTerrace
    withOutdoorSpace
    withWIFI
    withTV
  }
`;

export const SEASONAL_PRICE = gql`
  fragment SeasonalPrice on SeasonalPrice {
    value {
      amount,
      currency
    },
    isMonthlyPrice,
    period
  }
`;

//RENT ANNUALY

export const ANNUAL_FEATURES_FIELDS = gql `
  fragment AnnualFeaturesFields on AnnualAccommodationFeatures {
    rooms
    bedrooms
    livingSpace
    withDishwasher
    withWashingMachine
    withFireplace
    withParking
  }
`;

export const ANNUAL_FEATURES_ALL_FIELDS = gql `
  fragment AnnualFeaturesAllFields on AnnualAccommodationFeatures {
    totalSpace
    livingSpace
    rooms
    bedrooms
    bathrooms
    restrooms
    parkings
    orientation
    withDishwasher
    withWashingMachine
    withFireplace
    withPool
    withSauna
    withLift
    withParking
    withGarage
    withBalcony
    withTerrace
    withOutdoorSpace
    isNew
    isFurnished
  }
`;

//ADD TO CART

export const CART_PRICE_FIELDS = gql`
  fragment CartPriceFields on CartPrice {
    currency
    amount
    discount
    downPayment
  }
`;

export const CART_ITEM_PRICE_FIELDS = gql`
  fragment CartItemPriceFields on CartItemPrice {
    currency
    amount
    discount
    downPayment
    original
    options
    optionDetails {
      optionType
      amount
    }
  }
`;

export const CART_ITEM_FIELDS = gql`
  ${IMAGE_FIELDS}
  ${CART_ITEM_PRICE_FIELDS}
  fragment CartItemFields on RentalAccommodationCartItem {
    id
    title
    description
    expirationDate
    image { ...ImageFields }
    price { ...CartItemPriceFields }
    checkIn
    checkOut
  }
`;

//CART

export const CART_BADGE_GQL_DATA = gql`
  query CartBadge($sessionId: String, $userId: String) {
    cart(sessionId: $sessionId, userId: $userId) { id itemsCount }
  }
`;

export const FULL_CART_GQL_DATA = gql`
  ${CART_ITEM_FIELDS}
  ${CART_PRICE_FIELDS}
  query UserCart($sessionId: String, $userId: String) {
    cart(sessionId: $sessionId, userId: $userId) {
      id items { ...CartItemFields } itemsCount fullPrice { ...CartPriceFields }
    }
  }
`;
