import React, { FunctionComponent } from "react";
import Banner from "../../components/banner";
import Section from "../../components/wrapper/section";
import TwoColumn from "../../components/wrapper/twoColum";
import { useIntl } from "react-intl";
import SeoHelmet from "../../components/seo-helmet";

import "./managment.scss";

import image from "../../assets/ancienne-douane.jpg";

const Managment:FunctionComponent = ()=>{
    const intl = useIntl();

    return (
        <>
            <SeoHelmet
                title={intl.formatMessage({ id: "seo-title-management", defaultMessage: "Maytain | Gestion de vos biens immobiliers à Morgins"})}
                description={intl.formatMessage({id:"seo-description-management", defaultMessage:"Apprenez comment nous gérons vos résidences à la location. Entretien, sécurité et administration, nous ne laissons rien au hasard!"})}
                canonical="/gerance"
                image={image}
            />
            <Banner title={intl.formatMessage({id:"managment",  defaultMessage: "Gérance"})} />
            <Section className="managment">
                <TwoColumn
                imageSrc={image}
                alt={intl.formatMessage({ id: "img-text-alt-4", defaultMessage: "Ancienne cabane de douaniers, construite en 1928 au col des Portes de Culet."})}
                revert={false}
                title={intl.formatMessage({id:"managment-text1", defaultMessage: "Nous nous occupons de la gérance"})}
                subtitle={intl.formatMessage({id:"managment-text2", defaultMessage: "Gérance à morgins"})}
                >
                    <p>
                    {intl.formatMessage({id:"managment-text3", defaultMessage: "Nous nous occupons de la gérance de votre appartement ou chalet de A à Z avec rigueur et compétence. A tout moment vous pourrez réclamer un état clair et précis de votre situation financière."})}
                    </p>
                    <br/>
                    <br/>
                    <ul className="managment-list">
                        <li>
                            {intl.formatMessage({id:"managment-text4", defaultMessage: "Nous payons pour votre compte l’électricité, les impôts, les charges de l’immeuble, etc…"})}
                        </li>
                        <li>
                            {intl.formatMessage({id:"managment-text5", defaultMessage: "Nous planifions le nettoyage de votre logement."})}
                        </li>
                        <li>
                            {intl.formatMessage({id:"managment-text6", defaultMessage: "Nous allumons le chauffage avant votre arrivée, nous faisons le plein de mazout quand il est nécessaire."})}
                        </li>
                        <li>
                            {intl.formatMessage({id:"managment-text7", defaultMessage: "Nous résolvons vos problèmes techniques."})}
                        </li>
                        <li>
                            {intl.formatMessage({id:"managment-text8", defaultMessage: "Nous vous conseillons sur les entreprises locales pour vos rénovations, réparations etc…"})}
                        </li>
                        <li>
                            {intl.formatMessage({id:"managment-text9", defaultMessage: "Nous conservons vos clés en dépôt en cas d’oubli ou pour une entreprise."})}
                        </li>
                    </ul>
                </TwoColumn>
            </Section>
        </>
    )
}

export default Managment
