import React, { FunctionComponent, useState } from "react";
import Banner from "../../components/banner";
import { CardPost, CardPostSkeleton } from "../../components/card";
import { gql, useQuery } from "@apollo/client";
import Post from "../../models/post";
import { Outlet, useOutletContext } from "react-router";

import "./blog.scss";
import { useMemo } from "react";

const BLOG_QUERY = gql`
    query getPost($template : UUID!){
        cmsPages(templateId: $template) {
        id
        publishedDate
        data
        }
    }
`;

const Blog:FunctionComponent = ()=>{
    const [posts, setPosts] = useState<Post[] | null>(null);

    const {data, loading} = useQuery(BLOG_QUERY, {
        variables : {
            template:"03e8bb2b-0fa2-40e6-8d48-5b39a16b4a54"
        },
        onCompleted:(data:any)=>setPosts(data.cmsPages)
    })

    return (
        <>
            <Banner title="Blog"/>
            <section className="blog">
                <Outlet context={{posts,loading}}/>
            </section>
        </>
    )
}

export const Posts:FunctionComponent = ()=>{
    const {posts, loading}= useOutletContext<any>();
    const skeletonCard = ()=>{
        let cards = []
        for (let i = 0; i < 8; i++) {
             cards.push(<CardPostSkeleton key={i}/>)
        }
        return cards
    };

    const renderPost = useMemo(()=>{
        if(!!posts) return posts.map((post:any)=> <CardPost key={post.id} post={post}/>)
    },[posts,loading])

    return (
        <>
            {loading && skeletonCard()}
            { renderPost }
        </>
    )
}

export default Blog;
