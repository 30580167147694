import React, { FunctionComponent, ReactNode, useContext } from "react";
import { useIntl } from "react-intl";
import { classNames } from "@ct-react/core";
import { LocaleNavLink } from "@ct-react/locale";
import { LayoutContext } from "../../contexts/layout";
import { MenuContext } from "../../contexts/menu";
import Submenu from "./submenu";
import useIsHomePage from "../../hooks/useIsHomepage";
import BREAKPOINTS from "../../utils/breakpoints";
import { commonDefs } from "../../i18n";
type SubNavLinkProps = {
    classnames:string,
    to:string,
    click:()=>void,
    children : ReactNode
}

const SubNavLink:FunctionComponent<SubNavLinkProps> = ({classnames = "",to="/",click = ():void=>{},children})=>{
    return(
        <li className={classnames} onClick={()=>click()}>
            <LocaleNavLink to={to}>
                {children}
            </LocaleNavLink>
        </li>
    );
}

const Navigation:FunctionComponent = ()=>{
    const intl = useIntl();
    const layout = useContext(LayoutContext);
    const { isDown,setIsDown } = useContext(MenuContext);
    const isHomepage:boolean = useIsHomePage();

    const color = isHomepage?"whiteBorder":"";
    const whiteHover = isHomepage?"":"whiteHover";

    return(
        <ul className={classNames("navigation",{hide:!isDown && window.innerWidth<BREAKPOINTS.desktop, show:isDown, isHome : isHomepage})}>
            <Submenu link={intl.formatMessage(commonDefs.toRent)}>
                <SubNavLink classnames={whiteHover} click={()=>setIsDown(false)} to="louer/semaine">
                    {intl.formatMessage({id:"weekly-rental", defaultMessage: "Location à la semaine" })}
                </SubNavLink>
                <SubNavLink classnames={whiteHover} click={()=>setIsDown(false)} to="louer/saison">
                {intl.formatMessage({id:"seasonal-rental", defaultMessage: "Location à la saison" })}
                </SubNavLink>
                <SubNavLink classnames={whiteHover} click={()=>setIsDown(false)} to="louer/annee">
                {intl.formatMessage({id:"annual-rental", defaultMessage: "Location à l'année" })}
                </SubNavLink>
            </Submenu>
            <li className="navigation-link">
                <LocaleNavLink to={"acheter"} className={color} onClick={()=>setIsDown(false)}>
                    {intl.formatMessage(commonDefs.toBuy)}
                </LocaleNavLink>
            </li>
            <Submenu link={intl.formatMessage({id:"service", defaultMessage: "Services"})}>
                <SubNavLink classnames={whiteHover} click={()=>setIsDown(false)} to="administration">
                    {intl.formatMessage({id:"admin", defaultMessage: "Administration"})}
                </SubNavLink>
                <SubNavLink classnames={whiteHover} click={()=>setIsDown(false)} to="gerance">
                    {intl.formatMessage({id:"managment", defaultMessage: "Gérance"})}
                </SubNavLink>
                <SubNavLink classnames={whiteHover} click={()=>setIsDown(false)} to="mettre-en-location">
                    {intl.formatMessage({id:"putOnRent", defaultMessage: "Mettre en location"})}
                </SubNavLink>
                <SubNavLink classnames={whiteHover} click={()=>setIsDown(false)} to="mettre-en-vente">
                    {intl.formatMessage(commonDefs.putSale)}
                </SubNavLink>
            </Submenu>
            <Submenu link={intl.formatMessage({ id:"agency", defaultMessage: "Agence"})}>
                <SubNavLink classnames={whiteHover} click={()=>setIsDown(false)} to="a-propos">
                    {intl.formatMessage({id:"about", defaultMessage: "À propos" })}
                </SubNavLink>
                {/* <SubNavLink classnames={whiteHover} click={()=>setIsDown(false)} to="galerie">
                    {intl.formatMessage({id:"gallery", defaultMessage: "Galerie" })}
                </SubNavLink>
                <SubNavLink classnames={whiteHover} click={()=>setIsDown(false)} to="blog">
                    {intl.formatMessage({id:"blog", defaultMessage: "Blog" })}
                </SubNavLink> */}
                <SubNavLink classnames={whiteHover} click={()=>setIsDown(false)} to="liens">
                    {intl.formatMessage({id:"links", defaultMessage: "Liens" })}
                </SubNavLink>
            </Submenu>
            <li className="navigation-link">
                <LocaleNavLink to={"contact"} className={color} onClick={()=>setIsDown(false)}>
                    {intl.formatMessage({id:"contact",defaultMessage: "Contact" })}
                </LocaleNavLink>
            </li>
        </ul>
    )
}

export default Navigation
