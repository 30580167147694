import React, { FunctionComponent, useEffect, useState } from "react";
import { classNames } from "@ct-react/core";
import Language from "./language";
import { BasketIcone, ProfilIcone, } from "./navigation-icone";
import useIsHomePage from "../../hooks/useIsHomepage";

import "./navigation.scss"
import { useLocation } from "react-router";

const MenuBanderole:FunctionComponent = ()=>{
    const isHomepage:boolean = useIsHomePage();
    const location = useLocation();
    const [email, setEmail] = useState('info@maytain.com');

    useEffect(() => {
        if(location.pathname.includes("location")){
            setEmail("location@maytain.com");
        }else if(location.pathname.includes("vente")){
            setEmail("camille@maytain.com");
        }else if(location.pathname.includes("administration")){
            setEmail("administrations@maytain.com");
        }else{
            setEmail("info@maytain.com")
        }
    }, [location]);

    return(
        <div className={classNames("navigation-banderole",{primary:isHomepage, secondary:!isHomepage})}>
            <div>
                <div className="banderole-contact">
                    <a href="tel:+41 24 477 24 21">
                        <svg xmlns="http://www.w3.org/2000/svg" width="0.684rem" viewBox="0 0 16.969 17.001">
                            <path d="M18.08,13.979v2.409a1.606,1.606,0,0,1-1.75,1.606A15.889,15.889,0,0,1,9.4,15.529a15.656,15.656,0,0,1-4.817-4.817A15.889,15.889,0,0,1,2.118,3.75,1.606,1.606,0,0,1,3.716,2H6.125A1.606,1.606,0,0,1,7.731,3.381a10.309,10.309,0,0,0,.562,2.256,1.606,1.606,0,0,1-.361,1.694l-1.02,1.02a12.846,12.846,0,0,0,4.817,4.817l1.02-1.02a1.606,1.606,0,0,1,1.694-.361,10.309,10.309,0,0,0,2.256.562,1.606,1.606,0,0,1,1.381,1.63Z" transform="translate(-1.611 -1.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                        </svg>
                        +41 24 477 24 21
                    </a>
                    <a href={`mailto:${email}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="0.705rem" viewBox="0 0 17.381 13.791">
                        <path d="M3.6,4h12.79a1.6,1.6,0,0,1,1.6,1.6v9.593a1.6,1.6,0,0,1-1.6,1.6H3.6a1.6,1.6,0,0,1-1.6-1.6V5.6A1.6,1.6,0,0,1,3.6,4Z" transform="translate(-1.303 -3.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                        <path d="M17.988,6,9.994,11.6,2,6" transform="translate(-1.303 -3.901)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                    </svg>
                        {email}
                    </a>
                    <a href="https://www.google.com/maps/place/Agence+Immobili%C3%A8re+Maytain+S%C3%A0rl/@46.2376377,6.8552231,17z/data=!3m1!4b1!4m6!3m5!1s0x478ea388bf572a4f:0xbfe4352c0e91ebb5!8m2!3d46.237634!4d6.857798!16s%2Fg%2F1tg14n5g?entry=ttu" target="__blank">
                        <svg className="" xmlns="http://www.w3.org/2000/svg" width="0.672rem" viewBox="0 0 16.734 20.23">
                            <g transform="translate(-2.5 -0.5)">
                                <path d="M18.734,8.867c0,6.119-7.867,11.363-7.867,11.363S3,14.986,3,8.867a7.867,7.867,0,0,1,15.734,0Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                                <circle cx="2.5" cy="2.5" r="2.5" transform="translate(8.45 6)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                            </g>
                        </svg>
                        Rte du Village 15,1875 Morgins
                    </a>
                </div>
                <div className="banderole-icones">
                    <ProfilIcone/>
                    <BasketIcone/>
                    <div>
                        <Language/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuBanderole
