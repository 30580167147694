import React, { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useCartData } from "./cart";
import { RentalAccomodationCartItem } from "../../models/cart";
import CartItem, { Skeletonitem } from "../../components/cart/cart-item";
import { gql, useMutation } from "@apollo/client";
import gsap from "gsap";
import { CART_PRICE_FIELDS } from "@shared/fragments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faXmark } from "@fortawesome/free-solid-svg-icons";
import { LayoutContext } from "../../contexts/layout";
import EmptyCart from "../../components/cart/empty-cart";
import { PrimaryButton } from "../../components/button";
import { useNavigate } from "@ct-react/locale";
import "./cart-items.scss";
import Loader from "../../components/loader";

const REMOVE_CART_ITEM_GQL_MUT = gql`
  ${CART_PRICE_FIELDS}
  mutation RemoveFromCart($sessionId: String, $userId: String, $itemId: ID!) {
    removeCartItem: cartRemoveItem(sessionId: $sessionId, userId: $userId, itemId: $itemId) {
      cartId itemId fullPrice { ...CartPriceFields }
    }
  }
`;

const CartItems:FunctionComponent = ()=>{
    const intl = useIntl();
    const navigate = useNavigate();
    const  layout  = useContext(LayoutContext);
    const [priceIsVisible, setPriceIsVisible] = useState<boolean>(false);
    const [cartData, setCardData] = useCartData();
    const [idToRemove, setIdToRemove] = useState<string | null>(null);
    const grid = useRef<HTMLDivElement>(null);


    const [ removeFromCart, { loading: actionLoading } ] = useMutation(REMOVE_CART_ITEM_GQL_MUT, {
        refetchQueries: [ "CartBadge", "UserCart" ],
        awaitRefetchQueries: true
    });

    const onRemove = (itemId:string)=>{
        setIdToRemove(itemId);
    }

    const remove = (itemId:string)=>{
        const variables = { sessionId : cartData.sessionId , userId :  cartData.userId, itemId };
        //Firt show pop up
        removeFromCart({ variables })
        .then(()=>{
            document.body.style.overflow = "initial";
            setIdToRemove(null);
        })
        .finally(()=>{
            document.body.style.overflow = "initial";
            setIdToRemove(null);
        });
    };

    const nextStep = ()=>{
        if(!!cartData.userId) navigate("/checkout/information");
        if(!cartData.userId) navigate("/checkout/connexion");
    };
    const closeWindow = ()=>{
        document.body.style.overflow = "initial";
        setIdToRemove(null);
    }

    const renderCart = useMemo(()=>{
        if(cartData.loading) return <Skeletonitem/>;
        if(!cartData.loading && (cartData.items === null ||  cartData.items!.itemsCount === 0)) return <EmptyCart/>;
        if(!cartData.loading && !!cartData.items &&  cartData.items.itemsCount>0) return  cartData.items.items.map((item:RentalAccomodationCartItem)=> <CartItem key={item.id} item={item} onRemove={onRemove}/> )
        return <Skeletonitem/>
    },[cartData.items, cartData.loading]);

    const renderPrice = useMemo(()=>{
        if(!! cartData.items &&  cartData.items.itemsCount>0) return(
            <div className="cart-price" onClick={()=>setPriceIsVisible(!priceIsVisible)}>
                <h5>
                    {intl.formatMessage({id:"cart-items-total-price",defaultMessage : "Récapitulatif"})}
                    {layout.includes("smartphone")&&!priceIsVisible?
                    <FontAwesomeIcon icon={faChevronDown}/>
                    :
                    <FontAwesomeIcon icon={faChevronUp}/>
                    }
                </h5>
                {cartData.items.items.map((item:any)=>{
                    return(
                        <div key={item.id}>
                            <p className="bold-black">{item.title}</p>
                            <p className="price">
                                {item.price.amount != item.price.downPayment ? "Acompte" : "Total"}
                                <span>
                                    {item.price.downPayment} {item.price.currency}
                                </span>
                            </p>
                        </div>
                    )
                })}
                <div className="cart-price-total">
                    <p>{intl.formatMessage({id:"total-amount", defaultMessage : "Montant total"})}</p>
                    <span>
                        {intl.formatNumber(cartData.items.fullPrice.amount, {style: 'currency', currency: cartData.items.fullPrice.currency})}
                    </span>
                </div>
                {
                    cartData.items.fullPrice.amount != cartData.items.fullPrice.downPayment && (
                    <div className="cart-price-account">
                        <p>{intl.formatMessage({id:"to-pay-now", defaultMessage : "À payer maintenant"})}</p>
                        <span>
                            {intl.formatNumber(cartData.items.fullPrice.downPayment, {style: 'currency', currency: cartData.items.fullPrice.currency})}
                        </span>
                    </div>
                    )
                }
                {/* @ts-ignore */}
                <div onClick={nextStep}>
                    <PrimaryButton squared={true} value={intl.formatMessage({id:"btn-book-now", defaultMessage : "Réservez maintenant"})}/>
                </div>
            </div>
        );
    },[ cartData.items,priceIsVisible]);

    const popUpRemove = useMemo(()=>{
        if(!!idToRemove){
            document.body.style.overflow = "hidden";
            return(
                <div className="popUpRemove">
                    <h3>{intl.formatMessage({ id: "cart-item-delete-confirm", defaultMessage: "Êtes-vous certain de vouloir retirer ce bien de votre panier ?" })}</h3>
                    <div className="popUpRemove-buttons">
                        <PrimaryButton  setState={()=>remove(idToRemove)}
                                        value={intl.formatMessage({id:"yes",defaultMessage : "Oui"})}
                        />
                        <PrimaryButton  setState={closeWindow}
                                        value={intl.formatMessage({id:"no",defaultMessage : "Non"})}
                        />
                    </div>
                    <FontAwesomeIcon onClick={closeWindow} icon={faXmark} className="close-popUpRemove"/>
                </div>
            )
        }
        return <></>
    },[idToRemove])

    useEffect(()=>{
        if(layout.includes("browser")) return;
        if(priceIsVisible){gsap.to(grid.current,{gridTemplateRows:"auto auto"})};
        if(!priceIsVisible){gsap.to(grid.current,{gridTemplateRows:"auto 85px"})};
    },[priceIsVisible]);

    return(
        <>
        {actionLoading&& <Loader/>}
        <div className="cart-content" ref={grid}>
            <div className="cart-items" style={{gridColumn:  cartData.items?.itemsCount!>0? "1/2" : "1/3" }} >
                {renderCart}
            </div>
                {renderPrice}
        </div>
        {popUpRemove}
        </>
    )
}


export default CartItems;
