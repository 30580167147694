import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import Banner from "../../components/banner";

const Gallery:FunctionComponent = ()=>{
    const intl = useIntl();
    return (
        <>
            <Banner title={intl.formatMessage({id:"gallery",  defaultMessage: "Galerie"})}/>
        </>
    )
}

export default Gallery
