import React, { FunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import SeoHelmet from "../../components/seo-helmet";
import Banner from "../../components/banner";
import Section from "../../components/wrapper/section";

import "./putOnRent.scss";
import image from "../../assets/chalets-agence-.jpg";
import ContactForm from "../../components/form/forms";

const transDefs = defineMessages({
    seo_title: {
      id: "seo-title-putOnRent",
      defaultMessage: "Maytain | Mettre son bien en location à Morgins"
    },
    seo_desc: {
      id: "seo-description-putOnRent",
      defaultMessage: "Vous souhaitez mettre votre bien en location? Gagnez du temps, profitez de notre base de clientèle fidèle et confiez-nous cette mission"
    },
    title_1: {
      id: "putOnRent-title1",
      defaultMessage: "Vous souhaitez mettre votre bien en location ?"
    },
    text_1: {
        id: "putOnRent-text1",
        defaultMessage: "Gagnez du temps, profitez de notre base de clientèle fidèle et confiez-nous cette mission."
    },
    text_2: {
        id: "putOnRent-text2",
        defaultMessage: "Qu’il s’agisse de location courte durée, à la saison ou à l’année, nous nous adaptons à vos besoins en quelques étapes :"
    },
    step_1: {
        id: "step_1",
        defaultMessage: "Étape 1"
    },
    step_2: {
        id: "step_2",
        defaultMessage: "Étape 2"
    },
    step_3: {
        id: "step_3",
        defaultMessage: "Étape 3"
    },
    step_1_text_1: {
        id: "step_1_text_1",
        defaultMessage: "Prise de contact"
    },
    step_1_text_2: {
        id: "step_1_text_2",
        defaultMessage: "Visite et photographies de votre bien."
    },
    step_1_text_3: {
        id: "step_1_text_3",
        defaultMessage: "Ajustement des tarifs de location selon notre connaissance du marché - la taille, l’équipement et le standing du bien immobilier sont également des critères influents - et selon vos attentes."
    },
    step_1_text_4: {
        id: "step_1_text_4",
        defaultMessage: "A l’issue de la visite : récapitulatif complet des éléments nécessaires à la mise en ligne de votre location sur nos plateformes (tarifs, descriptif, périodes de disponibilité…) ainsi que nos conditions de location* par courriel pour validation finale."
    },
    step_2_text_1: {
        id: "step_2_text_1",
        defaultMessage: "Suivi de A à Z"
    },
    step_2_text_2: {
        id: "step_2_text_2",
        defaultMessage: "Organisation des visites et établissement d’un état des lieux d’entrée et de sortie (pour les locations à la saison et à l’année uniquement)."
    },
    step_2_text_3: {
        id: "step_2_text_3",
        defaultMessage: "Vérification de l’état de propreté des biens avant arrivée des locataires - programmation d’un petit nettoyage si nécessaire, le propriétaire étant avisé."
    },
    step_2_text_4: {
        id: "step_2_text_4",
        defaultMessage: "Gestion complète en cas d’équipement défectueux - pendant la location (avis au propriétaire, organisation de l’intervention d’un professionnel de confiance, suivi des travaux et du paiement des factures)."
    },
    step_3_text_1: {
        id: "step_3_text_1",
        defaultMessage: "C'est loué !"
    },
    step_3_text_2: {
        id: "step_3_text_2",
        defaultMessage: "Transmission d’un avis locatif au propriétaire reprenant les dates de location et la part de la location nette qui lui sera reversée."
    },
    step_3_text_4: {
        id: "step_3_text_4",
        defaultMessage: "Arrivée du locataire | Remise des clés et d’un sac de bienvenue."
    },
    step_3_text_5: {
        id: "step_3_text_5",
        defaultMessage: "Départ du locataire | Récupération des clés, planification du nettoyage, suivi en cas d’éventuelles remarques."
    },
    title_2: {
        id: "putOnRent-title2",
        defaultMessage: "Nos conditions de location"
    },
    subtitle_2: {
        id: "putOnRent-subtitle2",
        defaultMessage: "Conditions"
    },
    conditions_1: {
        id: "putOnRent-conditions-1",
        defaultMessage: "Pour les locations courte durée (- de 60 jours - semaine en semaine), nous prenons 20% de commission sur le prix de location + 8.1% de TVA sur le montant de la commission."
    },
    conditions_2: {
        id: "putOnRent-conditions-2",
        defaultMessage: "Pour les locations longue durée (+ de 60 jours consécutifs au même locataire - à la saison), nous prenons 15% de commission sur le prix de location + 8.1% de TVA sur le montant de la commission."
    },
    conditions_3: {
        id: "putOnRent-conditions-3",
        defaultMessage: "L’Agence Maytain met à disposition draps de lit, linges de bain (petit et grand), tapis de bain et torchons de cuisine pour les locataires. Si les locataires décident d’utiliser les draps, nous nous occupons de les déposer dans le bien loué ainsi que de les récupérer et les laver au départ (facturation directement aux locataires). Les locataires gardent néanmoins le choix de prendre leur propre draps et linges."
    },
    conditions_4: {
        id: "putOnRent-conditions-4",
        defaultMessage: "Nous programmons le nettoyage final après chaque location. Celui-ci est facturé directement aux locataires et il est obligatoire."
    },
    conditions_5: {
        id: "putOnRent-conditions-5",
        defaultMessage: "Les taxes de séjour sont facturés en plus aux locataires ; CHF 3.- par nuit/par adulte - CHF 1.50 par nuit/par enfant (6-17ans) - gratuit pour les moins de 6 ans. Pour les locations longue durée, nous établissons un forfait taxe de séjour (calculé au prorata de l’occupation). Nous reversons la totalité des taxes de séjour aux propriétaires ou auprès de la commune si les propriétaires sont des résidents de Morgins-Troistorrents."
    },
    conditions_6: {
        id: "putOnRent-conditions-6",
        defaultMessage: "L’agence doit détenir idéalement 3 clés du chalet/appartement."
    },
    conditions_7: {
        id: "putOnRent-conditions-7",
        defaultMessage: "Dès que nous avons une confirmation de réservation, vous êtes avisés par mail en recevant un avis de location."
    },
    conditions_8: {
        id: "putOnRent-conditions-8",
        defaultMessage: "Afin de louer au mieux votre appartement, nous vous demanderons de nous communiquer dès que possible vos dates d’occupation et celles auxquelles nous pourrions louer pour chacune des saisons."
    },
    conditions_9: {
        id: "putOnRent-conditions-9",
        defaultMessage: "Vous avez accès en tout temps à l’aide d’un identifiant et d’un mot de passe, à la plateforme en ligne pour suivre les locations de votre bien et les recettes s’y rapportant."
    },
    title_3: {
        id: "putOnRent-title3",
        defaultMessage: "Bon à savoir"
    },
    text_3: {
        id: "putOnRent-text3",
        defaultMessage: "Notre agence a pour mot d’ordre la flexibilité avec ses clients."
    },
    text_4: {
        id: "putOnRent-text4",
        defaultMessage: "En tant que propriétaire, vous pouvez à tout moment retirer votre bien de la location pour une durée définie, indéfinie ou de manière définitive, et ce sans préavis (pour autant qu’aucune réservation ne soit confirmée)."
    },
    text_5: {
        id: "putOnRent-text5",
        defaultMessage: "Rencontrez-nous ! Comme rien ne remplace le contact humain, nous vous accueillons dans nos bureaux en tout temps. Si le déplacement est difficile, nous nous tenons naturellement à votre disposition par téléphone et par email."
    },
});

const PutOnRent:FunctionComponent = ()=>{
    const intl = useIntl();

    return (
        <>
            <SeoHelmet
                title={intl.formatMessage(transDefs.seo_title)}
                description={intl.formatMessage(transDefs.seo_desc)}
                canonical="/mettre-en-location"
                image={image}
            />
            <Banner title={intl.formatMessage({id:"putOnRent",  defaultMessage: "Mettre en location"})}/>
            <Section
            className="putOnRent"
            title={intl.formatMessage(transDefs.title_1)}
            subtitle={intl.formatMessage({id:"putOnRent",  defaultMessage: "Mettre en location"})}
            titlePosition="left"
            >
                <div className="content">
                    <p>
                        <strong>{intl.formatMessage(transDefs.text_1)}</strong>
                    </p>
                    <p>
                        {intl.formatMessage(transDefs.text_2)}
                    </p>
                    <div className="step">
                    <div className="nbr">{intl.formatMessage(transDefs.step_1_text_1)}</div>
                    <div>
                        <ul>
                            <li>{intl.formatMessage(transDefs.step_1_text_2)}</li>
                            <li>{intl.formatMessage(transDefs.step_1_text_3)}</li>
                            <li>{intl.formatMessage(transDefs.step_1_text_4)}</li>
                        </ul>
                    </div>
                    </div>
                    <div className="step">
                        <div className="nbr">{intl.formatMessage(transDefs.step_2_text_1)}</div>
                        <div>
                            <ul>
                                <li>{intl.formatMessage(transDefs.step_2_text_2)}</li>
                                <li>{intl.formatMessage(transDefs.step_2_text_3)}</li>
                                <li>{intl.formatMessage(transDefs.step_2_text_4)}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="step">
                        <div className="nbr">{intl.formatMessage(transDefs.step_3_text_1)}</div>
                        <div>
                            <ul>
                                <li>{intl.formatMessage(transDefs.step_3_text_2)}</li>
                                <li>{intl.formatMessage(transDefs.step_3_text_4)}</li>
                                <li>{intl.formatMessage(transDefs.step_3_text_5)}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Section>
            <Section
            title={intl.formatMessage(transDefs.title_2)}
            subtitle={intl.formatMessage(transDefs.subtitle_2)}
            className="conditions"
            background="#F9F9F9"
            titlePosition="left"
            >
                <div className="content">
                    <ul>
                        <li>{intl.formatMessage(transDefs.conditions_1)}</li>
                        <li>{intl.formatMessage(transDefs.conditions_2)}</li>
                        <li>{intl.formatMessage(transDefs.conditions_3)}</li>
                        <li>{intl.formatMessage(transDefs.conditions_4)}</li>
                        <li>{intl.formatMessage(transDefs.conditions_5)}</li>
                        <li>{intl.formatMessage(transDefs.conditions_6)}</li>
                        <li>{intl.formatMessage(transDefs.conditions_7)}</li>
                        <li>{intl.formatMessage(transDefs.conditions_8)}</li>
                        <li>{intl.formatMessage(transDefs.conditions_9)}</li>
                    </ul>
                </div>
            </Section>
            <Section
            title={intl.formatMessage(transDefs.title_3)}
            className="goodToKnow"
            >
                <div className="content">
                    <p>
                        {intl.formatMessage(transDefs.text_3)}
                        {intl.formatMessage(transDefs.text_4)}
                    </p>
                </div>
            </Section>
            <Section
            title={intl.formatMessage({id:"contact", defaultMessage:"Contact"})}
            background="#F9F9F9"
            className="contact"
            >
                <div className="content">
                    <p>{intl.formatMessage(transDefs.text_5)}</p>
                    <ContactForm email="location@maytain.com"/>
                </div>
            </Section>
        </>
    )
};

export default PutOnRent;
