import React, { FunctionComponent } from "react";
import { useNavigate } from "@ct-react/locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCross } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { useCartData } from "./cart";
import { PrimaryButton } from "../../components/button";
import { classNames } from "@ct-react/core";
import "./payment-callback.scss";

type CartPaymentCallbackProps = { succeeded: boolean };

const CartPaymentCallback:FunctionComponent<CartPaymentCallbackProps> = ({succeeded}) =>{
    const intl = useIntl();
    const navigate = useNavigate();
    const [cartData, setCardData] = useCartData();

    const handleOnClick = ()=>{
        navigate(succeeded ? "/" : "/checkout/panier");
    }

    return(
        <div className="payment-result">
            <div className={classNames({succeeded : succeeded})}>
                <FontAwesomeIcon icon={succeeded? faCheck : faCross}/>
            </div>
            <h3>
                {succeeded?
                            intl.formatMessage({id:"payment-callback-suceed1", defaultMessage : "Transaction effectué avec succès !"})
                            :
                            intl.formatMessage({id:"payment-callback-failed1",defaultMessage : "Echec de la transaction"})
                }
            </h3>
            <p>
                {succeeded?
                            intl.formatMessage({id:"payment-callback-suceed2",defaultMessage : "Merci pour votre achat"})
                            :
                            intl.formatMessage({id:"payment-callback-failed2", defaultMessage : "Un problème est survenue"})
                }
            </p>
            <PrimaryButton  setState={()=>handleOnClick}
                            value={succeeded? intl.formatMessage({id:"btn-back-home",defaultMessage : "Retour à l'accueil"}) : intl.formatMessage({id:"btn-retry",defaultMessage : "Réssayer"}) }
            />
        </div>
    );
};

export default CartPaymentCallback;
