import React, { createContext, ReactNode, useEffect, useState } from "react";
import BREAKPOINTS from "../utils/breakpoints";

type Layout = {
    width:number,
    height:number
};

type LayoutProviderProps = {
    children:ReactNode
};

const currentLayout:Layout = {
    width:1920,
    height:800
};

export const LayoutContext = createContext<string>(
    "browser-landscape",
);

export const LayoutProvider = ({children}:LayoutProviderProps)=>{
    const [layout,setLayout] = useState<Layout>(currentLayout) //Test
    const [device,setDevice] = useState<string>("browser-landscape")

    useEffect(()=>{
        const set = ()=>{
            setLayout({width:window.innerWidth,height:window.innerHeight});
        }
        window.addEventListener("load",()=>set())
        window.addEventListener("resize",()=>set())

        if(layout.width<BREAKPOINTS.desktop && layout.width<layout.height){
            setDevice("smartphone-portrait")
        }else if(layout.width>BREAKPOINTS.desktop && layout.width>layout.height){
            setDevice("browser-landscape")
        }else if(layout.height>BREAKPOINTS.desktop && layout.width<layout.height){
            setDevice("browser-portrait")
        }else if(layout.height<BREAKPOINTS.desktop && layout.width>layout.height){
            setDevice("smartphone-landscape")
        }else{
            setDevice( "browser-landscape")
        };
        return () => {
            window.removeEventListener('load', ()=>set());
            window.removeEventListener('resize', ()=>set());
        };
    },[layout.width,layout.height])

    return(
        <LayoutContext.Provider value={device}>
            {children}
        </LayoutContext.Provider>
    )
}
